automation:
  add_step: Adicionar etapa
  add_trigger: Adicionar um acionador
  create_new: Nova automação
  error_launch: O lançamento da automação falhou
  select_trigger_description: Selecione um gatilho para começar a configurar sua automação
  success_launch: O lançamento da automação foi concluído com sucesso
  trigger_api: Quando uma chamada de API é recebida
  trigger_graphquery: Quando uma consulta graphQL é executada
  trigger_transformation: Quando um objeto é editado
automations:
  automation_name_placeholder: Nome da automação
  create_automation: Criar uma nova automação
  creation_automation_name: Nome da automação
  modal_new_automation_description: >-
    As automações permitem que você crie processos automatizados para criar,
    editar e salvar dados no Bonx.
  run:
    datetime: Data da corrida
    id: Id du run
  step:
    end_datetime: Data final da etapa
    name: Nome da etapa
    start_datetime: Data de início da etapa
  tab_detail: Automatização
  tab_history: História
blocks:
  qrCodeFullSize: Botão QR Code em modo de tela cheia
button:
  about: Sobre
  back: De volta
  toggle_dark: Modo escuro
  toggle_langs: Alternar idioma
dashboard:
  last_30_days: Últimos 30 dias
data_connection:
  add_step: Adicionar etapa
  auth: Método de autenticação
  auth_basic: básico
  data_connection_name_placeholder: Minha conexão de dados
  data_connection_step_type_placeholder: Marcador de posição do tipo de etapa de conexão de dados
  data_connection_steps: Detalhe da etapa
  document_url: URL compartilhado da planilha do Google
  input_data: Variável de entrada
  json_edition_label: Editar json data_connection
  name: Nome da etapa
  password: Senha
  query: Consulta
  save_file: O arquivo está salvo
  save_output: Variável de saída
  step_json_edition_label: Edite a etapa json
  tab: Guia da planilha do Google selecionada
  url: URL da API
  username: Nome de usuário
email:
  body: Corpo do e-mail
  subject: Assunto e-mail
filters:
  operators_doesnt_include: Não contém
  operators_equals_to: (==) igual a
  operators_greater_than: (>) maior que
  operators_greater_than_or_equal_to: (>=) maior ou igual a
  operators_includes: Contém
  operators_is_empty: está vazia
  operators_is_not_empty: não está vazio
  operators_less_than: (<) menor que
  operators_less_than_or_equal_to: (<=) menor ou igual a
  operators_no_operator: Sem condição
  operators_not_equal_to: (!=) diferente de
form.dollar_helper: |-
  As variáveis ​​precedidas por um sinal "$" são internas ao formulário. 
  Essas variáveis ​​devem então ser vinculadas aos atributos do modelo abaixo.
form_field_max_value: Valor máximo
forms:
  autofill_end: >-
    Este campo é preenchido automaticamente pelo Bonx quando o formulário é
    enviado, usando a data e hora atuais.
  autofill_information: Este campo é preenchido automaticamente pelo Bonx
  autofill_start: >-
    Este campo é preenchido automaticamente pelo Bonx quando o formulário é
    aberto, utilizando a data e hora atuais.
  base_model: modelo base
  create_form: Criar formulário
  create_new: Nova forma
  creation_form_name: Nome do formulario
  edit_no_field_warning: Este formulário atualmente não possui campo
  field_multiple_answers_allowed: Você pode selecionar várias opções aqui
  field_no_options: Ainda não há opções disponíveis.
  form_field_date: Encontro
  form_field_display: Exibição
  form_field_display_readquery_placeholder: Adicione uma consulta de leitura secundária (opcional)
  form_field_document: Documento
  form_field_has_min_max_values: Tem valores mínimos máximos
  form_field_image: Imagem
  form_field_label_label: Nome do campo
  form_field_min_value: Valor mínimo
  form_field_multiple_choice: Múltiplas escolhas
  form_field_name_label: Chave de atributo
  form_field_number: Número
  form_field_option: Opção
  form_field_option_required: >-
    Um campo de opção é obrigatório por padrão, selecione o campo "Múltiplas
    escolhas" se um valor vazio puder ser retornado pelo formulário.
  form_field_options_label: Lista de escolhas
  form_field_options_placeholder: Adicionar uma escolha
  form_field_read_query: Consulta de leitura
  form_field_required_label: Obrigatoriedade
  form_field_text: Campo de texto
  form_field_text_description_placeholder: Adicione uma descrição ou um auxiliar aqui (opcional)
  form_field_text_label_placeholder: Adicionar um nome de campo
  form_field_text_name_placeholder: Adicionar um nome de atributo
  form_field_text_value_placeholder: Adicione um valor padrão (opcional)
  form_field_textarea_label: texto longo
  form_field_write_query: Escrever consulta
  form_fields: Campos de formulários
  form_readquery_placeholder: Adicionar uma consulta de leitura principal (opcional)
  form_title_placeholder: Nome do formulario
  form_writequery_placeholder: Adicionar uma consulta de gravação
  formreadquery_placeholder: Adicionar uma consulta de leitura principal (opcional)
  modal_new_form_description: >-
    O criador de formulários permite que você gere formulários para serem
    preenchidos por suas equipes e, portanto, automatiza o feedback de dados.
  modal_share_form_description: >-
    Você pode compartilhar este formulário por e-mail com um link exclusivo e
    acessível ao público.
  no_related_model_found: Nenhum modelo encontrado
  share: Compartilhar
  share_form_emails_label: Endereço de e-mail
forms.action_delete_form: Excluir formulário
forms.action_duplicate_form: Formulário Duplicado
forms.choose_attribute: Escolha o atributo
forms.creation_import_fields: Importar atributos do modelo
forms.creation_related_model: Modelo Relacionado
forms.creation_related_process: Processo Relacionado
forms.creation_related_process_step: Etapa do Processo Relacionado
forms.deletion_confirmation_body: |-
  Tem certeza de que deseja excluir este formulário? 
  Somente um administrador do Bonx poderá restaurá-lo no futuro.
forms.deletion_confirmation_title: Exclusão de formulário
forms.error_save_not_admin: Somente um administrador pode salvar um formulário editado
forms.form_field_no_related_model_placeholder: Nenhum modelo relacionado
forms.form_field_no_relationship_placeholder: sem relacionamento
forms.form_field_relation: Relação
forms.form_field_relationship_allow_existing_object_label: Permitir vinculação de um objeto existente
forms.form_field_relationship_allow_new_object_label: Permitir a criação de um novo item vinculado
forms.form_field_relationship_related_model_label: Selecione um modelo relacionado
forms.form_field_relationship_value_label: Selecione um relacionamento
forms.form_share: Formulário compartilhado publicamente
forms.labels: ''
forms.new_form_advanced: Avançado
forms.new_form_classic: Clássico
forms.no_model: Este formulário não está vinculado a um modelo existente
forms.no_related_step: Não vinculado a uma etapa do processo
forms.toast_deletion_success: Este formulário foi excluído com sucesso.
forms.toast_duplication_success: |-
  O formulário foi duplicado com sucesso. 
  Você está sendo redirecionado para este novo formulário.
global:
  actions: Ações
  add: Adicionar
  automations: Automações
  cancel: Cancelar
  click_button_reload: Por favor, clique no botão abaixo para recarregar!
  client: Companhia
  client_name: Nome do cliente
  clients: Clientes
  close: Perto
  collaboration: colaboração
  comment: Comentarista
  compare: Comparar
  create: Crio
  currency: Moeda
  dashboard: Painel
  data: Dados
  data_connections: Conexões de dados
  data_saved: Seus dados foram salvos
  days: Dias
  delete: Excluir
  description: Descrição
  disabled: Desativado
  disabled_users: Usuários desativados
  display: Mostrar
  duplicate: Duplicar
  duration: Duração
  edit: Editar
  email: E-mail
  entity: Entidade
  error: Erro
  error_json: JSON está incorreto
  error_login_invalid_password: Senha incorreta.
  error_login_invalid_user: Usuário não encontrado, verifique novamente o nome de usuário.
  error_login_unknown: >-
    Erro de registro desconhecido, entre em contato com a equipe Bonx usando o
    botão redondo no canto inferior direito desta tela.
  error_password_validation: >-
    Sua senha deve ter pelo menos 8 caracteres e deve conter pelo menos uma
    minúscula, uma maiúscula e um número.
  failed: Fracassado
  file_input:
    no_file: Você ainda não enviou nenhum arquivo
  filter: Filtro
  first_name: Primeiro nome
  follow_up: Acompanhamento
  forgot_password: Esqueceu sua senha
  forms: Formulários
  from: A partir de
  groups: Grupos
  home: Casa
  id: Id
  is_required: É necessário
  label: Etiqueta
  labels: |-
    etiqueta | 
    etiqueta | 
    rótulos
  large: Grande
  last_name: Último nome
  launch: Lançar
  login: Conecte-se
  logout: Sair
  medium: Médio
  missing_write_request: Você não forneceu uma solicitação de gravação
  models: |-
    modelo | 
    modelo | 
    modelos
  month:
    april_short: abril
    august_short: agosto
    december_short: dezembro
    february_short: fevereiro
    january_short: janeiro
    july_short: julho
    june_short: junho
    march_short: março
    may_short: maio
    november_short: novembro
    october_short: outubro
    september_short: setembro
  name: Nome
  new: Novo
  new_version: Uma nova versão está disponível!
  not-found: Não encontrado
  objects: |-
    Objeto | 
    Objeto | 
    Objetos
  order: pedido
  overview: Visão geral
  parameters: Parâmetros
  password: Senha
  phone: Telefone
  photo: foto
  photo_url: URL da foto
  process: Processo
  project: Projeto
  projects: Projetos
  reload: recarregar
  remember_me: Lembre de mim
  reset_email_sent: >-
    Um e-mail para redefinir sua senha foi enviado. Por favor, clique no link
    quando você obtê-lo
  reset_password: Redefinir senha
  role: Função
  rows: linhas
  save: Salve 
  saving: Salvando...
  search: Procurar
  send: Mandar
  send_reset_email: Enviar e-mail de redefinição
  settings: Definições
  show: mostrar
  small: Pequena
  status: Status
  success: Sucesso
  table:
    nav: Paginação da tabela
  task: Tarefa | Tarefa | Tarefas
  total_report: Relatório total
  training_and_help: centro de recursos
  type: Tipo
  update: Atualizar
  user: Do utilizador
  users: Comercial
  users_and_permissions: Usuários e permissões
  validate: Validar
  value: Valor
  views: |-
    Ver | 
    Ver | 
    Visualizações
  week: Semana
  you_dont_have_access_to_this_page: Você não tem acesso a esta página
global.attribute: Atributo
global.close: ''
global.instance: Instância
global.model: Modelo
global.toast_key_copy_success_content: A chave foi copiada com sucesso em sua área de transferência.
global.toast_succes_copy_to_clipboard: O conteúdo da tabela foi copiado com sucesso para a área de transferência
global.unsinchronized_store_header_content: |-
  O seguinte elemento foi atualizado. 
  Portanto, você não poderá salvar nenhuma edição antes de atualizar a página.
global.unsinchronized_store_header_content_pt1: Os seguintes elementos foram atualizados
global.unsinchronized_store_header_content_pt2: Portanto, você não poderá realizar nenhuma edição antes de atualizar a página
global.unsinchronized_store_header_title: A configuração do Bonx foi editada por outro usuário
global.update: Atualizar
groups:
  group_name: Nome do grupo
  group_order: Pedido de grupo
  groups_list: Lista de grupo
  toast_edit_success: As alterações salvas no grupo foram salvas com sucesso
home:
  greetings: Saudações
modal_confirmation_popup_cancel: Não
modal_confirmation_popup_confirm: Sim
object:
  attribute:
    attributes: |-
      atributo | 
      atributo | 
      atributos
  attributes: Atributos do objeto
  attributes_list: Lista de atributos
  error_attribute_with_same_name_exists: Um atributo existente já tem o mesmo nome
  error_loading: Ocorreu um erro ao carregar o conteúdo desta página
  error_no_primary_attribute: Nenhum atributo primário foi definido para este modelo
  related_objects: |-
    Objeto relacionado | 
    Objeto relacionado | 
    Objetos relacionados
  relation:
    attributes_list: Lista de atributos de relacionamento
  relationship:
    object: Objeto vinculado
    to_object: Para o objeto
  relationship_list: Lista de relacionamentos
  relationships: |-
    relacionamento | 
    relacionamento | 
    relacionamentos
  selected_setting_object: Tipo de objeto
objects:
  add_attribute: Adicionar um atributo
  add_label: Criar um rótulo
  add_object: Criar um objeto
  attributes_subtype_float: Ponto flutuante
  attributes_subtype_integer: Inteiro
  attributes_subtype_link: Ligação
  attributes_subtype_plain_text: Texto sem formatação
  data_model: Modelo de dados
  data_models: Modelos de dados
  edit_attribute: Editar um atributo
  edit_label: Editar um rótulo
  edit_object: Editar um objeto
  label:
    placeholder: Por favor, escolha um rótulo
  label_objects: Objetos vinculados a este rótulo
  label_relations: Relações vinculadas a este rótulo
  labels: Rótulos
  labels_list: Lista de marcadores
  models: Modelos
  models_list: Listas de modelos
  objects_list: Lista de objetos
  overview: visão global
  relation:
    add_relation: Adicionar um relacionamento
    edit_relation: Editar um relacionamento
  relations: Relações
otable:
  error:
    table_has_no_column: >-
      Esta tabela ficará visível assim que uma chave for adicionada como coluna,
      entre em contato com um administrador de sua empresa.
pages:
  automation: Automação
  data-connection: Conexão de dados
  object: Objeto
process.finished: Finalizado
process.in_progress: Em andamento
process.waiting: Esperando
processes:
  next_step: Próxima Etapa
  step_name: Nome da etapa
profile:
  gantt_size_label: Exibição do gráfico de Gantt
  home_page: Pagina inicial
  my_profile: Meu perfil
  settings: Definições
timeline:
  external_user: Usuário externo
toast:
  form_submit_content: O formulário foi submetido com sucesso
  print_success: Uma página será aberta em breve
  view_error_no_model_title: Bonx em breve permitirá que você edite visualizações complexas
  view_error_no_model_title_content: >-
    Esta visualização foi criada manualmente, entre em contato com seu
    departamento de TI ou com um administrador do Bonx.
toast.form_field_file_submit_content_error: Este formulário requer o envio de um arquivo.
user:
  add_label: Criar um rótulo
  data_model:
    labels: Rótulos
    models: Modelos
    relations: Relacionamentos
  data_models: Modelos de dados
  edit_label: Editar um rótulo
  label:
    placeholder: Encontre um rótulo...
  models_list: Lista de marcadores
view:
  action_move_up: Subir
  no_title: Sem título
  toast_edit_success: O detalhe da vista foi editado com sucesso
views:
  action_move_down: Mover para baixo
  add_attribute: Novo atributo
  add_block: Novo bloco
  block_name: Nome do bloco
  block_type: Tipo du bloco
  create_new_view: Criar uma nova visualização
  create_new_view_description: >-
    As visualizações permitem que você visualize os dados de seu interesse, de
    diferentes formas, e compartilhe-os com suas equipes.
  create_view: Criar uma nova visualização
  creation_automation_name_label: Nome da automação
  creation_form_name_label: Nome do formulario
  creation_view_model: Modelo relacionado
  creation_view_name: Nome da visualização
  default_setup_element_name: Dados relacionados ao modelo
  default_view_group_name: Grupo padrão
  edit_attributes_options: Opções de atributos
  edit_attributes_options_add_colors: Cores
  edit_attributes_options_add_icon: Ícones
  edit_attributes_options_add_status: Status
  edit_attributes_options_background_color: Cor de fundo
  edit_attributes_options_border_color: Cor da borda
  edit_attributes_options_description: >-
    Selecionar as opções de um atributo permite alterar suas configurações
    profundas.
  edit_attributes_options_hide_json: Detalhes ocultos
  edit_attributes_options_icon: Ícone
  edit_attributes_options_no_attributes: Este bloco ainda não possui atributo.
  edit_attributes_options_show_json: Mostrar detalhes
  edit_attributes_options_status_error: 'Estado: erro'
  edit_attributes_options_status_success: 'Estado: sucesso'
  edit_attributes_options_status_warning: 'Estado: aviso'
  edit_import_model: Importar um modelo
  edit_import_model_description: >-
    A importação de um modelo permite adicionar em um clique todos os seus
    atributos como eixos do bloco.
  edition_deletion: Excluir a visualização
  edition_no_columns: >-
    Essas caixas de seleção ficarão visíveis assim que um atributo for
    adicionado ao modelo, entre em contato com um administrador de sua empresa.
  edition_no_related_objects: Este modelo não está relacionado a nenhum outro modelo
  edition_primary_table: Tabela de dados primários
  edition_secondary_related_objects: Objetos relacionados
  edition_selected_columns: Colunas
  error:
    query_invalid_or_missing: A solicitação fornecida está vazia ou resultou em um erro.
  filter_label_placeholder: Etiqueta para filtrar
  filter_value_placeholder: Valor
  filter_view: Filtrar esta visualização
  filters_add_filter: Adicionar uma condição
  gantt:
    bar:
      no_label: Esta barra não possui rótulo
    precision:
      day: dias
      display_by: Exibir por
      month: meses
  id: Id
  label: Etiqueta
  modal_new_view_description: >-
    As visualizações permitem que você visualize seus dados da maneira que
    desejar e compartilhe-os com suas equipes.
  query: Solicitação do GraphQL
  toast_edit_success: A visualização foi editada com sucesso
  type: Modelo
  view_block_name_placeholder: Nome do meu bloco de visualização (opcional)
  view_name_placeholder: Ver nome
  view_parameters_title: Visualizar parâmetros
  views_list: Lista de visualizações
views.deletion_confirmation_body: >-
  Tem certeza de que deseja excluir esta visualização? 

  Todos os seus dados serão armazenados caso você cometa um erro e queira
  reverter.
views.deletion_confirmation_title: Ver exclusão
views.toast_deletion_success: Esta visualização foi excluída com sucesso.
