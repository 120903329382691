import type { InternalAxiosRequestConfig } from "axios"
import type { TraceId } from "zipkin"
import mixpanel from "mixpanel-browser"
import { format } from "date-fns-tz"
import { userStore } from "~/stores/user"
import { getEnv } from "~/config"

mixpanel.init("47e5ff8cd6f1828311691d04d767130c", {
  debug: false,
  persistence: "localStorage",
})

export function mixPanelEvent(event_name: string, data?: Record<string, string>) {
  if (getEnv() !== "production")
    return

  const dateTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: "Europe/Paris" })
  mixpanel.track(event_name, {
    user_id: userStore().user.id,
    client_id: userStore().user.client_id,
    client_name: userStore().user.client_name,
    dateTime,
    ...data,
  })
}

// Zipkin instrumentation
const httpHeaders = {
  TraceId: "X-B3-TraceId",
  SpanId: "X-B3-SpanId",
  ParentSpanId: "X-B3-ParentSpanId",
  Sampled: "X-B3-Sampled",
  Flags: "X-B3-Flags",
}

export function appendZipkinHeaders(req: InternalAxiosRequestConfig, traceId: TraceId): InternalAxiosRequestConfig {
  const headers = req.headers || {}
  headers[httpHeaders.TraceId] = traceId.traceId
  headers[httpHeaders.SpanId] = traceId.spanId
  traceId.parentSpanId.ifPresent((psid) => {
    headers[httpHeaders.ParentSpanId] = psid
  })
  traceId.sampled.ifPresent((sampled) => {
    headers[httpHeaders.Sampled] = sampled ? "1" : "0"
  })

  if (traceId.isDebug())
    headers[httpHeaders.Flags] = "1"

  req.headers = headers
  // @ts-expect-error we add the traceId to the config
  req._zipkinTraceId = traceId
  return req
}
