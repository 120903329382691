export var FilterComparator;
(function (FilterComparator) {
    FilterComparator["Equal"] = "==";
    FilterComparator["NotEqual"] = "!=";
    FilterComparator["GreaterThan"] = ">";
    FilterComparator["GreaterThanOrEqual"] = ">=";
    FilterComparator["LessThan"] = "<";
    FilterComparator["LessThanOrEqual"] = "<=";
    FilterComparator["IsEmpty"] = "is_empty";
    FilterComparator["IsNotEmpty"] = "is_not_empty";
    FilterComparator["IsIn"] = "is_in";
    FilterComparator["IsNotIn"] = "is_not_in";
    FilterComparator["Contains"] = "contains";
    FilterComparator["NotContains"] = "not_contains";
    FilterComparator["IsRelativeToToday"] = "is_relative_to_today";
})(FilterComparator || (FilterComparator = {}));
export var RelativeDateIndicator;
(function (RelativeDateIndicator) {
    RelativeDateIndicator["this"] = "This";
    RelativeDateIndicator["past"] = "Past";
    RelativeDateIndicator["next"] = "Next";
})(RelativeDateIndicator || (RelativeDateIndicator = {}));
export var RelativeDateUnit;
(function (RelativeDateUnit) {
    RelativeDateUnit["days"] = "Day";
    RelativeDateUnit["weeks"] = "Week";
    RelativeDateUnit["months"] = "Month";
    RelativeDateUnit["years"] = "Year";
})(RelativeDateUnit || (RelativeDateUnit = {}));
export var RelativeDateTimeUnit;
(function (RelativeDateTimeUnit) {
    RelativeDateTimeUnit["hours"] = "Hour";
})(RelativeDateTimeUnit || (RelativeDateTimeUnit = {}));
export var AxisType;
(function (AxisType) {
    AxisType["Number"] = "number";
    AxisType["Text"] = "text";
    AxisType["Select"] = "select";
    AxisType["Date"] = "date";
    AxisType["DateTime"] = "datetime";
    AxisType["Boolean"] = "boolean";
    AxisType["Tags"] = "tags";
    AxisType["File"] = "file";
    AxisType["Duration"] = "duration";
})(AxisType || (AxisType = {}));
export var BlockType;
(function (BlockType) {
    BlockType["Table"] = "table";
    BlockType["Layout"] = "layout";
    BlockType["Gallery"] = "gallery";
    BlockType["Calendar"] = "calendar";
    BlockType["Inputs"] = "inputs";
    BlockType["Chart"] = "chart";
    BlockType["Cardlist"] = "cardlist";
    BlockType["Metrics"] = "metrics";
    BlockType["Gantt"] = "gantt";
})(BlockType || (BlockType = {}));
export var AggregationType;
(function (AggregationType) {
    AggregationType["Count"] = "count";
    AggregationType["CountUnique"] = "countUnique";
    AggregationType["Sum"] = "sum";
    AggregationType["Avg"] = "avg";
    AggregationType["Min"] = "min";
    AggregationType["Max"] = "max";
    AggregationType["Median"] = "median";
    AggregationType["TruncateDay"] = "truncateDay";
    AggregationType["TruncateWeek"] = "truncateWeek";
    AggregationType["TruncateMonth"] = "truncateMonth";
    AggregationType["TruncateYear"] = "truncateYear";
    AggregationType["Keys"] = "keys";
    AggregationType["Collect"] = "collect";
    AggregationType["CollectUnique"] = "collectUnique";
})(AggregationType || (AggregationType = {}));
