import { defineStore } from "pinia"
import { userStore } from "~/stores/user"
import DBHelper from "~/helpers/dbHelper/index"

const dbHelper = new DBHelper()

export const orgStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "org",
  // a function that returns a fresh state
  state: () => ({
    org: {
      clients: [] as Array<any>,
    },
  }),
  // optional getters
  getters: {
    getOrg(): any {
      return this.org
    },
  },
  // optional actions
  actions: {
    async loadOrg(): Promise<any> {
      const user: any = userStore().user
      if (user) {
        let clients: Array<any> = []

        if (user?.role === "ADMIN") {
          clients = await dbHelper.getAllDataFromCollection("clients")
        }
        else {
          if (user?.client_id) {
            const client = await dbHelper.getDocFromCollection("clients", user?.client_id)
            if (client)
              clients.push(client)
          }
        }
        this.org.clients = clients
      }
    },
  },
})
