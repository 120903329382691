import DbHelper from "~/helpers/dbHelper"
import type { ClientAcl } from "~/composables/acl"
import { userStore } from "~/stores/user"

const dbHelper = new DbHelper()

// TODO : After models are created -> add typing
export async function getClients() {
  const clients = await dbHelper.getAllDataFromCollection("clients")
  return clients
}

export async function updateUserClientAcl(acl: ClientAcl, client_id?: string) {
  const user = userStore().user
  client_id = client_id || user.client_id
  if (!client_id)
    throw new Error("No client_id provided")
  const client = await dbHelper.getDocFromCollection("clients", client_id)
  client.acl = acl
  return await dbHelper.updateDataToCollection("clients", client_id, client)
}
