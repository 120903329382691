import { Case, Function, List, Literal, Map, Null, RawCypher, and, datetime, eq, gt, gte, head, in as inOp, isNotNull, isNull, lt, lte, minus, not, or, plus, size, toString, } from "@neo4j/cypher-builder";
/**
 * Group expressions in parenthesis
 *
 * @param exprs The expressions to group
 * @private
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
class Parenthesis extends Function {
    constructor(exprs) {
        super("", exprs);
    }
    get exprs() {
        // @ts-expect-error I know this is a private property but I need it
        return this.params[0]?.exprs || this.params[0]?.params || [];
    }
}
/**
 * A cypher comment starting with //
 *
 * @param comment The comment to add
 * @private
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
class Comment extends RawCypher {
    constructor(comment) {
        super(`// ${comment}`);
    }
}
/**
 * Apply DISTINCT to the arguments of a function
 *
 * @private
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
class DistinctFunction extends Function {
    getCypher(env) {
        const argsStr = this.serializeParams(env);
        return `${this.name}(DISTINCT ${argsStr})`;
    }
}
/**
 * Apply a suffix to a cypher statement
 *
 * @param name The function name
 * @param suffix The suffix to apply
 * @param params The function arguments
 * @private
 *
 * @example with `.seconds` suffix : duration.inSeconds(instant1, instant2) => duration.inSeconds(instant1, instant2).seconds
 */
class SuffixedFunction extends Function {
    suffix;
    constructor(name, suffix, params = []) {
        super(name, params);
        this.suffix = suffix;
    }
    getCypher(env) {
        return super.getCypher(env) + this.suffix;
    }
}
/**
 * Cypher function range()
 *
 * Returns a list comprising all integer values within a range bounded by a start value start and end value end, where the difference step between any two consecutive values is constant; i.e. an arithmetic progression.
 * @param start The start value
 * @param end The end value
 * @param step The difference between any two consecutive values
 *
 * @example `range(1, 10, 2)` => `range(1, 10, 2)`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/list/#functions-range
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function range(start, end, step = 1) {
    return new Function("range", [start, end, new Literal(step)]);
}
/**
 * Group expressions in parenthesis
 *
 * @param exprs The expressions to group
 *
 * @example `group(1, 2, 3)` => `(1, 2, 3)`
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function group(...exprs) {
    return new Parenthesis(exprs);
}
/**
 * Cypher comment starting with //
 *
 * @param comment The comment to add
 *
 * @example `comment("This is a comment")` => `// This is a comment`
 * @see https://neo4j.com/docs/cypher-manual/4.4/syntax/comments/
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function comment(comment) {
    return new Comment(comment);
}
/**
 * Cypher statement to add a duration to a date
 * Wrapped in a group `()` to avoid precedence issues
 *
 * @param date The date to add the duration to
 * @param amount The amount of time to add
 * @param unit The unit of time to add (years, quarters, months, weeks, days, hours, minutes, seconds, milliseconds, microseconds, nanoseconds)
 *
 * @example `dateadd(now(), 1, "days")` => `(datetime() + duration({days: 1}))`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/duration/#functions-duration
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function dateadd(date, amount, unit) {
    const duration = { [unit.value]: amount };
    return group(plus(date, new Function("duration", [new Map(duration)])));
}
/**
 * Cypher statement to subtract a duration to a date
 * Wrapped in a group `()` to avoid precedence issues
 *
 * @param date The date to substract the duration to
 * @param amount The amount of time to substract
 * @param unit The unit of time to substract (years, quarters, months, weeks, days, hours, minutes, seconds, milliseconds, microseconds, nanoseconds)
 *
 * @example `datesub(now(), 1, "days")` => `(datetime() - duration({days: 1}))`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/duration/#functions-duration
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function datesub(date, amount, unit) {
    const duration = { [unit.value]: amount };
    return group(minus(date, new Function("duration", [new Map(duration)])));
}
/**
 * Cypher statement to get the current datetime
 *
 * @example `now()` => `datetime()`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/#functions-datetime-current
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function now() {
    return datetime();
}
/**
 * Cypher statement to convert an array of literals to a Cypher List
 *
 * @param options The array of literals to convert to a list
 *
 * @example `tags("tag1", "tag2")` => `["tag1", "tag2"]`
 * @example `tags(1, 2, 3)` => `[1, 2, 3]`
 * @see https://neo4j.com/docs/cypher-manual/4.4/values-and-types/lists/#cypher-lists-general
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function tags(...options) {
    return new List(options);
}
/**
 * Cypher statement to wrap an expression depending on a value in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param maybeEmptyValue The value to test
 * @param resultIfNotEmpty The expression to return if the value is not empty and not null
 * @param resultIfEmpty The expression to return if the value is empty or null (default: `null`)
 * @returns `resultIfEmpty` (default: `null`) if the value is empty or null, `resultIfNotEmpty` otherwise
 *
 * @example `exprIfValueNotEmptyOrNull(value, expr)` => `CASE WHEN value IS NULL OR value = "" THEN null ELSE expr END`
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function exprIfValueNotEmptyOrNull(maybeEmptyValue, resultIfNotEmpty, resultIfEmpty = Null) {
    return new Case(or(isNull(maybeEmptyValue), eq(maybeEmptyValue, new Literal(""))))
        .when(new Literal(false)).then(resultIfNotEmpty)
        .else(resultIfEmpty);
}
/**
 * Cypher statement to get the duration between two instants
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param instant1 The first instant
 * @param instant2 The second instant
 *
 * @example `durationbetween(now(), now())` => `duration.between(datetime(), datetime())`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/duration/#functions-duration-between
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function durationbetween(instant1, instant2) {
    const expr = new Function("duration.between", [instant1, instant2]);
    return exprIfValueNotEmptyOrNull(instant1, exprIfValueNotEmptyOrNull(instant2, expr));
}
/**
 * Cypher statement to truncate a date to a specific unit casted to a string if wanted
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param unit The unit to truncate to (year, month, week, day, ...)
 * @param temporalInstantValue The datetime to truncate
 * @param hasString If true, the result will be casted to a string, otherwise it will be a datetime
 *
 * @example `dateTruncate("day", now(), true)` => `toString(datetime.truncate("day", datetime()))`
 * @example `dateTruncate("day", now(), false)` => `datetime.truncate("day", datetime())`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/#functions-datetime-truncate
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function dateTruncate(unit, temporalInstantValue, hasString = true) {
    let expr = new Function("datetime.truncate", [unit, temporalInstantValue]);
    if (hasString)
        expr = toString(expr);
    return exprIfValueNotEmptyOrNull(temporalInstantValue, expr);
}
/**
 * Cypher statement to truncate a date to a day casted to a string if wanted
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param temporalInstantValue The datetime to truncate
 * @param hasString If true, the result will be casted to a string, otherwise it will be a datetime
 *
 * @example `truncateDay(now(), true)` => `toString(datetime.truncate("day", datetime()))`
 * @example `truncateDay(now(), false)` => `datetime.truncate("day", datetime())`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/#functions-datetime-truncate
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function truncateDay(temporalInstantValue, hasString = true) {
    return dateTruncate(new Literal("day"), temporalInstantValue, hasString);
}
/**
 * Cypher statement to truncate a date to a week casted to a string if wanted
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param temporalInstantValue The datetime to truncate
 * @param hasString If true, the result will be casted to a string, otherwise it will be a datetime
 *
 * @example `truncateWeek(now(), true)` => `toString(datetime.truncate("week", datetime()))`
 * @example `truncateWeek(now(), false)` => `datetime.truncate("week", datetime())`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/#functions-datetime-truncate
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function truncateWeek(temporalInstantValue, hasString = true) {
    return dateTruncate(new Literal("week"), temporalInstantValue, hasString);
}
/**
 * Cypher statement to truncate a date to a month casted to a string if wanted
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param temporalInstantValue The datetime to truncate
 * @param hasString If true, the result will be casted to a string, otherwise it will be a datetime
 *
 * @example `truncateMonth(now(), true)` => `toString(datetime.truncate("month", datetime()))`
 * @example `truncateMonth(now(), false)` => `datetime.truncate("month", datetime())`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/#functions-datetime-truncate
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function truncateMonth(temporalInstantValue, hasString = true) {
    return dateTruncate(new Literal("month"), temporalInstantValue, hasString);
}
/**
 * Cypher statement to truncate a date to a year casted to a string if wanted
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param temporalInstantValue The datetime to truncate
 * @param hasString If true, the result will be casted to a string, otherwise it will be a datetime
 *
 * @example `truncateYear(now(), true)` => `toString(datetime.truncate("year", datetime()))`
 * @example `truncateYear(now(), false)` => `datetime.truncate("year", datetime())`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/#functions-datetime-truncate
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function truncateYear(temporalInstantValue, hasString = true) {
    return dateTruncate(new Literal("year"), temporalInstantValue, hasString);
}
/**
 * Cypher statement to get the median of a list of values
 *
 * @param value The list of values
 *
 * @example `median(1, 2, 3)` => `apoc.agg.median([1, 2, 3])`
 * @see https://neo4j.com/labs/apoc/4.4/overview/apoc.agg/apoc.agg.median/
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function median(...value) {
    return new Function("apoc.agg.median", value);
}
/**
 * Cypher statement to get the number of unique (using DINSTINCT) values in a list of values
 *
 * @param expr The list of values
 *
 * @example `countUnique(property)` => `count(DISTINCT property)`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/aggregating/#functions-count
 * @see https://neo4j.com/docs/cypher-manual/4.4/syntax/operators/#syntax-using-the-distinct-operator
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function countUnique(expr) {
    return new DistinctFunction("count", [expr]);
}
/**
 * Cypher statement to collect unique (using DINSTINCT) values in a list of values
 *
 * @param expr The list of values
 *
 * @example `collectUnique(property)` => `collect(DISTINCT property)`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/aggregating/#functions-collect
 * @see https://neo4j.com/docs/cypher-manual/4.4/syntax/operators/#syntax-using-the-distinct-operator
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function collectUnique(expr) {
    return new DistinctFunction("collect", [expr]);
}
/**
 * Cypher statement to cast a value to a float
 *
 * @param expr The value to cast
 *
 * @example `float(property)` => `toFloat(property)`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/scalar/#functions-tofloat
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function float(expr) {
    return new Function("toFloat", [expr]);
}
/**
 * Cypher statement to get the duration between two instants in seconds
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param instant1 The first instant
 * @param instant2 The second instant
 *
 * @example `durationInSeconds(now(), now())` => `duration.inSeconds(datetime(), datetime()).seconds`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/duration/#functions-duration-inseconds
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function durationInSeconds(instant1, instant2) {
    const expr = new SuffixedFunction("duration.inSeconds", ".seconds", [instant1, instant2]);
    return exprIfValueNotEmptyOrNull(instant1, exprIfValueNotEmptyOrNull(instant2, expr));
}
/**
 * Cypher statement to get the duration between two instants in days
 * The statement is wrapped in a CASE statement to avoid errors when the tested value is empty or null
 *
 * @param instant1 The first instant
 * @param instant2 The second instant
 *
 * @example `durationInDays(now(), now())` => `duration.inDays(datetime(), datetime()).days`
 * @see https://neo4j.com/docs/cypher-manual/4.4/functions/temporal/duration/#functions-duration-indays
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function durationInDays(instant1, instant2) {
    const expr = new SuffixedFunction("duration.inDays", ".days", [instant1, instant2]);
    return exprIfValueNotEmptyOrNull(instant1, exprIfValueNotEmptyOrNull(instant2, expr));
}
/**
 * Cypher statement to evaluate a predicate and return an expression if the predicate is true or another expression if the predicate is false
 *
 * @param left The left operand of the predicate
 * @param operator The operator of the predicate
 * @param right The right operand of the predicate
 * @param trueExpr The expression to return if the predicate is true
 * @param falseExpr The expression to return if the predicate is false
 * @returns `trueExpr` if the predicate is true, `falseExpr` otherwise if provided
 *
 * @example `if(a = 1, a, b)` => `CASE WHEN a = 1 THEN a ELSE b END`
 * @example `if(a IS NOT null, a)` => `CASE WHEN a IS NOT NULL THEN a END`
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function ifFn(left, operator, right, trueExpr, falseExpr) {
    if (!(operator instanceof Literal))
        throw new Error("if() function needs a literal for operator as second argument");
    const operatorValue = operator.value;
    let predicate;
    switch (operatorValue) {
        case "=":
            predicate = eq(left, right);
            break;
        case "<>":
            predicate = not(eq(left, right));
            break;
        case ">":
            predicate = gt(left, right);
            break;
        case ">=":
            predicate = gte(left, right);
            break;
        case "<":
            predicate = lt(left, right);
            break;
        case "<=":
            predicate = lte(left, right);
            break;
        case "IS":
            if (right instanceof Literal && right.value === null)
                predicate = isNull(left);
            else
                throw new Error("if() function needs a literal with null value for IS operator");
            break;
        case "IS NOT":
            if (right instanceof Literal && right.value === null)
                predicate = isNotNull(left);
            else
                throw new Error("if() function needs a literal with null value for IS NOT operator");
            break;
        case "IN":
            predicate = inOp(left, right);
            break;
        case "NOT IN":
            predicate = not(inOp(left, right));
            break;
    }
    if (!predicate)
        throw new Error(`if() function needs a valid operator as second argument, got ${String(operatorValue)}`);
    const caseExpr = new Case(predicate).when(new Literal(true)).then(trueExpr);
    if (falseExpr)
        caseExpr.else(falseExpr);
    return caseExpr;
}
/**
 * Cypher statement to returns true if the given list or map contains no elements, or if the given STRING contains no characters.
 *
 * @param expr The expression to check
 *
 * @example `isEmpty(property)` => `isEmpty(property)`
 * @see https://neo4j.com/docs/cypher-manual/current/functions/predicate/#functions-isempty
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function isEmpty(expr) {
    return new Function("isEmpty", [expr]);
}
/**
 * Cypher statement to format dates.
 *
 * @param date The date to format
 * @param format The desired date format
 *
 * @example `formatDate(property, "yyyy/MM/dd")` => `apoc.date.format(property, "yyyy/MM/dd")`
 * @see https://neo4j.com/docs/apoc/current/overview/apoc.date/apoc.date.format/#usage-apoc.date.format
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function formatDate(date, format) {
    const expr = exprIfValueNotEmptyOrNull(date, new Function("apoc.temporal.format", [date, format]));
    return expr;
}
/**
 * Cypher statement to return the second expression,
 * if the first expression is :
 *  - empty
 *  - null
 *  - a list with an unique empty element
 * otherwise return the first expression.
 *
 * @param expr1 The first expression
 * @param expr2 The second expression
 *
 * @example `ifEmpty(property, "default")` => `CASE WHEN property IS NOT NULL THEN property ELSE "default" END`
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export function ifEmpty(expr1, expr2) {
    return new Case(and(eq(size(expr1), new Literal(1)), isEmpty(head(expr1))))
        .when(new Literal(true)).then(expr2)
        .else(exprIfValueNotEmptyOrNull(expr1, expr1, expr2));
}
/**
 * All custom functions usable in the query builder and in the app formulas
 *
 * @category Query Builder
 * @subcategory Custom Functions
 */
export const CUSTOM_FUNCTIONS = {
    dateadd,
    datesub,
    now,
    durationbetween,
    tags,
    dateTruncate,
    truncateDay,
    truncateWeek,
    truncateMonth,
    truncateYear,
    median,
    countUnique,
    collectUnique,
    float,
    durationInSeconds,
    durationInDays,
    if: ifFn,
    isEmpty,
    formatDate,
    ifEmpty,
};
