export enum QRItemType {
  GraphObject = "o",
  Action = "a",
}

/**
 * Deserializes a QR code, returning an object type and id if valid, null otherwise
 */
export function deserialize(s: string) {
  const regex = /bonx:([oa]):(\w*)/
  const match = s.match(regex)

  // Ensure string is a valid QR code, return null otherwise
  if (!match || match.length < 3)
    return

  const item_type_id = match[1]
  const item_id = match[2]

  // Why the f**k isn't enum deserialisation working nor throwing error ???
  // const item_type = QRItemType![item_type_id] as QRItemType;
  let item_type: QRItemType
  if (item_type_id === "a")
    item_type = QRItemType.Action
  else if (item_type_id === "o")
    item_type = QRItemType.GraphObject
  else
    throw new Error(`No valid QRItemType for '${item_type_id}'`)

  return {
    item_type,
    item_id,
  }
}

/**
 * Serializes any Bonx object (graph or action) into a string to embed into QR code
 * @param item_type
 * @param item_id
 */
export function serialize(item_type: QRItemType, item_id: string) {
  return `bonx:${item_type}:${item_id}`
}
