import { defineStore } from "pinia"

import ApiHelper from "../helpers/ApiHelper"

export const apiStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "api",
  // a function that returns a fresh state
  state: () => ({
    api: new ApiHelper(),
  }),

  // optional getters
  getters: {
    getApiClient(): ApiHelper {
      return this.api
    },
  },
  // optional actions
  actions: {

  },
})
