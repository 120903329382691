export function getOptions(inOptions) {
    const type = !inOptions || !inOptions.type ? 'png' : inOptions.type;
    const defaults = type === 'png' ? bitmapOptions : vectorOptions;
    return { ...defaults, ...inOptions };
}
const commonOptions = {
    type: 'png',
    parseUrl: false,
    ecLevel: 'M',
};
const bitmapOptions = {
    ...commonOptions,
    margin: 4,
    size: 5,
};
const vectorOptions = {
    ...commonOptions,
    margin: 1,
    size: 0,
};
