export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/builds/bbqx/webapp/app/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/builds/bbqx/webapp/app/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/builds/bbqx/webapp/app/src/pages/about.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/auth',
    /* internal name: '/auth' */
    /* no component */
    children: [
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/builds/bbqx/webapp/app/src/pages/auth/login.vue'),
        /* no children */
        meta: {
          "layout": "default"
        }
      },
      {
        path: 'reset-pass',
        name: '/auth/reset-pass',
        component: () => import('/builds/bbqx/webapp/app/src/pages/auth/reset-pass.vue'),
        /* no children */
        meta: {
          "layout": "default"
        }
      }
    ],
  },
  {
    path: '/automation',
    /* internal name: '/automation' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/automation/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/automation/[id]/',
            component: () => import('/builds/bbqx/webapp/app/src/pages/automation/[id]/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          },
          {
            path: 'oauth',
            name: '/automation/[id]/oauth',
            component: () => import('/builds/bbqx/webapp/app/src/pages/automation/[id]/oauth.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/automations',
    name: '/automations',
    component: () => import('/builds/bbqx/webapp/app/src/pages/automations.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/client',
    /* internal name: '/client' */
    /* no component */
    children: [
      {
        path: 'new',
        name: '/client/new',
        component: () => import('/builds/bbqx/webapp/app/src/pages/client/new.vue'),
        /* no children */
        meta: {
          "layout": "home",
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/data',
    name: '/data',
    component: () => import('/builds/bbqx/webapp/app/src/pages/data.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/form',
    /* internal name: '/form' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/form/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/form/[id]/',
            component: () => import('/builds/bbqx/webapp/app/src/pages/form/[id]/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'shared',
        /* internal name: '/form/shared' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/form/shared/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/form/shared/[id]/',
                component: () => import('/builds/bbqx/webapp/app/src/pages/form/shared/[id]/index.vue'),
                /* no children */
                meta: {
                  "layout": "minimal"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/forms',
    name: '/forms',
    component: () => import('/builds/bbqx/webapp/app/src/pages/forms.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/integrations',
    /* internal name: '/integrations' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/integrations/[id]' */
        /* no component */
        children: [
          {
            path: 'oauth',
            name: '/integrations/[id]/oauth',
            component: () => import('/builds/bbqx/webapp/app/src/pages/integrations/[id]/oauth.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/object',
    /* internal name: '/object' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/object/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/object/[id]/',
            component: () => import('/builds/bbqx/webapp/app/src/pages/object/[id]/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/objects',
    name: '/objects',
    component: () => import('/builds/bbqx/webapp/app/src/pages/objects.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/process',
    /* internal name: '/process' */
    /* no component */
    children: [
      {
        path: 'edit',
        /* internal name: '/process/edit' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/process/edit/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/process/edit/[id]/',
                component: () => import('/builds/bbqx/webapp/app/src/pages/process/edit/[id]/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/processes',
    name: '/processes',
    component: () => import('/builds/bbqx/webapp/app/src/pages/processes.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/profile',
    name: '/profile',
    component: () => import('/builds/bbqx/webapp/app/src/pages/profile.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/role',
    /* internal name: '/role' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/role/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/role/[id]/',
            component: () => import('/builds/bbqx/webapp/app/src/pages/role/[id]/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/settings',
    name: '/settings',
    component: () => import('/builds/bbqx/webapp/app/src/pages/settings.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  },
  {
    path: '/user',
    /* internal name: '/user' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/user/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/user/[id]/',
            component: () => import('/builds/bbqx/webapp/app/src/pages/user/[id]/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/view',
    /* internal name: '/view' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/view/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/view/[id]/',
            component: () => import('/builds/bbqx/webapp/app/src/pages/view/[id]/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'edit',
        /* internal name: '/view/edit' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/view/edit/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/view/edit/[id]/',
                component: () => import('/builds/bbqx/webapp/app/src/pages/view/edit/[id]/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/view-group',
    /* internal name: '/view-group' */
    /* no component */
    children: [
      {
        path: 'edit',
        /* internal name: '/view-group/edit' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/view-group/edit/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/view-group/edit/[id]/',
                component: () => import('/builds/bbqx/webapp/app/src/pages/view-group/edit/[id]/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/views',
    name: '/views',
    component: () => import('/builds/bbqx/webapp/app/src/pages/views.vue'),
    /* no children */
    meta: {
      "layout": "home",
      "requiresAuth": true
    }
  }
]
