automation:
  add_step: Aggiungi passaggio
  add_trigger: Aggiungi un trigger
  create_new: Nuova automazione
  error_launch: L'avvio dell'automazione non è riuscito
  select_trigger_description: Seleziona un trigger per iniziare a configurare la tua automazione
  success_launch: Il lancio dell'automazione è stato completato con successo
  trigger_api: Quando viene ricevuta una chiamata API
  trigger_graphquery: Quando viene eseguita una query graphQL
  trigger_transformation: Quando un oggetto viene modificato
automations:
  automation_name_placeholder: Nome dell'automazione
  create_automation: Crea una nuova automazione
  creation_automation_name: Nome dell'automazione
  modal_new_automation_description: >-
    Le automazioni ti consentono di creare processi automatizzati per creare,
    modificare e salvare i dati in Bonx.
  run:
    datetime: Data di esecuzione
    id: Sarei scappato
  step:
    end_datetime: Data di fine passaggio
    name: Nome del passo
    start_datetime: Data di inizio del passaggio
  tab_detail: Automatizzazione
  tab_history: Storia
blocks:
  qrCodeFullSize: Pulsante Codice QR in modalità a schermo intero
button:
  about: Di
  back: Di ritorno
  toggle_dark: Modalità scura
  toggle_langs: Cambia lingua
dashboard:
  last_30_days: Ultimi 30 giorni
data_connection:
  add_step: Aggiungi passaggio
  auth: Metodo di autenticazione
  auth_basic: di base
  data_connection_name_placeholder: La mia connessione dati
  data_connection_step_type_placeholder: Segnaposto tipo passo connessione dati
  data_connection_steps: Dettaglio passo
  document_url: URL condiviso del foglio di Google
  input_data: Ingresso variabile
  json_edition_label: Modifica data_connection json
  name: Nome del passo
  password: Parola d'ordine
  query: Domanda
  save_file: Il file è stato salvato
  save_output: Variabile di uscita
  step_json_edition_label: Modifica il passaggio json
  tab: Scheda del foglio Google selezionata
  url: URL API
  username: Nome utente
email:
  body: Corpo dell'e-mail
  subject: E-mail oggetto
filters:
  operators_doesnt_include: Non contiene
  operators_equals_to: (==) uguale a
  operators_greater_than: (>) maggiore di
  operators_greater_than_or_equal_to: (>=) maggiore o uguale a
  operators_includes: Contiene
  operators_is_empty: è vuoto
  operators_is_not_empty: non è vuoto
  operators_less_than: (<) minore di
  operators_less_than_or_equal_to: (<=) minore o uguale a
  operators_no_operator: Nessuna condizione
  operators_not_equal_to: (!=) diverso da
form.dollar_helper: >-
  Le variabili precedute dal segno "$" sono interne al form. 

  Queste variabili devono quindi essere collegate agli attributi del modello di
  seguito.
form_field_max_value: Valore massimo
forms:
  autofill_end: >-
    Questo campo viene compilato automaticamente da Bonx al momento dell'invio
    del modulo, utilizzando la data e l'ora correnti.
  autofill_information: Questo campo è compilato automaticamente da Bonx
  autofill_start: >-
    Questo campo viene compilato automaticamente da Bonx all'apertura del
    modulo, utilizzando la data e l'ora correnti.
  base_model: Modello basso
  create_form: Crea modulo
  create_new: Nuova forma
  creation_form_name: Nome del modulo
  edit_no_field_warning: Questo modulo attualmente non ha campo
  field_multiple_answers_allowed: Puoi selezionare più opzioni qui
  field_no_options: Non ci sono ancora opzioni disponibili.
  form_field_date: Data
  form_field_display: Schermo
  form_field_display_readquery_placeholder: Aggiungi una query di lettura secondaria (opzionale)
  form_field_document: Documento
  form_field_has_min_max_values: Ha valori min max
  form_field_image: Immagine
  form_field_label_label: Nome del campo
  form_field_min_value: Valore minimo
  form_field_multiple_choice: Scelte multiple
  form_field_name_label: Chiave di attributo
  form_field_number: Numero
  form_field_option: Opzione
  form_field_option_required: >-
    Un campo di opzione è richiesto per impostazione predefinita, selezionare il
    campo "Scelte multiple" se il modulo può restituire un valore vuoto.
  form_field_options_label: Elenco delle scelte
  form_field_options_placeholder: Aggiungi una scelta
  form_field_read_query: Leggi la domanda
  form_field_required_label: Obbligatorio
  form_field_text: Campo di testo
  form_field_text_description_placeholder: Aggiungi una descrizione o un helper qui (facoltativo)
  form_field_text_label_placeholder: Aggiungi un nome di campo
  form_field_text_name_placeholder: Aggiungi un nome di attributo
  form_field_text_value_placeholder: Aggiungi un valore predefinito (facoltativo)
  form_field_textarea_label: Testo lungo
  form_field_write_query: Scrivi domanda
  form_fields: Campi dei moduli
  form_readquery_placeholder: Aggiungi una query di lettura principale (facoltativo)
  form_title_placeholder: Nome del modulo
  form_writequery_placeholder: Aggiungi una query di scrittura
  formreadquery_placeholder: Aggiungi una query di lettura principale (facoltativo)
  modal_new_form_description: >-
    Il creatore di moduli ti consente di generare moduli che devono essere
    completati dai tuoi team e quindi automatizza il feedback dei dati.
  modal_share_form_description: >-
    Puoi condividere questo modulo via e-mail con un link univoco e
    pubblicamente accessibile.
  no_related_model_found: Nessun modello trovato
  share: Suddividere
  share_form_emails_label: Indirizzi email
forms.action_delete_form: Elimina modulo
forms.action_duplicate_form: Modulo duplicato
forms.choose_attribute: Scegli l'attributo
forms.creation_import_fields: Importa gli attributi del modello
forms.creation_related_model: ''
forms.creation_related_process: ''
forms.creation_related_process_step: Passaggio del processo correlato
forms.deletion_confirmation_body: |-
  Sei sicuro di voler eliminare questo modulo? 
  Solo un amministratore Bonx potrà ripristinarlo in futuro.
forms.deletion_confirmation_title: Cancellazione del modulo
forms.error_save_not_admin: Solo un amministratore può salvare un modulo modificato
forms.form_field_no_related_model_placeholder: Nessun modello correlato
forms.form_field_no_relationship_placeholder: Nessuna relazione
forms.form_field_relation: Relazione
forms.form_field_relationship_allow_existing_object_label: Consenti il ​​collegamento di un oggetto esistente
forms.form_field_relationship_allow_new_object_label: Consenti la creazione di un nuovo elemento collegato
forms.form_field_relationship_related_model_label: Seleziona un modello correlato
forms.form_field_relationship_value_label: Seleziona una relazione
forms.form_share: Modulo condiviso pubblicamente
forms.labels: Etichette
forms.new_form_advanced: Avanzate
forms.new_form_classic: Classico
forms.no_model: Questo modulo non è collegato a un modello esistente
forms.no_related_step: Non collegato a una fase del processo
forms.toast_deletion_success: Questo modulo è stato eliminato con successo.
forms.toast_duplication_success: |-
  Il modulo è stato duplicato con successo. 
  Stai per essere reindirizzato a questo nuovo modulo.
global:
  actions: Azioni
  add: Aggiungere
  automations: Automazioni
  cancel: Annulla
  click_button_reload: Si prega di fare clic sul pulsante qui sotto per ricaricare!
  client: Azienda
  client_name: Nome del cliente
  clients: Clienti
  close: Chiudere
  collaboration: collaborazione
  comment: Commentatore
  compare: Confrontare
  create: Creare
  currency: Moneta
  dashboard: Pannello
  data: Dati
  data_connections: Connessioni dati
  data_saved: I tuoi dati sono stati salvati
  days: Giorni
  delete: Elimina
  description: Descrizione
  disabled: Disabilitato
  disabled_users: Utenti disattivati
  display: Schermo
  duplicate: Duplicato
  duration: Durata
  edit: Modificare
  email: E-mail
  entity: Entità
  error: Errore
  error_json: JSON non è corretto
  error_login_invalid_password: Password errata.
  error_login_invalid_user: Utente non trovato, ricontrolla il nome utente.
  error_login_unknown: >-
    Errore di registrazione sconosciuto, contatta il team Bonx utilizzando il
    pulsante rotondo in basso a destra in questa schermata.
  error_password_validation: >-
    La tua password deve essere lunga almeno 8 caratteri e deve contenere almeno
    una minuscola, una maiuscola e un numero.
  failed: Fallito
  file_input:
    no_file: Non hai ancora inviato alcun file
  filter: Filtro
  first_name: nome di battesimo
  follow_up: Seguito
  forgot_password: Ha dimenticato la password
  forms: Le forme
  from: A partire dal
  groups: Gruppi
  home: Casa
  id: Id
  is_required: è obbligatorio
  label: Etichetta
  labels: |-
    etichetta | 
    etichetta | 
    etichette
  large: Di grandi dimensioni
  last_name: Cognome
  launch: Lanciare
  login: Login
  logout: Disconnettersi
  medium: medio
  missing_write_request: Non hai fornito una richiesta di scrittura
  models: |-
    modello | 
    modello | 
    Modelli
  month:
    april_short: apr
    august_short: ago
    december_short: dic
    february_short: febbraio
    january_short: gen
    july_short: lug
    june_short: giu
    march_short: mar
    may_short: Maggio
    november_short: nov
    october_short: ott
    september_short: sett
  name: Nome
  new: Nuovo
  new_version: È disponibile una nuova versione!
  not-found: Non trovato
  objects: |-
    Oggetto | 
    Oggetto | 
    Oggetti
  order: ordine
  overview: Panoramica
  parameters: Parametri
  password: Parola d'ordine
  phone: Telefono
  photo: Foto
  photo_url: URL foto
  process: Processi
  project: Progetto
  projects: Progetti
  reload: Ricaricare
  remember_me: Ricordati di me
  reset_email_sent: >-
    È stata inviata un'e-mail per reimpostare la password. Si prega di fare clic
    sul collegamento quando lo si ottiene
  reset_password: Resetta la password
  role: Ruolo
  rows: righe
  save: Salva
  saving: Salvataggio...
  search: Ricerca
  send: Inviare
  send_reset_email: Invia email di ripristino
  settings: Impostazioni
  show: Mostrare
  small: Piccolo
  status: Stato
  success: Successo
  table:
    nav: Impaginazione tabella
  task: Compito | Compito | Compiti
  total_report: Rapporto totale
  training_and_help: Centro risorse
  type: Tipo
  update: Upate
  user: Utente
  users: Utenti
  users_and_permissions: Utenti e autorizzazioni
  validate: Convalidare
  value: Valore
  views: |-
    Visualizza | 
    Visualizza | 
    Visualizzazioni
  week: Settimana
  you_dont_have_access_to_this_page: Non hai accesso a questa pagina
global.attribute: Attributo
global.close: Vicino
global.instance: Esempio
global.model: Modello
global.toast_key_copy_success_content: La chiave è stata copiata con successo negli appunti.
global.toast_succes_copy_to_clipboard: Il contenuto della tabella è stato copiato con successo negli appunti
global.unsinchronized_store_header_content: >-
  Il seguente elemento è stato aggiornato. 

  Pertanto, non sarà possibile salvare alcuna edizione prima di aggiornare la
  pagina.
global.unsinchronized_store_header_content_pt1: I seguenti elementi sono stati aggiornati
global.unsinchronized_store_header_content_pt2: >-
  Pertanto, non sarà consentito eseguire alcuna edizione prima di aggiornare la
  pagina
global.unsinchronized_store_header_title: La configurazione di Bonx è stata modificata da un altro utente
global.update: Aggiornamento
groups:
  group_name: Nome del gruppo
  group_order: Ordine di gruppo
  groups_list: Lista di gruppo
  toast_edit_success: Le modifiche salvate nel gruppo sono state salvate correttamente
home:
  greetings: Saluti
modal_confirmation_popup_cancel: No.
modal_confirmation_popup_confirm: Sì
object:
  attribute:
    attributes: |-
      attributo | 
      attributo | 
      attributi
  attributes: Attributi dell'oggetto
  attributes_list: Elenco attributi
  error_attribute_with_same_name_exists: Un attributo esistente ha già lo stesso nome
  error_loading: >-
    Si è verificato un errore durante il caricamento del contenuto di questa
    pagina
  error_no_primary_attribute: Nessun attributo primario è stato definito per questo modello
  related_objects: |-
    Oggetto correlato | 
    Oggetto correlato | 
    Oggetti correlati
  relation:
    attributes_list: Elenco degli attributi di relazione
  relationship:
    object: Oggetto collegato
    to_object: All'oggetto
  relationship_list: Elenco delle relazioni
  relationships: |-
    relazione | 
    relazione | 
    relazioni
  selected_setting_object: Tipo di oggetto
objects:
  add_attribute: Aggiungi un attributo
  add_label: Crea un'etichetta
  add_object: Crea un oggetto
  attributes_subtype_float: Virgola mobile
  attributes_subtype_integer: Intero
  attributes_subtype_link: Collegamento
  attributes_subtype_plain_text: Testo semplice
  data_model: Modello di dati
  data_models: Modelli di dati
  edit_attribute: Modifica un attributo
  edit_label: Modifica un'etichetta
  edit_object: Modifica un oggetto
  label:
    placeholder: Scegli un'etichetta
  label_objects: Oggetti collegati a questa etichetta
  label_relations: Relazioni legate a questa etichetta
  labels: Etichette
  labels_list: Elenco etichette
  models: Modelli
  models_list: Liste di modelli
  objects_list: Elenco oggetti
  overview: panoramica
  relation:
    add_relation: Aggiungi una relazione
    edit_relation: Modifica una relazione
  relations: Relazioni
otable:
  error:
    table_has_no_column: >-
      Questa tabella sarà visibile non appena verrà aggiunta una chiave come
      colonna, contatta un amministratore della tua azienda.
pages:
  automation: Automazione
  data-connection: Connessione dati
  object: Oggetto
process.finished: Finito
process.in_progress: In corso
process.waiting: In attesa
processes:
  next_step: Passo successivo
  step_name: Nome del passo
profile:
  gantt_size_label: Visualizzazione diagramma di Gantt
  home_page: Pagina iniziale
  my_profile: Il mio profilo
  settings: Impostazioni
timeline:
  external_user: Utente esterno
toast:
  form_submit_content: Il modulo è stato inviato con successo
  print_success: Una pagina si aprirà presto
  view_error_no_model_title: Bonx ti consentirà presto di modificare viste complesse
  view_error_no_model_title_content: >-
    Questa vista è stata creata manualmente, contatta il tuo reparto IT o un
    amministratore Bonx.
toast.form_field_file_submit_content_error: Questo modulo richiede l'invio di un file.
user:
  add_label: Crea un'etichetta
  data_model:
    labels: Etichette
    models: Modelli
    relations: Relazioni
  data_models: Modelli di dati
  edit_label: Modifica un'etichetta
  label:
    placeholder: Trova un'etichetta...
  models_list: Elenco etichette
view:
  action_move_up: Andare avanti
  no_title: Senza titolo
  toast_edit_success: Il dettaglio della vista è stato modificato con successo
views:
  action_move_down: Abbassati
  add_attribute: Nuovo attributo
  add_block: Nuovo blocco
  block_name: Nome del blocco
  block_type: Digitare il blocco
  create_new_view: Crea una nuova vista
  create_new_view_description: >-
    Le visualizzazioni ti consentono di visualizzare i dati che ti interessano,
    in diverse forme e di condividerli con i tuoi team.
  create_view: Crea una nuova vista
  creation_automation_name_label: Nome dell'automazione
  creation_form_name_label: Nome del modulo
  creation_view_model: Modello correlato
  creation_view_name: Nome della vista
  default_setup_element_name: Dati relativi al modello
  default_view_group_name: Gruppo predefinito
  edit_attributes_options: Opzioni di attributi
  edit_attributes_options_add_colors: Colori
  edit_attributes_options_add_icon: Icone
  edit_attributes_options_add_status: Stato
  edit_attributes_options_background_color: Colore di sfondo
  edit_attributes_options_border_color: Colore del bordo
  edit_attributes_options_description: >-
    La selezione delle opzioni di un attributo ti consente di modificarne le
    impostazioni profonde.
  edit_attributes_options_hide_json: Nascondere dettagli
  edit_attributes_options_icon: Icona
  edit_attributes_options_no_attributes: Questo blocco non possiede ancora alcun attributo.
  edit_attributes_options_show_json: Mostra dettagli
  edit_attributes_options_status_error: 'Stato: errore'
  edit_attributes_options_status_success: 'Stato: successo'
  edit_attributes_options_status_warning: 'Stato: avviso'
  edit_import_model: Importa un modello
  edit_import_model_description: >-
    L'importazione di un modello consente di aggiungere in un clic tutti i suoi
    attributi come assi del blocco.
  edition_deletion: Elimina la vista
  edition_no_columns: >-
    Queste caselle di controllo saranno visibili non appena un attributo verrà
    aggiunto al modello, contatta un amministratore della tua azienda.
  edition_no_related_objects: Questo modello non è correlato a nessun altro modello.
  edition_primary_table: Tabella dei dati primari
  edition_secondary_related_objects: Oggetti correlati
  edition_selected_columns: Colonne
  error:
    query_invalid_or_missing: La richiesta fornita è vuota o ha generato un errore.
  filter_label_placeholder: Etichetta da filtrare
  filter_value_placeholder: Valore
  filter_view: Filtra questa vista
  filters_add_filter: Aggiungi una condizione
  gantt:
    bar:
      no_label: Questa barra non possiede etichetta
    precision:
      day: giorni
      display_by: Visualizza entro
      month: mesi
  id: Id
  label: Etichetta
  modal_new_view_description: >-
    Le visualizzazioni ti consentono di visualizzare i tuoi dati nel modo
    desiderato e di condividerli con i tuoi team.
  query: Richiesta GraphQL
  toast_edit_success: La vista è stata modificata con successo
  type: Tipo
  view_block_name_placeholder: Il mio nome del blocco di visualizzazione (facoltativo)
  view_name_placeholder: Visualizza nome
  view_parameters_title: Visualizza parametri
  views_list: Elenco visualizzazioni
views.deletion_confirmation_body: >-
  Sei sicuro di voler eliminare questa vista? 

  Tutti i suoi dati verranno conservati nel caso in cui tu abbia commesso un
  errore e desideri annullarlo.
views.deletion_confirmation_title: Visualizza la cancellazione
views.toast_deletion_success: Questa vista è stata eliminata con successo.
