export const FIREBASE_CONFIG_STAGING = {
  apiKey: "AIzaSyCrbj4YQeYR17JFJNNzI1JGtytFbb9e01M",
  authDomain: "thebarbqx-staging.firebaseapp.com",
  projectId: "thebarbqx-staging",
  storageBucket: "thebarbqx-staging.appspot.com",
  messagingSenderId: "331262823539",
  appId: "1:331262823539:web:44b76911557b892ac42e06",
}

export const FIREBASE_CONFIG_PRODUCTION = {
  apiKey: "AIzaSyAE73d_VZ27okfsCs9oUMNwQNua4a6zRsc",
  authDomain: "the-barbqx-prod.firebaseapp.com",
  projectId: "the-barbqx-prod",
  storageBucket: "the-barbqx-prod.appspot.com",
  messagingSenderId: "60919803893",
  appId: "1:60919803893:web:f42c35ebf0a74f8381fabc",
}

export const BACKEND_URL_DEV = `${window.location.protocol}//${window.location.hostname}:8080`
export const BACKEND_URL_STAGING = "https://api-node-wiwydruanq-ew.a.run.app"
export const BACKEND_URL_PRODUCTION = "https://api.bonx.tech"
export const DEFAULT_ZIPKIN_URL = "https://zipkin-l5sjees2rq-ew.a.run.app"

export const LOGGER_CONFIG = {
  key: "",
  projectId: "bbqx-staging",
  service: "bbqx-staging",
  disabled: false,
}

export function getEnv() {
  const { hostname } = window.location
  if (hostname.includes("staging"))
    return "staging"
  else if (hostname.includes("prod") || hostname.includes("app."))
    return "production"
  else return "dev"
}

export function getBackEndURL() {
  const env = getEnv()
  if (env === "staging")
    return BACKEND_URL_STAGING
  else if (env === "production")
    return BACKEND_URL_PRODUCTION
  else return import.meta.env.VITE_BACKEND_URL_GITPOD ?? BACKEND_URL_DEV
}

export function getFirebaseConfig() {
  const env = import.meta.env.VITE_FORCE_FB_ENV ?? getEnv()
  if (env === "staging")
    return FIREBASE_CONFIG_STAGING
  else if (env === "production")
    return FIREBASE_CONFIG_PRODUCTION
  else return FIREBASE_CONFIG_STAGING
}

/**
 * Returns the URL of the frontend
 * Beware : if you're on a localhost machine, this will still return either staging or production
 * For E2E tests this can be annoying if you're trying to test the local environment not the staging
 * @returns The URL of the frontend, depending on the environment
 */
export function getFrontEndURL() {
  const env = getEnv()
  if (env === "staging")
    return "https://staging.bonx.tech"
  else if (env === "production")
    return "https://app.bonx.tech"
  else return import.meta.env.VITE_FRONTEND_URL_GITPOD ?? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
}

export function getZipkinURL() {
  // We don't enable to push spans to Zipkin, from a local, production-configured, server
  // To avoid polluting production Zipkin with local spans
  // If that's something you actually wanted to do temporarily
  // Uncomment the following line and comment the one after it
  // const env = import.meta.env.VITE_FORCE_FB_ENV ?? getEnv()
  const env = getEnv()
  if (env === "dev")
    return "http://localhost:9411"
  return DEFAULT_ZIPKIN_URL
}
