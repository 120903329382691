import * as Sentry from "@sentry/vue"
import { VERSION } from "@bonx/common"
import type { UserModule } from "~/types"
import { getEnv } from "~/config"

export const install: UserModule = ({ app }) => {
  if (getEnv() === "dev")
    return

  // Staging DSN
  let dsn = "https://787068c22d054672b06d8e451fa7220a@o1342798.ingest.sentry.io/4504377458098176"

  if (getEnv() === "production")
    dsn = "https://1b631cf537ed48e2a59965b92ad538cc@o1342798.ingest.sentry.io/4504401859051520"

  Sentry.init({
    app,
    dsn,
    integrations: [
      new Sentry.BrowserTracing(),
    ],
    tracesSampleRate: 0.025,
    release: `bonx@${VERSION}`,
  })
}
