import type { UserModule } from "../types"
import { apiStore } from "../stores/api"

export const install: UserModule = ({ isClient, router }) => {
  if (isClient) {
    router.afterEach(() => {
      // Create a new traceId for each route change to avoid the previous traceId being used
      // and causing the spans to be linked to the previous route
      const newTraceId = apiStore().api.tracer.createRootId()
      apiStore().api.tracer.setId(newTraceId)
    })
  }
}
