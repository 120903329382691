export var ActionType;
(function (ActionType) {
    ActionType["GOTO"] = "goto";
    ActionType["SHOW"] = "show";
    ActionType["HIDE"] = "hide";
    ActionType["OPEN_DRAWER"] = "open_drawer";
    ActionType["UPDATE_OBJECT"] = "update_object";
    ActionType["REFRESH_BLOCK"] = "refresh_block";
    ActionType["OPEN_FORM"] = "open_form";
    ActionType["OPEN_DIALOG"] = "open_dialog";
    ActionType["DELETE_OBJECT"] = "delete_object";
    ActionType["CALLBACK"] = "callback";
})(ActionType || (ActionType = {}));
export var ActionComparator;
(function (ActionComparator) {
    ActionComparator["EQ"] = "==";
    ActionComparator["NEQ"] = "!=";
    ActionComparator["GT"] = ">";
    ActionComparator["LT"] = "<";
    ActionComparator["GTE"] = ">=";
    ActionComparator["LTE"] = "<=";
    ActionComparator["INCLUDES"] = "includes";
    ActionComparator["NOT_INCLUDES"] = "not_includes";
    ActionComparator["IS_EMPTY"] = "is_empty";
    ActionComparator["IS_NOT_EMPTY"] = "is_not_empty";
})(ActionComparator || (ActionComparator = {}));
