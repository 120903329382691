import timeago from "vue-timeago3"
import { fr } from "date-fns/locale"
import type { UserModule } from "../types"

export const install: UserModule = ({ app }) => {
  app.use(timeago, {
    locale: fr,
    autoUpdate: 10,
  })
}
