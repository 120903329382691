import type { Field, Node } from "@bonx/common"
import { isDate, isUndefined } from "lodash-es"
import { isValid, parse, parseISO } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"

function enforceDateOrUndefined(value: any, outputType: "date" | "datetime"): Date | undefined {
  if (!value)
    return value

  if (outputType === "datetime") {
    const converted = parseISO(value)

    if (!isValid(converted))
      throw new TypeError(`Unable to parse [${outputType}] value : ${value}`)

    return converted
  }

  if (outputType === "date") {
    if (value.endsWith("T00:00:00.000Z"))
      value = value.replace("T00:00:00.000Z", "")

    const converted = parse(value, "yyyy-MM-dd", new Date())

    if (!isValid(converted))
      throw new TypeError(`Unable to parse [${outputType}] value : ${value}`)

    return converted
  }

  throw new Error(`Invalid output type : ${String(outputType)}`)
}

/**
 * Deserialize node data from the backend using the schema
 * @param properties
 * @param schema
 */
export function enforceDataTypes(properties: Record<string, any>, schema: Array<Field>) {
  for (const field of schema) {
    if (!isUndefined(properties[field.name])) {
      if (field?.$formkit === "datetime")
        properties[field.name] = enforceDateOrUndefined(properties[field.name], "datetime")
      else if (field?.$formkit === "date")
        properties[field.name] = enforceDateOrUndefined(properties[field.name], "date")
    }
  }
  return properties
}

/**
 * Enforce strict typing on any value from by the frontend
 * This function doesn't have the same semantics as enforceDataTypes
 * Because if we were to set date or datetime to null or undefined instead of an empty string
 * They would not be passed to the backend (as JSON doesn't support null value fields)
 */
export function enforceStrictType(value: any, field: Field) {
  switch (field.$formkit) {
    case "date":
    case "datetime":
      value = value ? new Date(value) : ""
      break
    case "number":
      if (field.subtype === "float")
        value = Number.parseFloat(value)
      else
        return Number.parseInt(value)

      if (Number.isNaN(value))
        value = null

      break
  }
  return value
}

/**
 * Serialize node data before sending it to the backend using the schema
 * @param properties The properties of the node
 * @param schema The model schema
 * @returns The serialized properties
 */
export function serializeDataTypes(properties: Node<unknown>["properties"], schema: Array<Field>) {
  for (const [key, value] of Object.entries(properties)) {
    const field = schema.find(field => field.name === key)
    if (field?.$formkit === "datetime" && isDate(value))
      properties[key] = value.toISOString()
    else if (field?.$formkit === "date" && isDate(value))
      properties[key] = formatInTimeZone(value, "UTC", "yyyy-MM-dd")
  }
  return properties
}
