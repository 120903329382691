import { defineStore } from "pinia"
import { Form, FormService } from "@bonx/common"
import { cloneDeep } from "lodash-es"
import DBHelper from "../helpers/dbHelper/index"
import { CreateFormFieldInput } from "../components/Forms/Edition/formEdition"
import { userStore } from "./user"
import { globalStore } from "./global"

const dbHelper = new DBHelper()

/**
 * Input to update the indexes of the field and subfield being edited
 */
export interface UpdateEditIndexesInput {
  /**
   * Index of the primary field being edited
   */
  primaryFieldIndex?: number | null
  /**
   * Index of the subfield being edited
   */
  subFieldIndex?: number | null
  /**
   * Index of the subtable being edited
   */
  subTableIndex?: number | null
}

export const formsStore = defineStore({
  id: "forms",
  state: () => ({
    forms: {
      list: [] as Array<Form>,
    },
    edit: {
      form: null as null | Form,
      fieldInput: null as null | CreateFormFieldInput,
      fieldIndex: null as null | number,
      subFieldIndex: null as null | number,
      subFieldInput: null as null | CreateFormFieldInput,
      subTableIndex: null as null | number,
      subTableInput: null as null | CreateFormFieldInput & { type: "items" },
    },
    _formService: null as FormService | null,
  }),
  getters: {
    getForms(): Array<Form> {
      return this.forms.list
    },
    getFormService: state => () => {
      if (!state._formService)
        state._formService = new FormService(dbHelper, userStore().user.client_id)
      return state._formService
    },
  },
  actions: {
    async loadForms() {
      this.forms.list = await this.getFormService().list()
    },
    async createForm(form: Partial<Form>) {
      const user = userStore().user
      if (!user?.client_id)
        throw new Error("User has no client_id")

      const created = await this.getFormService().create(form)
      this.forms.list.push(created)
      await globalStore().updateStoreCache()
      return created.id
    },
    async dupplicateForm(formId: string): Promise<Form | null> {
      const user = userStore().user
      if (user) {
        const form = this.forms.list.find(form => form.id === formId)
        if (form) {
          form.title = `${form.title} (copy)`
          const firebaseForm = await this.getFormService().create(form)
          form.id = firebaseForm.id
          this.forms.list.push(form)
          await globalStore().updateStoreCache()
          return form
        }
      }
      return null
    },
    async updateForm(form: Form): Promise<Form | undefined> {
      if (this.forms.list.find(item => item.id === form?.id)) {
        await this.getFormService().update(form.id, form)
        const index = this.forms.list.findIndex(item => item.id === form.id)
        this.forms.list.splice(index, 1, form)
        await globalStore().updateStoreCache()
        return form
      }
    },
    async deleteForm(formId: string) {
      const form = this.forms.list.find(form => form.id === formId)
      if (form) {
        await this.getFormService().update(formId, { deleted: true })
        this.forms.list = this.forms.list.filter(form => form.id !== formId)
      }
    },
    editForm(form: Form) {
      this.edit.initialForm = cloneDeep(form)
      this.edit.form = cloneDeep(form)
      this.edit.fieldInput = null
      this.edit.fieldIndex = null
      this.edit.subFieldIndex = null
      this.edit.subFieldInput = null
      this.edit.subTableIndex = null
    },

    /**
     * Update the indexes of the field and subfield being edited
     */
    updateEditIndexes(indexes?: UpdateEditIndexesInput) {
      this.edit.fieldIndex = indexes?.primaryFieldIndex ?? null
      this.edit.subFieldIndex = indexes?.subFieldIndex ?? null
      this.edit.subTableIndex = indexes?.subTableIndex ?? null
    },
  },
})
