automation:
  add_step: 添加步骤
  add_trigger: 添加触发器
  create_new: 新的自动化
  error_launch: 自动化启动失败
  select_trigger_description: 选择一个触发器以开始配置您的自动化
  success_launch: 自动化启动已成功完成
  trigger_api: 收到 api 调用时
  trigger_graphquery: 执行 graphQL 查询时
  trigger_transformation: 编辑对象时
automations:
  automation_name_placeholder: 自动化名称
  create_automation: 创建新的自动化
  creation_automation_name: 自动化名称
  modal_new_automation_description: 自动化允许您创建自动化流程，以便创建、编辑数据并将其保存到 Bonx。
  run:
    datetime: 运行日期
    id: 我正在运行
  step:
    end_datetime: 步骤结束日期
    name: 步骤名称
    start_datetime: 步骤开始日期
  tab_detail: 自动化
  tab_history: 历史
blocks:
  qrCodeFullSize: 全屏模式下的二维码按钮
button:
  about: 关于
  back: 后退
  toggle_dark: 黑暗模式
  toggle_langs: 切换语言
dashboard:
  last_30_days: 过去 30 天
data_connection:
  add_step: 添加步骤
  auth: 认证方式
  auth_basic: 基本的
  data_connection_name_placeholder: 我的数据连接
  data_connection_step_type_placeholder: 数据连接阶梯式占位符
  data_connection_steps: 步骤细节
  document_url: 谷歌表共享网址
  input_data: 输入变量
  json_edition_label: 编辑 data_connection json
  name: 步骤名称
  password: 密码
  query: 询问
  save_file: 文件是否保存
  save_output: 输出变量
  step_json_edition_label: 编辑步骤 json
  tab: 选定的谷歌工作表标签
  url: API 网址
  username: 用户名
email:
  body: 邮件正文
  subject: 主题邮箱
filters:
  operators_doesnt_include: 不包含
  operators_equals_to: (==) 等于
  operators_greater_than: (>) 大于
  operators_greater_than_or_equal_to: (>=) 大于或等于
  operators_includes: 包含
  operators_is_empty: 是空的
  operators_is_not_empty: 不为空
  operators_less_than: (<) 小于
  operators_less_than_or_equal_to: (<=) 小于或等于
  operators_no_operator: 无条件
  operators_not_equal_to: (!=) 不等于
form.dollar_helper: |-
  以“$”符号开头的变量是表单内部的。
  然后必须将这些变量链接到下面的模型属性。
form_field_max_value: 最大值
forms:
  autofill_end: 提交表单时，Bonx 使用当前日期和时间自动填写此字段。
  autofill_information: 此字段由 Bonx 自动填写
  autofill_start: 打开表单时，Bonx 会使用当前日期和时间自动填写此字段。
  base_model: 基础模型
  create_form: 创建表格
  create_new: 新形式
  creation_form_name: 表格名称
  edit_no_field_warning: 该表格目前没有字段
  field_multiple_answers_allowed: 您可以在此处选择多个选项
  field_no_options: 目前还没有可用的选项。
  form_field_date: 日期
  form_field_display: 展示
  form_field_display_readquery_placeholder: 添加二次阅读查询（可选）
  form_field_document: 文档
  form_field_has_min_max_values: 有最小最大值
  form_field_image: 图片
  form_field_label_label: 字段名称
  form_field_min_value: 最小值
  form_field_multiple_choice: 多项选择
  form_field_name_label: 属性键
  form_field_number: 数字
  form_field_option: 选项
  form_field_option_required: 选项字段默认必填，如果表单可以返回空值，请选择“多项选择”字段。
  form_field_options_label: 选择列表
  form_field_options_placeholder: 添加选择
  form_field_read_query: 读取查询
  form_field_required_label: 强制的
  form_field_text: 文本域
  form_field_text_description_placeholder: 在此处添加描述或助手（可选）
  form_field_text_label_placeholder: 添加字段名称
  form_field_text_name_placeholder: 添加属性名称
  form_field_text_value_placeholder: 添加默认值（可选）
  form_field_textarea_label: 长文本
  form_field_write_query: 写查询
  form_fields: 表格字段
  form_readquery_placeholder: 添加主要阅读查询（可选）
  form_title_placeholder: 表格名称
  form_writequery_placeholder: 添加写入查询
  formreadquery_placeholder: 添加主要阅读查询（可选）
  modal_new_form_description: 表单创建器允许您生成由您的团队完成的表单，从而自动进行数据反馈。
  modal_share_form_description: 您可以使用唯一且可公开访问的链接通过电子邮件共享此表单。
  no_related_model_found: 没有找到模型
  share: 分享
  share_form_emails_label: 电子邮件地址
forms.action_delete_form: ''
forms.action_duplicate_form: 重复表格
forms.choose_attribute: 选择属性
forms.creation_import_fields: 导入模型属性
forms.creation_related_model: 相关型号
forms.creation_related_process: 相关流程
forms.creation_related_process_step: 相关工艺步骤
forms.deletion_confirmation_body: |-
  您确定要删除此表单吗？
  将来只有 Bonx 管理员才能恢复它。
forms.deletion_confirmation_title: 表单删除
forms.error_save_not_admin: 只有管​​理员可以保存编辑过的表单
forms.form_field_no_related_model_placeholder: 没有相关型号
forms.form_field_no_relationship_placeholder: 没有关系
forms.form_field_relation: 关系
forms.form_field_relationship_allow_existing_object_label: 允许链接现有对象
forms.form_field_relationship_allow_new_object_label: 允许创建新的链接项
forms.form_field_relationship_related_model_label: 选择相关型号
forms.form_field_relationship_value_label: 选择关系
forms.form_share: 公开分享表格
forms.labels: 标签
forms.new_form_advanced: 先进的
forms.new_form_classic: 经典的
forms.no_model: 此表单未链接到现有模型
forms.no_related_step: 未链接到流程步骤
forms.toast_deletion_success: 此表单已成功删除。
forms.toast_duplication_success: |-
  表格已成功复制。
  您将被重定向到这个新表单。
global:
  actions: 行动
  add: 添加
  automations: 自动化
  cancel: 取消
  click_button_reload: 请点击下方按钮重新加载！
  client: 公司
  client_name: 客户名称
  clients: 客户
  close: 关闭
  collaboration: 合作
  comment: 评论员
  compare: 比较
  create: 创造
  currency: 货币
  dashboard: 仪表盘
  data: 数据
  data_connections: 数据连接
  data_saved: 您的数据已保存
  days: 天
  delete: 删除
  description: 描述
  disabled: 已禁用
  disabled_users: 停用的用户
  display: 展示
  duplicate: 重复
  duration: 持续时间（秒）
  edit: 编辑
  email: 电子邮件
  entity: 实体
  error: 错误
  error_json: JSON 不正确
  error_login_invalid_password: 密码错误。
  error_login_invalid_user: 找不到用户，请仔细检查用户名。
  error_login_unknown: 未知的日志记录错误，请使用此屏幕右下角的圆形按钮联系 Bonx 团队。
  error_password_validation: 您的密码长度必须至少为 8 个字符，并且必须至少包含一个小写字母、一个大写字母和一个数字。
  failed: 失败的
  file_input:
    no_file: 您尚未提交任何文件
  filter: 筛选
  first_name: 名
  follow_up: 跟进
  forgot_password: 忘记密码
  forms: 形式
  from: 从
  groups: 团体
  home: 家
  id: Id
  is_required: 是必须的
  label: 标签
  labels: |-
    标签 |
    标签 |
    标签
  large: 大的
  last_name: 姓
  launch: 发射
  login: 登录
  logout: 登出
  medium: 中等的
  missing_write_request: 您没有提供写入请求
  models: |-
    型号 |
    型号 |
    楷模
  month:
    april_short: 四月
    august_short: 八月
    december_short: 十二月
    february_short: 二月
    january_short: 一月
    july_short: 七月
    june_short: 君
    march_short: 马尔
    may_short: 可能
    november_short: 十一月
    october_short: 十月
    september_short: 九月
  name: 姓名
  new: 新的
  new_version: 有新版本可用！
  not-found: 未找到
  objects: |-
    对象 |
    对象 |
    对象
  order: 命令
  overview: 概述
  parameters: 参数
  password: 密码
  phone: 电话
  photo: 照片
  photo_url: 网址照片
  process: 过程
  project: 项目
  projects: 项目
  reload: 重新加载
  remember_me: 记住账号
  reset_email_sent: 已发送一封用于重置密码的电子邮件。收到后请点击链接
  reset_password: 重设密码
  role: 角色
  rows: 行
  save: 节省
  saving: 保存...
  search: 搜索
  send: 发送
  send_reset_email: 发送重置电子邮件
  settings: 设置
  show: 节目
  small: 小的
  status: 状态
  success: 成功
  table:
    nav: 表格分页
  task: 任务 |任务 |任务
  total_report: 总报告
  training_and_help: 资源中心
  type: 类型
  update: 更新
  user: 用户
  users: 用户
  users_and_permissions: 用户和权限
  validate: 证实
  value: 价值
  views: |-
    查看 |
    查看 |
    意见
  week: 星期
  you_dont_have_access_to_this_page: 您无权访问此页面
global.attribute: 属性
global.close: 关闭
global.instance: 实例
global.model: 模型
global.toast_key_copy_success_content: 密钥已成功复制到剪贴板中。
global.toast_succes_copy_to_clipboard: 表格内容已成功复制到剪贴板
global.unsinchronized_store_header_content: |-
  以下元素已更新。
  因此，在刷新页面之前，您将不允许保存任何版本。
global.unsinchronized_store_header_content_pt1: 以下元素已更新
global.unsinchronized_store_header_content_pt2: 因此，您将不允许在刷新页面之前执行任何编辑
global.unsinchronized_store_header_title: Bonx 配置已被其他用户编辑
global.update: 更新
groups:
  group_name: 小组名称
  group_order: 组请求
  groups_list: 组列表
  toast_edit_success: 保存到组的更改已成功保存
home:
  greetings: 问候
modal_confirmation_popup_cancel: 否
modal_confirmation_popup_confirm: 是
object:
  attribute:
    attributes: |-
      属性 |
      属性 |
      属性
  attributes: 对象属性
  attributes_list: 属性列表
  error_attribute_with_same_name_exists: 现有属性已具有相同的名称
  error_loading: 加载此页面内容时出错
  error_no_primary_attribute: 没有为此模型定义主要属性
  related_objects: |-
    相关对象 |
    相关对象 |
    相关对象
  relation:
    attributes_list: 关系属性列表
  relationship:
    object: 链接对象
    to_object: 对对象
  relationship_list: 关系列表
  relationships: |-
    关系 |
    关系 |
    关系
  selected_setting_object: 对象类型
objects:
  add_attribute: 添加属性
  add_label: 创建标签
  add_object: 创建一个对象
  attributes_subtype_float: 浮点数
  attributes_subtype_integer: 整数
  attributes_subtype_link: 无格式文本
  attributes_subtype_plain_text: 无格式文本
  data_model: 数据模型
  data_models: 数据模型
  edit_attribute: 编辑属性
  edit_label: 编辑标签
  edit_object: 编辑对象
  label:
    placeholder: 请选择标签
  label_objects: 链接到此标签的对象
  label_relations: 链接到此标签的关系
  labels: 标签
  labels_list: 标签列表
  models: 楷模
  models_list: 型号列表
  objects_list: 对象列表
  overview: 概述
  relation:
    add_relation: 添加关系
    edit_relation: 编辑关系
  relations: 关系
otable:
  error:
    table_has_no_column: 一旦将键添加为列，该表就会可见，请联系贵公司的管理员。
pages:
  automation: 自动化
  data-connection: 数据连接
  object: 目的
process.finished: 完成的
process.in_progress: 进行中
process.waiting: 等待
processes:
  next_step: 下一步
  step_name: 步骤名称
profile:
  gantt_size_label: 甘特图显示
  home_page: 主页
  my_profile: 我的简历
  settings: 设置
timeline:
  external_user: 外部用户
toast:
  form_submit_content: 表单已成功提交
  print_success: 页面即将打开
  view_error_no_model_title: Bonx 很快将允许您编辑复杂的视图
  view_error_no_model_title_content: 此视图是手动构建的，请与您的 IT 部门或 Bonx 管理员联系。
toast.form_field_file_submit_content_error: 此表格需要提交文件。
user:
  add_label: 创建标签
  data_model:
    labels: 标签
    models: 楷模
    relations: 关系
  data_models: 数据模型
  edit_label: 编辑标签
  label:
    placeholder: 找个标签...
  models_list: 标签列表
view:
  action_move_up: 提升
  no_title: 无题
  toast_edit_success: 已成功编辑视图详细信息
views:
  action_move_down: 下移
  add_attribute: 新属性
  add_block: 新区块
  block_name: 块名称
  block_type: 类型du块
  create_new_view: 创建新视图
  create_new_view_description: 视图允许您以不同的形式可视化您感兴趣的数据并与您的团队共享。
  create_view: 创建新视图
  creation_automation_name_label: 自动化名称
  creation_form_name_label: 表格名称
  creation_view_model: 相关型号
  creation_view_name: 视图名称
  default_setup_element_name: 模型相关数据
  default_view_group_name: 默认组
  edit_attributes_options: 属性选项
  edit_attributes_options_add_colors: 颜色
  edit_attributes_options_add_icon: 图标
  edit_attributes_options_add_status: 地位
  edit_attributes_options_background_color: 背景颜色
  edit_attributes_options_border_color: 边框颜色
  edit_attributes_options_description: 选择属性的选项允许您更改其深度设置。
  edit_attributes_options_hide_json: 隐藏细节
  edit_attributes_options_icon: 图标
  edit_attributes_options_no_attributes: 这个方块还没有属性。
  edit_attributes_options_show_json: 显示详细资料
  edit_attributes_options_status_error: 状态：错误
  edit_attributes_options_status_success: 状态：成功
  edit_attributes_options_status_warning: 状态：警告
  edit_import_model: 导入模型
  edit_import_model_description: 导入模型允许您一键添加其所有属性作为块的轴。
  edition_deletion: 删除视图
  edition_no_columns: 一旦将属性添加到模型中，此复选框将可见，请联系您公司的管理员。
  edition_no_related_objects: 该模型与任何其他模型无关。
  edition_primary_table: 主要数据表
  edition_secondary_related_objects: 相关对象
  edition_selected_columns: 列
  error:
    query_invalid_or_missing: 提供的请求为空或导致错误。
  filter_label_placeholder: 过滤标签
  filter_value_placeholder: 价值
  filter_view: 过滤此视图
  filters_add_filter: 添加条件
  gantt:
    bar:
      no_label: 这个酒吧没有标签
    precision:
      day: 天
      display_by: 显示方式
      month: 月
  id: Id
  label: 标签
  modal_new_view_description: 视图允许您以您想要的方式可视化您的数据并与您的团队共享。
  query: GraphQL 请求
  toast_edit_success: 视图已成功编辑
  type: 类型
  view_block_name_placeholder: 我的视图块名称（可选）
  view_name_placeholder: 查看名称
  view_parameters_title: 查看参数
  views_list: 查看列表
views.deletion_confirmation_body: |-
  您确定要删除此视图吗？
  万一你犯了个错误并想恢复，所有的数据都将被保存。
views.deletion_confirmation_title: 查看删除
views.toast_deletion_success: 此视图已成功删除。
