import { signInWithEmailAndPassword } from "firebase/auth"
import { fb_auth } from "../../services/firebase"
import { loadStores } from "../stores"
import DbHelper from "../../helpers/dbHelper"

const dbHelper = new DbHelper()

const channel = new BroadcastChannel("authChannel")

export async function loadUser(): Promise<any> {
  const user: any = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ""
    await loadStores(userId)
  }
}

export async function getUserById(idUser: string) {
  const user = await dbHelper.getDocFromCollection("users", idUser)
  return user
}

// export const getOrg = async(userId: string) => {
//   const user = await dbHelper.getDocFromCollection(USER_COLLECTION_NAME, userId)
//   const clientId = user?.client_id
//   const client = await dbHelper.getDocFromCollection(CLIENT_COLLECTION_NAME, clientId)
//   const slug = client?.slug ?? 'default'
//   return slug
// }

export function getUserId() {
  const user = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ""
    return userId
  }
  return ""
}

export async function getUsers(client_id: string): Promise<Array<any>> {
  return await dbHelper.getAllDataFromCollectionWithWhere(
    "clients",
    "client_id",
    client_id,
  )
}

export async function signIn(email: string, password: string) {
  await signInWithEmailAndPassword(fb_auth, email, password)
  await loadUser()
  channel.postMessage("login")
}
export async function logout() {
  const currentUser = fb_auth.currentUser
  const userJson: any = localStorage.getItem("currentUser")
  if (currentUser && currentUser?.uid === JSON.parse(userJson)?.id)
    channel.postMessage("logout")

  await fb_auth.signOut()
  localStorage.removeItem("currentUser")
  localStorage.removeItem("actions_session_variables")
  localStorage.removeItem("view_blocks_element_per_page")
}
