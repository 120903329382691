import { CollectionService } from "./CollectionService.js";
import { ModelService } from "./ModelService.js";
/**
 * Service class for managing View Groups
 */
export class ViewGroupService extends CollectionService {
    modelService;
    constructor(dbHelper, clientId) {
        super(dbHelper, "view_groups", clientId);
        this.modelService = new ModelService(dbHelper, clientId);
    }
    /**
     * List all View Groups
     * @returns Array of view groups
     */
    async list(options = {}) {
        const viewGroups = await super.list(options);
        return viewGroups;
    }
    /**
     * Get a View Group by ID
     * @param viewGroupId ID of the view group to retrieve
     * @returns The requested view group
     */
    async get(viewGroupId) {
        const viewGroup = await super.get(viewGroupId);
        if (!viewGroup)
            return null;
        return viewGroup;
    }
    /**
     * Process the diff between a module document and a custom document
     * @param moduleDoc The module view group
     * @param input The custom view group
     * @returns The diff between the two documents
     */
    diff(moduleDoc, input) {
        // allow add view in view list
        const validInput = this.allowAddElementInArray(moduleDoc, input, "view_list");
        return validInput;
    }
    /**
     * Merge a module document with a custom document
     * @param moduleDoc The module document
     * @param customDoc The custom document
     * @returns The merged document
     */
    merge(moduleDoc, customDoc) {
        if (!moduleDoc)
            return customDoc;
        // merge added fields
        const mergedData = this.mergeElementInArray(moduleDoc, customDoc, "view_list");
        mergedData.template = moduleDoc.id;
        mergedData.module_view_list = moduleDoc.view_list;
        return mergedData;
    }
}
export default ViewGroupService;
