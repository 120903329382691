import type { UserModule } from "~/types"
import { fb_auth } from "~/services/firebase"

export const install: UserModule = ({ isClient, router }) => {
  if (isClient) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const query = Object.fromEntries(urlSearchParams.entries())
    router.beforeEach((to, from, next) => {
      if (to.matched.some((record) => {
        return record.meta.requiresAuth
      })) {
        fb_auth.onAuthStateChanged((user) => {
          if (user) {
            next()
          }
          else {
            next({
              path: "/auth/login",
              query,
            })
          }
        })
      }
      else {
        next()
      }
    })
  }
}
