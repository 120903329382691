import { chunk, uniq } from "lodash-es"
import type {
  QueryConstraint,
} from "firebase/firestore"
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore"
import { DBHelperInterface } from "@bonx/common"
import { db } from "../../services/firebase"

/* DbHelper v9 */
export default class DBHelper implements DBHelperInterface {
  db: Firestore
  constructor() {
    this.db = db
  }

  async batchAdd(collectionName: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0

    for (const document of data) {
      const docRef = doc(collection(db, collectionName)) //  doc(db, collection)
      batchArray[batchIndex].set(docRef, document)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(writeBatch(db))
        batchIndex++
        operationCounter = 0
      }
    }

    // eslint-disable-next-line ts/no-misused-promises
    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async batchUpdate(collection: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0
    for (const document of data) {
      const docRef = doc(db, collection, document.id)
      const docBactch = { ...document }
      delete docBactch.id
      batchArray[batchIndex].update(docRef, docBactch)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(writeBatch(db))
        batchIndex++
        operationCounter = 0
      }
    }
    // eslint-disable-next-line ts/no-misused-promises
    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async batchDelete(collection: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0
    for (const document of data) {
      const docRef = doc(db, collection, document.id)
      batchArray[batchIndex].delete(docRef)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(this.db.batch())
        batchIndex++
        operationCounter = 0
      }
    }

    // eslint-disable-next-line ts/no-misused-promises
    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async setDataToCollection(collectionName: string, id: string, data: any) {
    const docRef = collection(db, collectionName)
    return setDoc(doc(docRef, id), data)
  }

  async addDataToCollection(collectionName: string, data: any) {
    return addDoc(collection(db, collectionName), data)
  }

  async updateDataToCollection(collectionName: string, id: string, data: Record<string, unknown>) {
    try {
      const docRef = doc(db, collectionName, id)
      await updateDoc(docRef, data)
      return {}
    }
    catch (e) {
      const error = `updateDataToCollection - ${collectionName} - ${id} - ${String(e)}`
      console.error(error)
      return {
        error,
        data,
      }
    }
  }

  async updateFieldToCollection(collectionName: string, id: string, field: string, data: any) {
    const docRef = doc(db, collectionName, id)
    return updateDoc(docRef, field, data)
  }

  async deleteData(collectionName: string, id: string) {
    return deleteDoc(doc(db, collectionName, id))
  }

  async getAllDataFromCollectionBetweenDates(collectionName: string, whatIs: string, startDate: Date, endDate: Date) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName), where(whatIs, ">", startDate), where(whatIs, "<", endDate))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollectionFromIds(collectionName: string, equalTo: Array<any>) {
    const returnArray: Array<any> = []
    if (equalTo?.length) {
      const equalToUniq = uniq(equalTo)
      const equalToChunked = chunk(equalToUniq, 10)
      for (const tenBloc of equalToChunked) {
        const q = query(collection(db, collectionName), where("__name__", "in", tenBloc))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((docSnap: any) => {
          const resultQuery = docSnap.data()
          resultQuery.id = docSnap.id
          returnArray.push(resultQuery)
        })
      }
    }
    return returnArray
  }

  async getAllDataFromCollectionWithWhere(collectionName: string, whatIs: string, equalTo: any) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName), where(whatIs, "==", equalTo))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollectionWithWhereInArray(collectionName: string, whatIs: string, equalTo: any) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName), where(whatIs, "array-contains", equalTo))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollection(collectionName: string) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getDocFromCollection(collectionName: string, docId: string) {
    let result = null
    if (docId) {
      const docRef9 = doc(this.db, collectionName, docId)
      const docSnap = await getDoc(docRef9)

      if (docSnap.exists()) {
        const resultQuery: any = docSnap.data()
        resultQuery.id = docSnap.id
        result = resultQuery
      }
      else {
        // doc.data() will be undefined in this case
        console.error("getDocFromCollection,", collectionName, docId)
        // logger.logError("dbGetDoc", error)
        throw new Error(`Document ${docId} not found on collection ${collectionName}`)
      }
    }
    return result
  }

  async getDocFromCollectionOnSnapshot(collectionName: string, docId: string, callBack: any) {
    if (docId) {
      const snap = onSnapshot(doc(db, collectionName, docId), (doc: any) => {
        const resultQuery: any = doc.data()
        resultQuery.id = doc.id
        callBack(resultQuery)
        return resultQuery
      })
      return snap
    }
  }

  async getAllDataFromCollectionWithWhereArrayOnSnapshot(
    collectionName: string,
    arrayWhere: Array<any>,
    callBack: any,
  ) {
    const returnArray: Array<any> = []
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, "==", arrayWhere[prop]))
    }
    /*
     const q = query(collection(db, collectionName), ...whereArgsArray)
     const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((docSnap) => {
        const resultQuery = docSnap.data()
        resultQuery.id = docSnap.id
        returnArray.push(resultQuery)
      })
    })
    */
    callBack(returnArray)
    return returnArray
  }

  async getAllDataFromCollectionWithWhereIn(
    collectionName: string,
    whatIs: string,
    equalTo: Array<any>,
    arrayWhere: Array<any> = [],
  ) {
    const returnArray: Array<any> = []
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, "==", arrayWhere[prop]))
    }
    const equalToChunked = chunk(equalTo, 10)

    for (let i = 0; i < equalToChunked.length; i++) {
      const cloneWhereArgsArray = [...whereArgsArray]
      cloneWhereArgsArray.push(where(whatIs, "in", equalToChunked[i]))
      const q = query(collection(db, collectionName), ...cloneWhereArgsArray)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((docSnap: any) => {
        const resultQuery = docSnap.data()
        resultQuery.id = docSnap.id
        returnArray.push(resultQuery)
      })
    }
    return returnArray
  }

  async getAllDataFromCollectionWithAll(collectionName: string, constraints: any) {
    const returnArray: Array<any> = []
    const constraintsArgsArray = []

    const { where_constraints, orderBy_constraints, limit_constraint } = constraints || {}
    if (where_constraints) {
      where_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(where(cond.field, cond.compare, cond.value))
      })
    }
    if (orderBy_constraints) {
      orderBy_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(orderBy(cond.field, cond.direction || "asc"))
      })
    }
    if (limit_constraint)
      constraintsArgsArray.push(limit(limit_constraint))

    const q = query(collection(db, collectionName), ...constraintsArgsArray)
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollectionWithWhereArray<T>(collectionName: string, arrayWhere: Record<string, any>): Promise<Array<T>> {
    const returnArray: Array<any> = []
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, "==", arrayWhere[prop]))
    }
    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getDocFromCollectionWithWhere(collectionName: string, wheres: Record<string, any>) {
    const constraints: Array<QueryConstraint> = []
    for (const [key, value] of Object.entries(wheres))
      constraints.push(where(key, "==", value))

    const q = query(collection(db, collectionName), ...constraints)
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty)
      return querySnapshot.docs[0].data()

    return null
  }
}
