import { defineStore } from "pinia"
import { deserialize } from "~/helpers/qrcodes"

/**
 * Store for aggregating and making available data,
 * QR codes scanned by handheld scanners
 */
export const qrStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "qr",
  // a function that returns a fresh state
  state: () => ({
    latest_qr_string: "",
    latest_qr_item: {},
    qr_string_count: 0,
    qr_item_count: 0,
  }),

  // optional getters
  getters: {
    getLatestString(): any {
      return this.latest_qr_string
    },
    getLatestQRItem(): any {
      return this.latest_qr_item
    },
    getQRCount(): any {
      return this.qr_string_count
    },
  },
  // optional actions
  actions: {
    setCapturedQR(value: string) {
      const qr_item = deserialize(value)
      if (qr_item) {
        this.latest_qr_string = value
        this.qr_string_count += 1
        this.latest_qr_item = qr_item
        this.qr_item_count += 1
      }
    },
  },
})
