import { Function, List, Literal, Match, NamedNode, NamedParam, NamedVariable, Pattern, Relationship, Return, With, and, coalesce, collect, concat, contains, date, datetime, eq, gt, gte, id, in as inOp, isNotNull, isNull, lt, lte, not, or, plus, size, toString } from "@neo4j/cypher-builder";
import { isEqual } from "lodash-es";
import { parseFilterFormula, parseValueFormula } from "../formula/index.js";
import { AggregationType, AxisType, FilterComparator } from "../types/block.js";
import { ModelService } from "../services/ModelService.js";
import { RelationService } from "../services/RelationService.js";
import { CUSTOM_FUNCTIONS, comment, dateadd, datesub, exprIfValueNotEmptyOrNull, group, isEmpty } from "./CypherFunctions.js";
import { getLabelField, isRelationFieldSingleRelationForCurrentModel } from "./relationsHelper.js";
import { isFirestoreDocError } from "./dbHelper.js";
/**
 * Main model alias in the query
 */
export const MAIN_MODEL_ALIAS = "n";
/**
 * List of the CYPHER aggregation functions
 */
const AGGREGATION_FUNCTIONS = [
    "avg",
    "count",
    "collect",
    "max",
    "min",
    "percentileCount",
    "percentileDisc",
    "stdev",
    "sum",
    "collectUnique",
    "countUnique",
];
/**
 * Extract the id from a WithProjection
 * @param proj The WithProjection to extract the id from
 * @returns The id
 */
function _getId(proj) {
    let toFind = "";
    if (proj instanceof NamedVariable || proj instanceof NamedNode) {
        toFind = proj.id;
    }
    else if (Array.isArray(proj)) {
        if (proj[1] instanceof NamedVariable)
            toFind = proj[1].id;
        else if (typeof proj[1] === "string")
            toFind = proj[1];
    }
    return toFind;
}
function _getFilterValue(axisType, value) {
    if (Array.isArray(value))
        return new List(value.map(v => _getFilterValue(axisType, v)));
    const isNamedParam = typeof value === "string" && value.startsWith("$");
    switch (axisType) {
        case AxisType.Date:
            return date(isNamedParam ? new NamedParam(value.substring(1)) : new Literal(value));
        case AxisType.DateTime:
            return datetime(isNamedParam ? new NamedParam(value.substring(1)) : new Literal(value));
        case AxisType.Number:
            if (!isNamedParam) {
                value = Number.parseFloat(value);
                if (Number.isNaN(value))
                    throw new Error("Invalid number");
            }
        // fallthrough
        case AxisType.Text:
        // fallthrough
        case AxisType.Tags:
        // fallthrough
        case AxisType.Boolean:
        // fallthrough
        default:
            return isNamedParam ? new NamedParam(value.substring(1)) : new Literal(value);
    }
}
export class QueryHelper {
    dbHelper;
    debug;
    reportError;
    modelService;
    /**
     * The user Client Id who performs the query
     */
    clientId;
    /**
     * Root query witch will be returned as a string
     */
    rootQuery;
    /**
     * The main part of the query (the first MATCH clause)
     */
    mainQuery;
    parsedFilters;
    filterGroups = {};
    filterMatchs = {};
    filterMatchsByGroups = {};
    exprByAxis = {};
    /**
     * Cache for correspondance between model id and slug
     */
    cache = {
        modelsIdToSlug: {},
        mirrorRelations: [],
        modelRelations: {},
    };
    /**
     * Bonx's models added to the query
     */
    models = {};
    /**
     * Cypher nodes added to the query
     */
    nodes = {};
    /**
     * Cypher patterns added to the query
     */
    patterns = {};
    /**
     * Contains the columns to return in the RETURN clause
     */
    returnColumns = [];
    /**
     * Contains the columns to accumulate between the calculated fields
     */
    withAccumulator = [];
    /**
     * Contains the columns in current WITH clause for a calculated field
     */
    currentWithClause = null;
    /**
     * Contains the related models slugs present for the current WITH clause
     */
    currentAddedRelatedModels = [];
    /**
     * The dataset
     */
    dataset;
    /**
     * The dataset options
     */
    options;
    /**
     * Contains the current predicate group id
     */
    predicateGroupId = 0;
    /**
     * Constructor
     * @param dbHelper The Firestore database helper
     * @param clientId The user Client Id who performs the query
     * @param debug If true, the queryTree, variablesExpressions and the query will be logged in the console
     */
    constructor(dbHelper, clientId, debug = false, reportError = console.error) {
        this.dbHelper = dbHelper;
        this.clientId = clientId;
        this.debug = debug;
        this.reportError = reportError;
        this.modelService = new ModelService(dbHelper, clientId);
    }
    async datasetQuery(dataset, options) {
        this.dataset = dataset;
        this.options = options;
        // This is a classic query to a model
        if (dataset.type === "model") {
            // Get the main model from dataset
            const model = await this.getModelById(dataset.value);
            // Add main model to the query
            this.addModel(MAIN_MODEL_ALIAS, model);
            const mainNode = await this.getNodeBySlug(MAIN_MODEL_ALIAS);
            // We have filters, parse them
            await this.parseFilters(options.filters);
            // Create a MATCH query
            await this.createMatchQuery();
            // Return the main node (first aggregation)
            this.withAccumulator.push(mainNode);
            // Filters on the main model
            await this.createWhereClause("mainModel");
            // Common columns to RETURN
            // BNX-917 : if not aggregating
            if (!this.options.aggregation?.enabled) {
                this.return(mainNode);
                this.return(id(mainNode), "identity");
                this.return(new NamedVariable(`${MAIN_MODEL_ALIAS}.client_id`), "client_id");
            }
            // Iterate over the axis
            // Each axis is a formula
            for (const axis of options.axis ?? []) {
                // try to parse the formula
                let parsed;
                try {
                    parsed = parseValueFormula(axis.value, {});
                }
                catch (e) {
                    this.reportError(`error parsing formula ${axis.value} : ${String(e)}`);
                    continue;
                }
                this.concatCurrentWithClause();
                this.concat(comment(`Axis ${axis.id}`));
                // create the expression and add it to the WITH clause
                const expr = (await this.createExpression(parsed, MAIN_MODEL_ALIAS, MAIN_MODEL_ALIAS, `${MAIN_MODEL_ALIAS}.${axis.id}`, [MAIN_MODEL_ALIAS]));
                if (expr) {
                    const namedVar = new NamedVariable(axis.id);
                    this.exprByAxis[axis.id] = expr;
                    this.addToWithClause([expr, namedVar]);
                    this.addToWithAccumulator(namedVar);
                    // BNX-917 : Aggregate the axis if needed
                    let returnExpr = namedVar;
                    let returnAlias;
                    if (this.options.aggregation?.enabled) {
                        const agg = this.options.aggregation.axes[axis.id];
                        // Wrap the expression in an aggregation function if there not already one
                        // Skip Keys aggregation => this is just no aggregation
                        if (agg && agg !== AggregationType.Keys) {
                            returnAlias = namedVar;
                            if (Object.keys(CUSTOM_FUNCTIONS).includes(agg))
                                returnExpr = CUSTOM_FUNCTIONS[agg](namedVar);
                            else
                                returnExpr = new Function(agg, [namedVar]);
                        }
                    }
                    this.return(returnExpr, returnAlias);
                }
            }
            this.concatCurrentWithClause();
            // Filters on the related models
            await this.createWhereClause("relatedModels");
            // Ordering
            if (options.ordering && options.ordering.filter(o => o.key !== "").length > 0) {
                // if we are aggregating, we need to put the return columns in a WITH clause before ordering excluding the main model
                if (this.options.aggregation?.enabled) {
                    this.concat(new With(...this.returnColumns));
                    this.withAccumulator = this.withAccumulator.filter(p => _getId(p) !== MAIN_MODEL_ALIAS);
                }
                const withClause = new With(...this.withAccumulator);
                let index = 0;
                for (const { key, isFormula, direction } of options.ordering.filter(o => o.key !== "")) {
                    index++;
                    let expr;
                    if (isFormula) {
                        // try to parse the formula
                        let parsed;
                        try {
                            parsed = parseValueFormula(key, {});
                        }
                        catch (e) {
                            this.reportError(`error parsing formula ${key} : ${String(e)}`);
                            continue;
                        }
                        // create the expression
                        expr = (await this.createExpression(parsed, MAIN_MODEL_ALIAS, MAIN_MODEL_ALIAS, key, [MAIN_MODEL_ALIAS]));
                    }
                    else {
                        let variableName = "";
                        // check if key is in the axis
                        if (this.options.axis && this.options.axis.some(a => a.id === key)) {
                            variableName = key;
                        }
                        else {
                            // check if the filter value is an existing value in an axis
                            const axis = this.options.axis?.find(a => a.value === key);
                            if (axis) {
                                variableName = axis.id;
                            }
                            else {
                                if (this.dataset.type === "model")
                                    variableName = `${MAIN_MODEL_ALIAS}.${key}`;
                                else
                                    this.throwError("ordering on a non model dataset is not supported yet");
                            }
                        }
                        expr = new NamedVariable(variableName);
                    }
                    const namedVar = new NamedVariable(`ordering_${index}`);
                    withClause.addColumns([expr, namedVar]);
                    withClause.orderBy([namedVar, direction === "asc" ? "ASC" : "DESC"]);
                }
                this.concat(withClause);
                // if we are aggregating, the return values are in the previous WITH clause so we only need to return variable names
                if (this.options.aggregation?.enabled)
                    this.returnColumns = this.withAccumulator;
            }
            // Create the RETURN clause
            const returnClause = new Return(...this.returnColumns);
            // Adds SKIP and LIMIT clauses
            if (options.skip)
                returnClause.skip(options.skip);
            if (options.limit)
                returnClause.limit(options.limit);
            // Adds the RETURN clause
            this.concat(returnClause);
        }
        return this.renderQuery();
    }
    /**
     * Create a MATCH query
     * @param model Base model of the query (i.e. the first MATCH clause)
     * @param options Options for the query
     * @returns The generated CYPHER query
     */
    async matchQuery(model, options) {
        // Add main model to the query
        this.addModel(MAIN_MODEL_ALIAS, model);
        const mainNode = await this.getNodeBySlug(MAIN_MODEL_ALIAS);
        // Create a MATCH query
        await this.createMatchQuery();
        // Filter on the primary item id
        if (options.primaryItemId)
            await this.where({ identity: options.primaryItemId });
        // Return the main node (first aggregation)
        if (!options.excludeMainNodeProperties)
            this.return(mainNode);
        this.withAccumulator.push(mainNode);
        // Common columns to RETURN
        this.return(id(mainNode), "identity");
        this.return(new NamedVariable(`${MAIN_MODEL_ALIAS}.client_id`), "client_id");
        // Detect related models in axis
        const modelRelations = {};
        if (Array.isArray(options.axis) && options.axis.length > 0) {
            for (let i = 0; i < options.axis.length; i++) {
                // V0: retrocompatibility => this a related model slug => we need to get the related relation
                if (options.axis[i].includes("__")) {
                    options.relations = options.relations || [];
                    const [slug] = options.axis[i].split("__");
                    // Skip if this model was already added
                    if (modelRelations[slug])
                        continue;
                    const relatedModel = await this.getModelBySlug(slug);
                    const relations = await this.getModelRelations(relatedModel);
                    // Get the first matching relation, this could be the wrong one ?
                    const relation = relations.find(r => r.object_id === model.id || r.to_object_id === model.id);
                    if (relation) {
                        // Add the related model to the query
                        this.addModel(slug, relatedModel);
                        // Store the relation for later use
                        modelRelations[slug] = [relation, relation.object_id === model.id ? "right" : "left"];
                        // Check if the mirror column for this relation in the base model exists
                        const mirrorColumn = model.schema.find(s => s.$formkit === "relation" && s.misc?.relation_id === relation.id);
                        if (!mirrorColumn)
                            console.warn(`V0: retrocompatibility => Unable to find the mirror column in model ${model.name} for the axis ${options.axis[i]} with the relation ${relation.id}`);
                    }
                }
            }
        }
        // Adds the relations to the query
        if (Array.isArray(options.relations) && options.relations.length > 0) {
            for (const relation of options.relations) {
                // Get the related model id from the relation
                const relatedModelId = relation.object_id === model.id ? relation.to_object_id : relation.object_id;
                const relDirection = relation.object_id === model.id ? "right" : "left";
                // Get the related model
                const relatedModel = await this.getModelById(relatedModelId);
                const relatedNode = await this.getNodeBySlug(relatedModel.slug);
                // Adds the relation to the query
                await this.addRelatedNode(relation, MAIN_MODEL_ALIAS, relatedModel.slug, relDirection);
                // Add the related model to the query
                this.addModel(relatedModel.slug, relatedModel);
                this.return(relatedNode);
                this.withAccumulator.push(relatedNode);
            }
        }
        // Iterate over models
        for (const [slug, model] of Object.entries(this.models)) {
            // Iterate over model schema to build cypher query
            for (const s of model.schema) {
                // Exclude common columns, deleted columns and relations
                if (s.name === "client_id"
                    || s.name === "identity"
                    || s.deleted === true
                    || s.hidden === true
                    || (Array.isArray(options.axis) && ((slug === MAIN_MODEL_ALIAS && !options.axis.includes(s.name)) || (slug !== MAIN_MODEL_ALIAS && !options.axis.includes(`${slug}__${s.name}`)))))
                    continue;
                // Relations
                if (s.$formkit === "relation") {
                    // Important See note above about excludeReturnRelationFieldsIds
                    if (options.excludeReturnRelationFieldsIds)
                        continue;
                    const relationService = new RelationService(this.dbHelper, this.clientId);
                    const relation = await relationService.get(s.misc.relation_id);
                    if (!relation) {
                        this.reportError(`Relation with id ${relation} not found`);
                        continue;
                    }
                    // At the moment, we only support returning relation fields either 1_1 or 1_N (on the opposite side of 1)
                    // (In this context, a relation field means "1 or more neo4J IDs of the linked object")
                    // For instance, if you have a (Order)-[1_N]-(OrderLine) relation
                    // From the Order's point of view, you can have multiple OrderLine linked (and we won't return the relation field)
                    // From the OrderLine's point of view, it's linked to a single order, therefore we'll return the relation field
                    // (Which will translate in returning a single ID, the one of the order)
                    if (!isRelationFieldSingleRelationForCurrentModel(relation, this.models[MAIN_MODEL_ALIAS]))
                        continue;
                    s.misc.formulaV1 = `${s.name}.identity`;
                    // Load the label for the relation field
                    const otherModelId = relation.object_id === this.models[MAIN_MODEL_ALIAS].id ? relation.to_object_id : relation.object_id;
                    const otherModel = await this.getModelById(otherModelId);
                    const labelField = getLabelField(relation, this.models[MAIN_MODEL_ALIAS].id, otherModel);
                    // See BNX-2023, this line below caused a serious performance and data bug
                    const field = {
                        label: labelField,
                        name: `${s.name}_label`,
                        $formkit: "text",
                        misc: {
                            formulaV1: `${s.name}.${labelField}`,
                            formulaDisabled: false,
                        },
                    };
                    model.schema.push(field);
                }
                if (typeof s.misc?.formulaV1 === "string" && s.misc?.formulaV1 !== "") { // this is a formula
                    // this formula is disabled (maybe a problem ?) but
                    if (s.$formkit !== "relation" && s.misc?.formulaDisabled === true)
                        continue;
                    // try to parse the formula
                    let parsed;
                    try {
                        parsed = parseValueFormula(s.misc.formulaV1, {});
                    }
                    catch (e) {
                        this.reportError(`error parsing formula ${s.misc.formulaV1} : ${String(e)}`);
                        continue;
                    }
                    this.concatCurrentWithClause();
                    this.concat(comment(`Calculated field ${slug}.${s.name}`));
                    // create the expression and add it to the WITH clause
                    const expr = (await this.createExpression(parsed, MAIN_MODEL_ALIAS, slug, `${MAIN_MODEL_ALIAS}.${s.name}`, [MAIN_MODEL_ALIAS, slug]));
                    if (expr) {
                        const namedVar = new NamedVariable(`${slug}__${s.name}`);
                        this.addToWithClause([expr, namedVar]);
                        this.addToWithAccumulator(namedVar);
                        this.return(namedVar, options.return_prefixed_columns === false ? s.name : undefined);
                    }
                }
                else if (Array.isArray(options.axis)) {
                    // We ask for specific columns to return
                    // We return the column without prefix or with __ prefix if it's a related model
                    // As used in vO views
                    if (slug === MAIN_MODEL_ALIAS) {
                        this.return(new NamedVariable(`${slug}.${s.name}`), s.name);
                    }
                    else {
                        // Process attribute in related model as a formula
                        this.concatCurrentWithClause();
                        this.concat(comment(`Calculated field ${slug}__${s.name}`));
                        await this.addRelatedNode(modelRelations[slug][0], MAIN_MODEL_ALIAS, slug, modelRelations[slug][1]);
                        const namedVar = new NamedVariable(`${slug}__${s.name}`);
                        this.addToWithClause([collect(new NamedVariable(`${slug}.${s.name}`)), namedVar]);
                        this.addToWithAccumulator(namedVar);
                        this.return(namedVar, options.return_prefixed_columns === false ? s.name : undefined);
                    }
                }
            }
        }
        this.concatCurrentWithClause();
        // Filters
        options.wheres && await this.where(options.wheres);
        // Create the RETURN clause
        const returnClause = new Return(...this.returnColumns);
        // Adds ordering
        if (options.ordering)
            // @ts-expect-error typing is ok
            returnClause.orderBy(...options.ordering.map(o => [new NamedVariable(o.key), o.direction]));
        // Adds the RETURN clause
        this.concat(returnClause);
        return this.renderQuery();
    }
    /**
     * Defines the query as a MATCH query
     * @returns the MATCH query object
     */
    async createMatchQuery() {
        this.mainQuery = new Match(await this.getPattern(MAIN_MODEL_ALIAS));
        this.rootQuery = concat(this.mainQuery);
        return this.mainQuery;
    }
    async createWhereClause(wherePosition) {
        if (this.parsedFilters) {
            const [predicate, position, filterGroups] = this.parsedFilters;
            if (position === wherePosition) {
                if (wherePosition === "mainModel" && Object.values(this.filterGroups).length === 0) {
                    this.mainQuery.where(predicate);
                    return;
                }
                let withClause = null;
                const localWithAccumulator = [];
                for (const filterGroup of filterGroups) {
                    withClause = new With(...this.withAccumulator, ...localWithAccumulator);
                    if (this.filterMatchsByGroups[filterGroup]) {
                        for (const match of this.filterMatchsByGroups[filterGroup])
                            this.concat(match);
                    }
                    withClause.addColumns(this.filterGroups[filterGroup]);
                    // @ts-expect-error get namedVar
                    localWithAccumulator.push(this.filterGroups[filterGroup][1]);
                    this.concat(withClause);
                }
                if (withClause) {
                    withClause.where(predicate);
                    this.concat(withClause);
                }
            }
        }
        // BNX-1001: Better support for filtering
        if (Array.isArray(this.options.betterFilters) && this.options.betterFilters.length > 0) {
            // We are filtering on calculated axis, so we need to filter at the end of the query
            if (wherePosition === "relatedModels") {
                const withClause = new With(...this.withAccumulator);
                for (const filter of this.options.betterFilters) {
                    if (filter.axis === "")
                        continue;
                    if (filter.isFormula) {
                        try {
                            const parsed = parseFilterFormula(filter.value, {});
                            this.parsedFilters = await this.createPredicate(parsed);
                        }
                        catch (e) {
                            this.reportError(`error parsing formula ${filter.id} : ${String(e)}`);
                        }
                        // @TODO
                    }
                    else {
                        const axis = this.options.axis?.find(a => a.id === filter.axis);
                        if (!axis) {
                            console.warn(`Axis ${filter.axis} not found`);
                            continue;
                        }
                        let predicate;
                        let right;
                        let left = new NamedVariable(filter.axis);
                        if ((filter.comparator === FilterComparator.IsEmpty || filter.comparator === FilterComparator.IsNotEmpty) && (axis.type === AxisType.Date || axis.type === AxisType.DateTime || axis.type === AxisType.Number)) {
                            left = toString(left);
                        }
                        else if ((axis.type === AxisType.Date || axis.type === AxisType.DateTime)) {
                            // Force date and datetime types on left operande
                            left = exprIfValueNotEmptyOrNull(left, axis.type === AxisType.Date ? date(left) : datetime(left));
                        }
                        switch (filter.comparator) {
                            case FilterComparator.IsEmpty:
                                if (axis.type === AxisType.Select)
                                    predicate = isNull(left);
                                else
                                    predicate = or(isNull(left), isEmpty(left));
                                break;
                            case FilterComparator.IsNotEmpty:
                                if (axis.type === AxisType.Select)
                                    predicate = isNotNull(left);
                                else
                                    predicate = and(isNotNull(left), not(isEmpty(left)));
                                break;
                            case FilterComparator.Contains:
                            case FilterComparator.NotContains:
                                try {
                                    right = _getFilterValue(AxisType.Text, filter.value); // Force text type to search in text
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                predicate = contains(toString(left), right);
                                if (filter.comparator === FilterComparator.NotContains)
                                    predicate = not(predicate);
                                break;
                            case FilterComparator.IsIn:
                            case FilterComparator.IsNotIn:
                                if (!Array.isArray(filter.value) || filter.value.length === 0)
                                    continue;
                                try {
                                    right = _getFilterValue(axis.type, filter.value);
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                // BNX-1175 : search a list in a collect (a list) => need to do an intersection to check if the list contains at least one of the values
                                // @ts-expect-error i know it's private but i need it
                                if (this.exprByAxis[axis.id] && this.exprByAxis[axis.id].name === "collect") {
                                    predicate = size(new Function("apoc.coll.intersection", [left, right]));
                                    if (filter.comparator === FilterComparator.IsNotIn)
                                        predicate = eq(predicate, new Literal(0));
                                    else
                                        predicate = gt(predicate, new Literal(0));
                                }
                                else {
                                    predicate = inOp(left, right);
                                    if (filter.comparator === FilterComparator.IsNotIn)
                                        predicate = not(predicate);
                                }
                                break;
                            case FilterComparator.IsRelativeToToday: {
                                const relative = filter.value;
                                const now = axis.type === AxisType.Date ? date() : datetime();
                                let start;
                                let end;
                                const value = new Literal(Number.parseInt(relative.value));
                                const unit = new Literal(relative.unit);
                                switch (relative.indicator) {
                                    case "past":
                                        start = gte(left, datesub(now, value, unit));
                                        end = lte(left, now);
                                        break;
                                    case "this": {
                                        let truncated;
                                        switch (relative.unit) {
                                            case "months":
                                                truncated = new Function(`${axis.type === AxisType.Date ? "date" : "datetime"}.truncate`, [new Literal("month"), now]);
                                                start = gte(left, truncated);
                                                end = lt(left, dateadd(truncated, new Literal(1), new Literal("months")));
                                                break;
                                            case "years":
                                                truncated = new Function(`${axis.type === AxisType.Date ? "date" : "datetime"}.truncate`, [new Literal("year"), now]);
                                                start = gte(left, truncated);
                                                end = lt(left, dateadd(truncated, new Literal(1), new Literal("years")));
                                                break;
                                            case "hours":
                                                truncated = new Function(`${axis.type === AxisType.Date ? "date" : "datetime"}.truncate`, [new Literal("hour"), now]);
                                                start = gte(left, truncated);
                                                end = lt(left, dateadd(truncated, new Literal(1), new Literal("hours")));
                                                break;
                                            case "weeks":
                                                truncated = new Function(`${axis.type === AxisType.Date ? "date" : "datetime"}.truncate`, [new Literal("week"), now]);
                                                start = gte(left, truncated);
                                                end = lt(left, dateadd(truncated, new Literal(1), new Literal("weeks")));
                                                break;
                                            case "days":
                                            default:
                                                truncated = new Function(`${axis.type === AxisType.Date ? "date" : "datetime"}.truncate`, [new Literal("day"), now]);
                                                start = gte(left, truncated);
                                                end = lt(left, dateadd(truncated, new Literal(1), new Literal("days")));
                                                break;
                                        }
                                        break;
                                    }
                                    case "next":
                                        start = lte(left, dateadd(now, value, unit));
                                        end = gte(left, now);
                                        break;
                                }
                                predicate = and(start, end);
                                break;
                            }
                            case FilterComparator.GreaterThan:
                                try {
                                    right = _getFilterValue(axis.type, filter.value);
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                predicate = gt(left, right);
                                break;
                            case FilterComparator.GreaterThanOrEqual:
                                try {
                                    right = _getFilterValue(axis.type, filter.value);
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                predicate = gte(left, right);
                                break;
                            case FilterComparator.LessThan:
                                try {
                                    right = _getFilterValue(axis.type, filter.value);
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                predicate = lt(left, right);
                                break;
                            case FilterComparator.LessThanOrEqual:
                                try {
                                    right = _getFilterValue(axis.type, filter.value);
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                predicate = lte(left, right);
                                break;
                            case FilterComparator.Equal:
                            case FilterComparator.NotEqual:
                            default:
                                try {
                                    right = _getFilterValue(axis.type, filter.value);
                                }
                                catch (e) {
                                    this.reportError(`error on filter formula ${filter.id} : ${String(e)}`);
                                    continue;
                                }
                                if (axis.type === AxisType.Boolean) {
                                    if (isEqual(filter.value, false)) {
                                        if (filter.comparator === FilterComparator.NotEqual)
                                            predicate = eq(left, new Literal(true));
                                        else
                                            predicate = or(not(eq(left, new Literal(true))), isNull(left));
                                    }
                                    else {
                                        if (filter.comparator === FilterComparator.Equal)
                                            predicate = eq(left, new Literal(true));
                                        else
                                            predicate = or(not(eq(left, new Literal(true))), isNull(left));
                                    }
                                }
                                else {
                                    predicate = eq(left, right);
                                    if (filter.comparator === FilterComparator.NotEqual)
                                        predicate = not(predicate);
                                }
                                break;
                        }
                        withClause.where(predicate);
                    }
                    this.concat(withClause);
                }
            }
        }
    }
    /**
     * Adds an OPTIONAL MATCH clause to the query with the given relation, models and direction
     * @param relation The relation to use
     * @param fromModelSlug The model to start from
     * @param toModelSlug The model to go to
     * @param relDirection The direction of the relation
     */
    async addRelatedNode(relation, fromModelSlug, toModelSlug, relDirection, filterPosition) {
        !filterPosition && this.concatCurrentWithClause();
        // Check if the relation has already been added
        if (!filterPosition && this.currentAddedRelatedModels.includes(`${fromModelSlug}::${toModelSlug}`))
            return this;
        this.currentAddedRelatedModels.push(`${fromModelSlug}::${toModelSlug}`);
        // Get the related node
        const relatedNode = await this.getNodeBySlug(toModelSlug);
        // Get the pattern for the base model
        const basePattern = await this.getPattern(fromModelSlug);
        // Create the pattern for the related model with the relation if it exists
        const pattern = basePattern
            .related(new Relationship({ type: relation.name }))
            .withDirection(relDirection)
            .to(relatedNode);
        // add OPTIONAL MATCH to the query
        const match = new Match(pattern).optional();
        if (filterPosition) {
            if (!Array.isArray(this.filterMatchs[filterPosition]))
                this.filterMatchs[filterPosition] = [];
            this.filterMatchs[filterPosition].push(match);
        }
        else {
            this.concat(match);
        }
        return this;
    }
    /**
     * Gets a Cypher Pattern by its related model slug
     * @param slug slug of the model
     * @returns The node object
     */
    async getPattern(slug) {
        if (this.patterns[slug])
            return this.patterns[slug];
        const node = await this.getNodeBySlug(slug);
        const pattern = new Pattern(node).withProperties({
            client_id: new NamedParam("client_id"),
        });
        this.patterns[slug] = pattern;
        return this.patterns[slug];
    }
    /**
     * Adds an expression to the RETURN clause
     * @param expr The Cypher expression to add to the RETURN clause
     * @param alias The optional alias for the expression
     */
    return(expr, alias) {
        if (alias) {
            // @ts-expect-error I know this is a private property but I need it
            if (!this.returnColumns.find(c => c[1] === alias))
                this.returnColumns.push([expr, alias]);
            return this;
        }
        // @ts-expect-error I know this is a private property but I need it
        if (!this.returnColumns.find(c => c.id === expr.id))
            this.returnColumns.push(expr);
        return this;
    }
    concat(clause) {
        // @ts-expect-error I know this is a private property but I need it
        if (clause.parent !== this.rootQuery)
            this.rootQuery.concat(clause);
        return this;
    }
    /**
     * Adds the WHERE clauses to the query
     * @param wheres A record of filters
     * @returns this for chaining
     */
    async where(wheres) {
        if (wheres && Object.keys(wheres).length > 0) {
            for (const [key, value] of Object.entries(wheres)) {
                if (key === "identity") {
                    const node = await this.getNodeBySlug(MAIN_MODEL_ALIAS);
                    this.mainQuery.where(eq(id(node), new Function("toInteger", [new NamedParam("identity")])));
                }
                else {
                    const nameParts = key.split(".");
                    let name = "";
                    if (nameParts.length > 1) {
                        // attribute in related model
                        name = key;
                    }
                    else {
                        name = `${MAIN_MODEL_ALIAS}.${key}`;
                    }
                    this.mainQuery.where(eq(new NamedVariable(name), new Literal(value)));
                }
            }
        }
        return this;
    }
    /**
     * Adds model to the query builder
     * @param slug The model slug in Firestore
     * @param model The model object
     * @returns this for chaining
     */
    addModel(slug, model) {
        this.models[slug] = model;
        this.nodes[slug] = new NamedNode(slug, {
            labels: [model.name],
        });
        return this;
    }
    /**
     * Gets a model by its slug
     * @param slug slug of the model
     * @returns The model object
     */
    async getModelBySlug(slug) {
        if (this.models[slug])
            return this.models[slug];
        const setting_object = (await this.modelService.list({ filters: { slug } }))[0];
        if (!setting_object || isFirestoreDocError(setting_object))
            this.throwError(`Model not found : ${slug}`);
        this.models[slug] = setting_object;
        this.cache.modelsIdToSlug[setting_object.id] = slug;
        return this.models[slug];
    }
    /**
     * Gets a model by its Firestore ID
     * @param id Firestore ID of the model
     * @returns The model object
     */
    async getModelById(id) {
        if (this.cache.modelsIdToSlug[id])
            return this.models[this.cache.modelsIdToSlug[id]];
        const model = await this.modelService.get(id);
        if (!model || isFirestoreDocError(model))
            this.throwError(`Model not found : ${id}`);
        this.models[model.slug] = model;
        this.cache.modelsIdToSlug[id] = model.slug;
        return this.models[model.slug];
    }
    /**
     * Gets a Cypher NamedNode by its related model slug
     * @param slug slug of the model
     * @returns The node object
     */
    async getNodeBySlug(slug) {
        if (this.nodes[slug])
            return this.nodes[slug];
        const setting_object = await this.getModelBySlug(slug);
        const relatedNode = new NamedNode(setting_object.slug, {
            labels: [setting_object.name],
        });
        this.nodes[slug] = relatedNode;
        return this.nodes[slug];
    }
    /**
     * Retrieves the relation from the mirror column in a model
     * @param baseModelSlug The slug of the model containing the mirror column
     * @param relationMirrorColumnName The name of the mirror column
     * @returns An array with the found relation and the direction of the relation
     */
    async getRelationFromMirrorColumn(baseModelSlug, relationMirrorColumnName) {
        const cached = this.cache.mirrorRelations.find(r => r[0] === baseModelSlug && r[1] === relationMirrorColumnName);
        if (cached)
            return [cached[2], cached[3]];
        // Get base model
        const baseModel = await this.getModelBySlug(baseModelSlug);
        // BNX-1295 : better support for relations
        // If the mirror column name starts with __relation_ this is a direct relation identifier
        let relationId = "";
        if (relationMirrorColumnName.startsWith("__relation_")) {
            relationId = relationMirrorColumnName.replace("__relation_", "");
        }
        else {
            // Search mirror column in the schema
            const relationMirrorColumn = baseModel.schema.find(c => c.name === relationMirrorColumnName && c.deleted !== true);
            if (!relationMirrorColumn)
                this.throwError(`Mirror column ${relationMirrorColumnName} not found in ${baseModelSlug}`);
            relationId = relationMirrorColumn.misc.relation_id;
        }
        // Get relation
        const relationService = new RelationService(this.dbHelper, this.clientId);
        const relation = await relationService.get(relationId);
        if (!relation || isFirestoreDocError(relation))
            this.throwError(`No relation found for ${baseModelSlug} > ${relationMirrorColumnName}`);
        const direction = relation.object_id === baseModel.id ? "right" : "left";
        this.cache.mirrorRelations.push([baseModelSlug, relationMirrorColumnName, relation, direction]);
        return [relation, direction];
    }
    async getModelRelations(model) {
        if (this.cache.modelRelations[model.id])
            return this.cache.modelRelations[model.id];
        const relationService = new RelationService(this.dbHelper, this.clientId);
        const relations = await relationService.list({ modelId: model.id });
        this.cache.modelRelations[model.id] = relations;
        return relations;
    }
    /**
     * Adds a With projection to the accumulator
     * @param proj The projection to add
     */
    addToWithAccumulator(proj) {
        if (this.withAccumulator.some(p => _getId(p) === _getId(proj)))
            return;
        this.withAccumulator.push(proj);
    }
    /**
     * Adds a With projection to the current WITH clause
     * @param proj The projection to add
     */
    addToWithClause(proj) {
        if (this.withAccumulator.some(p => _getId(p) === _getId(proj)))
            return;
        if (this.currentWithClause)
            this.currentWithClause.addColumns(proj);
        else
            this.currentWithClause = new With(...this.withAccumulator, proj);
    }
    /**
     * Concatenates the current with clause to the query
     */
    concatCurrentWithClause() {
        if (this.currentWithClause)
            this.concat(this.currentWithClause);
        this.currentWithClause = null;
        if (this.options?.keepAddedRelatedModels !== true)
            this.currentAddedRelatedModels = [];
    }
    /**
     * Returns a field definition from a model schema
     * @param slug The model slug
     * @param field The field name
     * @returns The field definition in the model schema, or undefined if not found
     */
    async getModelSchemaField(slug, field) {
        const model = await this.getModelBySlug(slug);
        return model.schema.find(s => s.name === field);
    }
    /**
     * Increments the global predicate group ID
     */
    getPredicateGroupId() {
        return this.predicateGroupId++;
    }
    async parseFilters(filters) {
        if (!filters)
            return;
        try {
            const parsed = parseFilterFormula(filters, {});
            this.parsedFilters = await this.createPredicate(parsed);
        }
        catch (e) {
            this.reportError(`error on filters formula ${filters} : ${String(e)}`);
        }
    }
    async createPredicate(part, position = "mainModel", filterGroups = []) {
        let predicate;
        if (part.type === "variable") {
            // check if variable is in the axis
            const axis = this.options?.axis?.find(a => a.id === part.name);
            if (axis) {
                position = "relatedModels";
                predicate = new NamedVariable(part.name);
            }
            else {
                if (this.dataset.type === "model")
                    predicate = await this.createExpression(part, MAIN_MODEL_ALIAS, MAIN_MODEL_ALIAS, part.name, [MAIN_MODEL_ALIAS], undefined, position);
                else
                    this.throwError("ordering on a non model dataset is not supported yet");
            }
        }
        else if (part.type === "comparison") {
            const [left, leftPosition] = await this.createPredicate(part.left, position, filterGroups);
            const [right, rightPosition] = await this.createPredicate(part.right, position, filterGroups);
            if (leftPosition === "relatedModels" || rightPosition === "relatedModels")
                position = "relatedModels";
            predicate = eq(left, right);
            predicate.operator = part.comparator;
            if (predicate.operator === "NOT IN") {
                predicate.operator = "IN";
                predicate = not(predicate);
            }
        }
        else if (part.type === "group") {
            const partName = `where_group${this.getPredicateGroupId()}`;
            const namedVariable = new NamedVariable(partName);
            const exprs = [];
            for (const e of part.exprs) {
                const [expr, pos] = await this.createPredicate(e, position, filterGroups);
                exprs.push(expr);
                if (pos === "relatedModels")
                    position = "relatedModels";
            }
            const expr = group(...exprs);
            this.filterGroups[partName] = [expr, namedVariable];
            filterGroups.push(partName);
            // Store a reference to the filter group in the cache
            if (!this.filterMatchsByGroups[partName])
                this.filterMatchsByGroups[partName] = [];
            if (this.filterMatchs[position]) {
                this.filterMatchsByGroups[partName].push(...this.filterMatchs[position]);
                this.filterMatchs[position] = [];
            }
            predicate = namedVariable;
        }
        else if (part.type === "param") {
            predicate = new NamedParam(part.name);
        }
        else {
            predicate = await this.createExpression(part, MAIN_MODEL_ALIAS, MAIN_MODEL_ALIAS, part.name, [MAIN_MODEL_ALIAS], undefined, position);
        }
        return [predicate, position, filterGroups];
    }
    /**
     * Create Cypher objects from a formula
     * @param part The part of the formula to be converted
     * @param baseModelSlug The base model slug (used for related models)
     * @returns The Cypher object or an array of Cypher objects
     */
    async createExpression(part, baseModelSlug, modelSlug, modelVariableName, path = [], parentExprName, filterPosition) {
        let expr;
        // Functions
        if (part.type === "function") {
            const exprs = [];
            for (const e of part.exprs) {
                const expr = await this.createExpression(e, baseModelSlug, modelSlug, modelVariableName, path, part.name, filterPosition);
                if (Array.isArray(expr))
                    exprs.push(...expr);
                else
                    exprs.push(expr);
            }
            // this is a custom function
            if (Object.keys(CUSTOM_FUNCTIONS).includes(part.name)) {
                // this is an aggregation function and one of the child expressions is also an aggregation function, skip this parent function.
                // @ts-expect-error haha
                if (AGGREGATION_FUNCTIONS.includes(part.name) && exprs.some(expr => expr.name && AGGREGATION_FUNCTIONS.includes(expr.name)))
                    expr = group(...exprs);
                else
                    expr = CUSTOM_FUNCTIONS[part.name](...exprs);
            }
            else {
                // this is an aggregation function and one of the child expressions is also an aggregation function, skip this parent function.
                // @ts-expect-error haha
                if (AGGREGATION_FUNCTIONS.includes(part.name) && exprs.some(expr => expr.name && AGGREGATION_FUNCTIONS.includes(expr.name)))
                    expr = group(...exprs);
                else
                    expr = new Function(part.name, exprs);
            }
        }
        else if (part.type === "variable") { // Variables
            const nameParts = part.name.split(".");
            let name = "";
            let field;
            // Check if the attribute is in a related model
            if (nameParts.length > 1) {
                // Adds mirror columns to the path
                let lastRelatedModel;
                let lastRelatedModelSlug = modelSlug;
                for (const part of nameParts.slice(0, -1)) {
                    const [relation, relDirection] = await this.getRelationFromMirrorColumn(lastRelatedModelSlug, part);
                    lastRelatedModel = await this.getModelById(relDirection === "right" ? relation.to_object_id : relation.object_id);
                    path.push(part);
                    await this.addRelatedNode(relation, lastRelatedModelSlug, lastRelatedModel.slug, relDirection, filterPosition);
                    lastRelatedModelSlug = lastRelatedModel.slug;
                }
                const attribute = nameParts[nameParts.length - 1];
                if (lastRelatedModel) {
                    field = await this.getModelSchemaField(lastRelatedModel.slug, attribute);
                    name = `${lastRelatedModel.slug}.${attribute}`;
                    baseModelSlug = modelSlug;
                    modelSlug = lastRelatedModel.slug;
                }
                if (attribute === "identity")
                    return id(await this.getNodeBySlug(modelSlug));
            }
            else {
                if (part.name === "identity")
                    return id(await this.getNodeBySlug(modelSlug));
                field = await this.getModelSchemaField(modelSlug, part.name);
                name = `${modelSlug}.${part.name}`;
            }
            const type = field?.$custom ?? field?.$formkit ?? "text";
            // Check if the attribute is a formula (computed field of computed field)
            if (typeof field?.misc?.formulaV1 === "string"
                && field?.misc?.formulaV1 !== ""
                && field?.misc?.formulaDisabled !== true) {
                try {
                    const parsed = parseValueFormula(field.misc.formulaV1, {});
                    const parsedExpr = await this.createExpression(parsed, baseModelSlug, modelSlug, `${modelVariableName}.${name}`, path, part.name, filterPosition);
                    // Wraps the calculation in parenthesis (eg. (n.total * n.coef / 100 + 10) * 1.2) if not an aggregation function
                    // @ts-expect-error haha
                    if (AGGREGATION_FUNCTIONS.includes(parsedExpr.name))
                        expr = parsedExpr;
                    else if (Array.isArray(parsedExpr))
                        expr = group(...parsedExpr);
                    else
                        expr = group(parsedExpr);
                }
                catch (e) {
                    this.reportError(`error parsing formula ${field.misc.formulaV1} : ${String(e)}`);
                }
            }
            else if (type === "text") {
                // Make sure this is a string
                if (parentExprName === "coalesce")
                    return new NamedVariable(name);
                expr = coalesce(new NamedVariable(name), new Literal(""));
            }
            else if (type === "number") {
                if (parentExprName === "coalesce")
                    return new NamedVariable(name);
                // Make sure this is a number
                expr = coalesce(new NamedVariable(name), new Literal(0));
            }
            else if (type === "relation") {
                // Handle relation fields
                const [relation, relDirection] = await this.getRelationFromMirrorColumn(modelSlug, nameParts[nameParts.length - 1]);
                const relatedModel = await this.getModelById(relDirection === "right" ? relation.to_object_id : relation.object_id);
                await this.addRelatedNode(relation, modelSlug, relatedModel.slug, relDirection, filterPosition);
                expr = new NamedVariable(`${relatedModel.slug}`);
            }
            else {
                expr = new NamedVariable(name);
            }
        }
        else if (part.type === "operation") { // Operations
            expr = plus((await this.createExpression(part.left, baseModelSlug, modelSlug, modelVariableName, path, part.operator, filterPosition)), (await this.createExpression(part.right, baseModelSlug, modelSlug, modelVariableName, path, part.operator, filterPosition)));
            expr.operator = part.operator;
        }
        else if (part.type === "group") { // Groups (parenthesis)
            const exprs = [];
            for (const e of part.exprs)
                exprs.push((await this.createExpression(e, baseModelSlug, modelSlug, modelVariableName, path, parentExprName, filterPosition)));
            expr = group(...exprs);
        }
        else if (part.type === "literal") { // Literals
            expr = new Literal(part.value);
        }
        else if (part.type === "params") { // Function parameters
            const exprs = [];
            for (const e of part.exprs)
                exprs.push((await this.createExpression(e, baseModelSlug, modelSlug, modelVariableName, path, parentExprName, filterPosition)));
            return exprs;
        }
        else if (part.type === "null") { // Null value
            expr = new Literal(null);
        }
        else if (part.type === "param") {
            expr = new NamedParam(part.name);
        }
        return expr;
    }
    /**
     * Renders the query to string
     * @returns The generated Cypher query
     */
    renderQuery() {
        try {
            const { cypher } = this.rootQuery.build();
            if (this.debug)
                void import("node:fs").then(fs => fs.writeFileSync(`query_${Date.now().toString()}.txt`, cypher.replaceAll("{ client_id: $client_id }", "")));
            return cypher;
        }
        catch (e) {
            this.reportError(`error rendering query: ${String(e)}`);
        }
        return "";
    }
    /**
     * Throws an error with the given message, optionally reporting it to the error handler
     * @param error The error message
     * @throws An error with the given message
     */
    throwError(error) {
        this.reportError(`${error} | clientId: ${this.clientId}`);
        throw new Error(error);
    }
}
