export function isRelationFieldSingleRelationForCurrentModel(relation, model) {
    if (!relation.type)
        throw new Error(`Relation ${relation.name} has no type`);
    if (relation.type === "1_1")
        return true;
    // This is a 1-N relation
    // Ex: 1 order has N order lines
    // Therefore, you can only display
    else if (relation.type === "1_N")
        return relation.to_object_id === model.id;
    // This is a N-1 relation
    else if (relation.type === "N_1")
        return relation.object_id === model.id;
    // This is a N-N
    else if (relation.type === "N_N")
        return false;
    throw new Error(`Unknown relation type ${relation.type}`);
}
export function getLabelField(relation, currentModelId, otherModel) {
    const primaryModelFieldName = otherModel.schema[0].name;
    if (relation.object_id === currentModelId) {
        if (relation.to_object_label_attribute && relation.to_object_label_attribute !== "")
            return relation.to_object_label_attribute;
    }
    else {
        if (relation.object_label_attribute && relation.object_label_attribute !== "")
            return relation.object_label_attribute;
    }
    return primaryModelFieldName;
}
