import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getFirebaseConfig } from "~/config"

export const fb = initializeApp(getFirebaseConfig())

// // Initialize Firebase
export const db = getFirestore(fb)

// export const fbperf = getPerformance(fb)
export const fb_storage = getStorage(fb)
export const fb_auth = getAuth(fb)

// export const fb_analytics = getAnalytics()
