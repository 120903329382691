import { isEmpty, isEqual, isNull, isNumber, isUndefined, memoize, round, sortBy } from "lodash-es"
import type { Field, ModelSchema, NodeProperties, Relation } from "@bonx/common"
import type { ComposerTranslation } from "vue-i18n"
import { serializeDataTypes } from "../utils/datatypes"
import type { Toast } from "../types/toast"

export type ModelFieldEnriched = Field & {
  relation_definition?: Relation
}

export function oAssign(from: any, value: any) {
  for (const a in from) {
    if (value[a])
      from[a] = value[a]
  }
}

export function dateFormat(timestamp: number, locale: string) {
  return new Date(timestamp * 1000).toLocaleDateString(locale)
}

export function dateTimeFormat(timestamp: number, locale: string) {
  return new Date(timestamp * 1000).toLocaleString(locale)
}

export function getNthDate(nthDays: any, fromDate = new Date()) {
  fromDate.setUTCHours(0, 0, 0)
  fromDate.setTime(fromDate.getTime() + nthDays * 86400000)
  return fromDate
}

export function dateGetMonthYear(timestamp: number, locale: string) {
  return (
    `${new Date(timestamp * 1000).toLocaleDateString(locale, { month: "long" })
    } ${new Date(timestamp * 1000).getFullYear()}`
  )
}

export function nbDaysOverdue(timestampDue: number, timestampEnd: number) {
  if (timestampDue === 0) {
    return 0
  }
  else if (timestampEnd === 0) {
    const nowTimestamp = Date.now() / 1000

    if (timestampDue > nowTimestamp)
      return 0
    else
      return daysRange(timestampDue, nowTimestamp)
  }
  else {
    return daysRange(timestampDue, timestampEnd)
  }
}

export function daysRange(timestampStart: number, timestampEnd: number) {
  // Is it same day ?
  const dateStart = new Date(timestampStart * 1000)
  const dateEnd = new Date(timestampEnd * 1000)
  const dateStartString = `${dateStart.getDate()}/${dateStart.getMonth()}/${dateStart.getFullYear()}`
  const dateEndString = `${dateEnd.getDate()}/${dateEnd.getMonth()}/${dateEnd.getFullYear()}`
  const isSameDay = dateStartString === dateEndString

  if (isSameDay)
    return 0
  else
    return round((timestampEnd - timestampStart) / (60 * 60 * 24))
}

export function formatBytes(bytes: number, decimals: number) {
  if (bytes === undefined)
    return ""
  if (bytes === 0)
    return "0 Bytes"
  const k = 1024
  const dm = decimals || 2
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function getInitialForFullName(fullName: string) {
  return fullName
    ? fullName
      .split(" ")
      .map(name => name.substring(0, 1))
      .join("")
    : ""
}

const KEY = "messages"

export const clearMessages = () => localStorage.removeItem(KEY)

export async function clearCacheStorage() {
  if (window.caches)
    await window.caches.keys().then(keys => Promise.all(keys.map(key => window.caches.delete(key))))
}

export function forceHomeReload() {
  const { protocol, hostname, port } = window.location
  const url = [protocol, "//", hostname]
  if (port)
    url.push(...[":", port])

  const ts = new Date().getTime()

  const newUrl = `${url.join("")}?ts=${ts}`
  void fetch(url.join(""), { cache: "reload", headers: { "Clear-Site-Data": "storage" } }).finally(() => {
    window.location.href = newUrl
  })
}

export async function hotReload() {
  await clearCacheStorage()
  clearMessages()
  forceHomeReload()
}

export const versionToNumber = memoize((version?: string) =>
  Number.parseInt((version ?? "0.0.0").replace(/\./g, "")),
)

function isFalsy(value: any) {
  return isUndefined(value) || isNull(value) || value === ""
}

/**
 * Result of the comparison between two objects
 */
interface CheckChangesResult {
  /**
   * A boolean indicating if the original and changed object are different
   */
  changed: boolean
  /**
   * The delta object containing the changes between the original and changed object
   */
  delta: Partial<NodeProperties<unknown>>
}

/**
 * Performs a deep comparison between to value objects, against a pre-defined schema
 * to determine if one object is different from the other
 * @param originalObject the original object data
 * @param changedObject  the possibly changed object data
 * @param schema the schema to compare against
 * @returns an object with the result of the comparison
 */
export function checkChanges(originalObject: NodeProperties<unknown>, changedObject: NodeProperties<unknown>, schema: Array<ModelFieldEnriched>): CheckChangesResult {
  let changed = false

  const delta: CheckChangesResult["delta"] = {}

  for (const field of schema) {
    const key = field.name
    const ftype = field.$formkit

    const modified = changedObject[key]
    const origin = originalObject[key]

    if (isFalsy(origin) && isFalsy(modified)) {
      // Neither field exists in the original or changed object
      continue
    }

    // Check when the value.type == file (array cas) after adding the delete option for files
    if (ftype === "tag" || ftype === "multitag" || ftype === "file") {
      if (!isEqual(sortBy(modified as Array<string>), sortBy(origin as Array<string>))) {
        changed = true
        delta[key] = modified
        continue
      }
    }
    else if (ftype === "number" || ftype === "text") {
      if (modified !== origin) {
        delta[key] = modified
        changed = true
        continue
      }
    }
    else if (ftype === "relation") {
      // For the moment, we don't do any check on the relation direction
      // Because this is best handled by the ObjectDetails component (that will exclude the relation field from the page details
      const modifiedValue = Array.isArray(modified) ? modified[0] : modified
      if (!isEqual(modifiedValue, origin)) {
        changed = true
        delta[key] = modified
        continue
      }
    }
    else {
      if (!isEqual(modified, origin)) {
        changed = true
        delta[key] = modified
        continue
      }
    }
  }

  return { changed, delta }
}

/**
 *
 * @param schema model's Schema
 * @param properties properties of the object
 * @returns the missing required fields
 */
export function getMissingRequiredFields(schema: ModelSchema, properties: NodeProperties<unknown>) {
  const data = serializeDataTypes(properties, schema)
  const filteredSchema = schema.filter((field: Field) => field.validation === "required" && (!field.misc?.formulaV1 || field.misc?.formulaV1 === ""))
  const missingFields: Array<string> = []
  for (const field of filteredSchema) {
    const value = data[field.name]
    switch (field.$formkit) {
      case "relation":
      case "number":
        !isNumber(value) && missingFields.push(field.label)
        break
      default :
        (!value || isEmpty(value)) && missingFields.push(field.label)
    }
  }
  return missingFields
}

/**
 *
 * @param schema model's Schema
 * @param properties properties of the object
 * @param toast represente the Toast object { show: false, isSuccess: false, title: "", content: "" }
 * @param t Locale message translation function. We cannot declare the locale message translation function here because it is already declared in the parent component and needs to be declared at the top of the <script setup> section.
 * @returns true if it exist missing required fields and display the toast else false
 */
export function validateRequiredFields(schema: ModelSchema, properties: NodeProperties<unknown>, toast: Toast, t: ComposerTranslation) {
  const missingFields = getMissingRequiredFields(schema, properties)
  if (missingFields.length > 0) {
    toast.title = t("global.missing_field", { n: missingFields.length })
    toast.content = t("global.are_required", { n: missingFields.length, fields: missingFields.join(", ") })
    toast.isSuccess = false
    toast.show = true
    return false
  }
  return true
}

export async function asyncFilter<U>(arr: Array<any>, predicate: (value: any, index: number, array: Array<any>) => U): Promise<Array<U>> {
  const results = await Promise.all(arr.map(predicate))
  return arr.filter((_v, index) => results[index])
}
