// import {IS_PROD, IS_DEMO, IS_STAGING} from "@/_helpers/config/index";

export default class AnalyticsHelper {
  constructor() {}
  async logAction(event_name: string/* , payload: any = {} */) {
    if (event_name) {
      if (typeof window !== "undefined") {
        // const userData: any = userStore().user
        /* const w: any = window
        const href = w?.location?.href
        const env
          = (href.includes("demo") && "DEMO")
          || (href.includes("staging") && "STAGING")
          || (href.includes("app") && "PROD")
          || "LOCAL"
         const params = {
          user: userData?.id ? userData?.id : "anonymous",
          user_name: `${userData?.last_name} ${userData?.first_name}`,
          client_name: userData?.client_name,
          entity_name: userData?.entity_name,
          env,
          version: VERSION,
        }

        // if (payload) {
        //   params = { ...params, ...payload };
        // }
        const u = w?.umami
        if (u)
          u(event_name) */
      }
      // posthog.capture(payload?.event_name, params);
    }
  }
}
