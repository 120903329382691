automation:
  action: Action
  add_step: Ajouter une étape
  add_trigger: Ajouter un déclencheur
  create_new: Nouvelle automatisation
  disabled_automations: Automatisations désactivées
  disable_automation: Désactiver l'automatisation
  enabled_automations: Automatisations activées
  enable_automation: Activer l'automatisation
  disable_automation_step: Désactiver l'étape d'automatisation
  enable_automation_step: Activer l'étape d'automatisation
  error_launch: Le lancement de l'automatisation a échoué
  is_disabled: L'automatisation est désactivée
  select_trigger_description: Sélectionnez un déclencheur pour commencer à paramétrer votre automatisation
  success_launch: Le lancement de l'automatisation s'est terminé avec succès
  trigger: Déclencheur
  trigger_api: Lorsqu'une requête api est reçu
  trigger_graphquery: Lorsqu'une requête graphQL est exécutée
  trigger_transformation: Quand un objet est modifié
automations:
  automation_name_placeholder: Nom de l'automatisation
  create_automation: Créer une nouvelle automatisation
  creation_automation_name: Nom de l'automatisation
  modal_new_automation_description: >-
    Les automatisation vous permettent de créer des process automatiques pour
    créer, modifier et enregistrer des données dans Bonx.
  run:
    datetime: Date du run
    id: Id du run
  step:
    end_datetime: Date de fin d'étape
    name: Nom de l'étape
    start_datetime: Date de début de l'étape
  tab_credentials: Identifiants
  tab_detail: Automatisation
  tab_history: Historique
blocks:
  action_button_primary: Primaire
  action_button_secondary: Secondaire
  action_button_visible: Visible
  action_buttons: Boutons d'action
  action_condition:
    eq: Égale à
    gt: Supérieur à
    gte: Supérieur ou égale à
    includes: Contient
    key: Clé
    lt: Inférieur à
    lte: Inférieur ou égale à
    neq: Différent de
    not_includes: Ne contient pas
    value: Valeur
  action_goto:
    url: Adresse URL
  action_open_dialog:
    content: Contenu
    icon: Icône
    node_id: ID Objet
    show_object_card: Afficher la carte d'un objet
    title: Titre
  action_open_drawer:
    mapping: Propriétés prédéfinies
    model_id: ID du modèle
    nodeid: ID de l'objet
    type: Action
    type_create: Créer un objet
    type_edit: Modifier un objet
  action_open_form:
    query: Paramètres de la requête
  action_update_object:
    model_id: Model ID
    nodeid: ID de l'objet
    object: Objet
  actions:
    goto: Aller à
    hide: Cacher
    label: Scénarios d'actions
    open_dialog: Ouvrir une boite de dialogue
    open_drawer: Ouvrir le détail de l'objet
    open_form: Ouvrir un formulaire
    refresh_bloc: Rafraichir les données
    show: Afficher
    target: Élément déclencheur
    trigger: Déclencheur
    update_object: Modifier l'objet
    delete_object: Supprimer l'object
    callback: Fonction de rappel
  axis:
    aggregation: Agrégation
    aggregation_avg: Moyenne
    aggregation_collect: Liste de valeurs
    aggregation_collectUnique: Liste de valeurs uniques
    aggregation_count: Compter
    aggregation_countUnique: Compter uniques
    aggregation_keys: Clés
    aggregation_max: Maximum
    aggregation_median: Médiane
    aggregation_min: Minimum
    aggregation_sum: Somme
    aggregation_truncateDay: Tronquer au jour
    aggregation_truncateHour: Tronquer à l'heure
    aggregation_truncateMonth: Tronquer au mois
    aggregation_truncateWeek: Tronquer à la semaine
    aggregation_truncateYear: Tronquer à l'année
    autofill: Tous les champs
    config_error_message: Certains champs sont vides. Veuillez les remplir avant d'enregistrer
    default_value: Valeur par défaut
    file_display_image: Afficher comme image
    formatting: Mise en forme conditionnelle
    formatting_comparator_color_from_tags: Couleur depuis les tags
    formatting_comparator_label: Comparateur
    formatting_target_background: Arrière-plan
    formatting_target_content: Texte
    formatting_target_label: Propriété cible
    id: ID
    label: Colonnes
    progression_bar_max: Max
    progression_bar_min: Min
    select_custom: Valeurs personnalisées
    select_dataset: Dataset
    tags_custom: Valeurs personnalisées
    tags_dataset: Attribut du dataset
    tags_options: Options
    tags_source: Source des options
    type_boolean: Booléen
    type_date: Date
    type_datetime: Date et Heure
    type_duration: Durée
    type_file: Fichier
    type_number: Nombre
    type_select: Select
    type_tags: Tags
    type_text: Texte
    value: Valeur
  calendar_axis_end: Date de fin
  calendar_axis_start: Date de début
  calendar_axis_status: Statut de l'événement
  calendar_axis_title: Titre de l'événement
  calendar_event_default_duration: Durée par défaut (heures)
  calendar_hidden_days: Jours cachés
  calendar_slot_max_time: Heure de fin des jours
  calendar_slot_min_time: Heure de début des jours
  chart:
    type: Type de graphique
    type_bar: Barres empilées
    type_doughnut: Camembert
    type_hist: Histogramme
    type_line: Graphique en ligne
  className: Style
  columns: Nb colonnes
  comparators:
    type_empty: est vide
    type_eq: égale à
    type_gt: plus grand que
    type_gte: plus grand ou égale à
    type_include: inclus
    type_ineq: inégale à
    type_lt: plus petit que
    type_lte: "plus petit ou égale à "
    type_not_empty: n'est pas vide
    type_not_include: n'inclus pas
    type_notnull: n'est pas NULL
    type_null: est NULL
  dataset:
    block: Bloc
    custom: Requête
    label: Dataset
    model: Modèle
    type: Type
  edit_mode:
    activate: Activer le mode édition
    desactivate: Désactiver le mode édition
  elementId: ID de l'élément
  elementsPerPage: Éléments par page
  empty_block_description: Vous n'avez pas encore finalisé la configuration de ce bloc.
  empty_layout_description: Ajouter un nouveau block
  filter_comparators:
    contains: ':'
    equal: '='
    greaterthan: '>'
    greaterthanorequal: '>='
    isempty: vide
    isin: dans
    isnotempty: pas vide
    isnotin: pas dans
    isrelativetotoday: relatif à aujourd'hui
    lessthan: '<'
    lessthanorequal: '<='
    notcontains: '!:'
    notequal: '!='
  filters: Filtres
  filters_column: Colonne
  formula: Formule
  invalid_filter: Filtre invalide, vérifiez les valeurs saisies
  limit: Limiter à N lignes
  metrics_name: Nom de la métrique
  new_block: Mon nouveau bloc
  ordering: Tri
  ordering_asc: ASC
  ordering_desc: DESC
  playbooks:
    scannedQrCode: Code QR scanné
    actionButtonClick: Click sur le bouton d'action
    rowClick: Au clique sur la ligne
    cellClick: Au clique sur la cellule
    selectedRows: Lignes sélectionnées
    dateClick: Au clique sur la date
    rowChanged: Ligne modifié
  qrCodeFullSize: Bouton QR Code en mode plein écran
  quit_confirmation_body: |-
    Certaines modifications n'ont pas été sauvegardées.
    Voulez-vous vraiment quitter cette page ?
  rest_filters: Plus de filtres | 1 filtre | {n} filtres
  searchable: Activer la recherche
  select_disable: Sélection désactivée car pas d'id sur la ligne
  selectable: Activer la sélection de ligne
  selected_rows: Aucun objet selectionné | {count} objet sélectionné | {count} objets sélectionnés
  selection: Sélection
  show_title: Afficher le titre
  skip: Passer N lignes
  sortable: Activer le tri
  steps: Étapes
  types:
    calendar: Calendrier
    cardlist: Liste de cartes
    chart: Graphique
    dataset: Données brutes
    gallery: Galerie
    gantt: Gantt
    inputs: Saisie
    layout: Mise en forme
    metrics: Métriques
    table: Tableau
button:
  about: À propos de
  back: Retour
  copy_data_to_clipboard: Copier les données
  download_data: Télécharger les données
  permission_denied: Vous n'avez pas le droit pour activer ce boutton.
  toggle_dark: Mode sombre
  toggle_langs: Changer de langue
dashboard:
  last_30_days: Les 30 derniers jours
data_connection:
  action: Action
  add_step: Ajouter une étape
  api_rest: Appel vers API
  auth: Méthode d'authentification
  auth_basic: de base
  credential_json_edition_label: Modifier le JSON du credential
  csv: Générer un CSV
  csv_sub_key_input_data: Clé de donnée
  data_changed: Un champ a été modifié
  data_connection_name_placeholder: Ma connexion de données
  data_connection_step_type_placeholder: Espace réservé pour le type d'étape de connexion de données
  data_connection_steps: Détail de l'étape
  document_url: URL partagée Google Sheet
  email: Envoyer un email
  form_sent: Un formulaire a été envoyé
  google_sheet: Importer / Exporter Google Sheet
  template: Action
  graph_query: Exécution de code
  input_data: Variable d'entrée
  json_edition_label: Modifier
  manual_launch: Lancement manuel
  model_choice: Choix du modèle
  name: Nom de l'étape
  password: Mot de passe
  query: Requête
  save_file: Enregistrer le fichier
  save_output: Variable de sortie
  step_json_edition_label: "Modifier le json de l'étape "
  stop: Arrêter la séquence selon condition
  tab: Onglet séléctionné
  url: URL de l'API
  username: Nom d'utilisateur
  webhook: WebHook
  webhook_url: URL Webhook
  read_query: Requête de lecture
  path: Chemin vers la transformation
  type: Type de l'étape
day: Jour
days:
  friday: Vendredi
  monday: Lundi
  saturday: Samedi
  sunday: Dimanche
  thursday: Jeudi
  tuesday: Mardi
  wednesday: Mercredi
email:
  body: Corps de l'e-mail
  from_name: Nom de l'expéditeur
  subject: Objet de l'e-mail
error:
  could_not_export_pdf_template: Impossible d'exporter le modèle PDF
  could_not_share_url: Impossible de générer l'url de partage de formulaire
filters:
  date_indicator_next: Prochains
  date_indicator_past: Derniers
  date_indicator_this: Ce / Cette
  date_relative_unit_days: Jour(s)
  date_relative_unit_hours: Heure(s)
  date_relative_unit_months: Mois
  date_relative_unit_weeks: Semaine(s)
  date_relative_unit_years: Année(s)
  filter: Filtre
  formula: Mode formule
  lock: Verrouiller
  new_filter: Nouveau filtre
  operators_doesnt_include: Ne contient pas
  operators_equals_to: (==) Egal à
  operators_greater_than: (>) Supérieur à
  operators_greater_than_or_equal_to: (>=) Supérieur ou égal à
  operators_includes: Contient
  operators_is_empty: Est vide
  operators_is_not_empty: N'est pas vide
  operators_is_relative_to_today: Par rapport à aujourd'hui
  operators_less_than: (<) Inférieur à
  operators_less_than_or_equal_to: (<=) Inférieur ou égal à
  operators_no_operator: Aucune condition
  operators_not_equal_to: (!=) Différent de
  reset: Réinitialiser
  save_for_all: Enregistrer pour tous
  simple: Mode filtre simple
  unlock: Déverrouiller
form:
  confirm_before_sending: Merci de vérifier vos choix avant de confirmer l'envoi du formulaire.
  sent_recap: Votre réponse a bien été prise en compte, merci.
form.dollar_helper: |-
  Les variables précédées du signe "$" sont internes au formulaire.
  Ces variables doivent ensuite être liées aux attributs du modèle.
form_field_max_value: Valeur max
forms:
  action_delete_form: Supprimer le formulaire
  action_duplicate_form: Dupliquer le formulaire
  add_field_table: Ajouter un champ ou un tableau
  already_used_share_token: Ce formulaire a déjà été soumis
  autofill: Remplissage automatique
  autofill_end: >-
    Ce champ est automatiquement rempli par Bonx lorsque le formulaire est
    envoyé, avec la date et l'heure en cours.
  autofill_form_ended_datetime: Date et heure de fin du formulaire
  autofill_form_started_datetime: Date et heure de début du formulaire
  autofill_information: Ce champ est rempli automatiquement par Bonx
  autofill_scan: Valeur du qrcode scanné
  autofill_scan_datetime: Date et heure du scan
  autofill_start: >-
    Ce champ est automatiquement rempli par Bonx lorsque le formulaire est
    ouvert, avec la date et l'heure en cours.
  base_model: Donnée de base
  create_form: Créer un nouveau formulaire
  create_new: Nouveau formulaire
  creation_form_name: Nom du formulaire
  creation_related_process_end_step: Etape suivante
  creation_related_process_start_step: Etape de départ
  deletion_confirmation_body: "Voulez-vous vraiment supprimer ce formulaire\_? \nToutes ses données seront définitivement supprimées de nos serveurs. \nSeul un administrateur de Bonx sera en mesure de la restaurer dans le futur."
  deletion_confirmation_title: Suppression de formulaire
  edit_no_field_warning: Ce formulaire ne possède actuellement aucun champ
  edition_mode: Mode édition
  error_save_not_admin: Seul un administrateur peut enregistrer un formulaire modifié
  field_multiple_answers_allowed: Vous pouvez sélectionner plusieurs options
  field_no_options: Il n'y a pas encore d'options disponibles.
  field_number: Champ numéro
  field_settings: Paramètres du champ
  fields_tables: Champs
  file:
    compact: Compact
    viewer: Viewer
  form_field_aql_aql: "AQL :"
  form_field_aql_elo: "ELO :"
  form_field_aql_number_of_defects: Nombre de non-conformes
  form_field_aql_number_of_elements_to_control: Nombre de pièces à contrôler
  form_field_aql_number_of_max_non_quality: Max acceptable de non-conformes
  form_field_aql_number_of_parts_per_lot: Nombre de pièces à prélever par carton
  form_field_aql_number_of_parts_per_lot_last: Nombre de pièces à prélever (dernier carton)
  form_field_aql_number_of_sampled_lots: Nombre de cartons / contenants à prélever
  form_field_aql_tolerance_percent: Tolerance
  form_field_autocomplete: Saisie automatique
  form_field_configuration: Paramétrage
  form_field_date: Date
  form_field_date_picker_type: Type de date
  form_field_date_picker_type_date: Date
  form_field_date_picker_type_datetime: Date et heure
  form_field_display: Affichage
  form_field_display_file: Afficher comme un fichier
  form_field_display_image: Afficher comme une image
  form_field_display_readquery_placeholder: Ajouter une requête de lecture secondaire (facultatif)
  form_field_display_type: Type
  form_field_display_type_default: Par défaut
  form_field_display_type_file: Fichier
  form_field_display_type_image: Image
  form_field_document: Document
  form_field_file_no_assets: Pas de fichiers à afficher
  form_field_formula_attributes_label: Attributs
  form_field_formula_context: Contexte de la vue
  form_field_formula_fixed_label: Valeur
  form_field_formula_formula_label: Formule
  form_field_formula_functions_avg: Moyenne
  form_field_formula_functions_ceil: ArrondiSupérieur
  form_field_formula_functions_coalesce: Coalesce
  form_field_formula_functions_collect: Collecte
  form_field_formula_functions_collectUnique: CollecteUnique
  form_field_formula_functions_count: Nb
  form_field_formula_functions_countUnique: NbUnique
  form_field_formula_functions_date: Date
  form_field_formula_functions_dateadd: AvanceDate
  form_field_formula_functions_datesub: ReculeDate
  form_field_formula_functions_durationInDays: DuréeEnJours
  form_field_formula_functions_durationInSeconds: DuréeEnSecondes
  form_field_formula_functions_durationbetween: DuréeEntre
  form_field_formula_functions_float: EnDecimal
  form_field_formula_functions_floor: ArrondiInférieur
  form_field_formula_functions_formatDate: formatDate
  form_field_formula_functions_head: Premier
  form_field_formula_functions_id: ID
  form_field_formula_functions_if: Si
  form_field_formula_functions_label: Fonctions
  form_field_formula_functions_last: Dernier
  form_field_formula_functions_max: Max
  form_field_formula_functions_min: Min
  form_field_formula_functions_now: Maintenant
  form_field_formula_functions_right: Droite
  form_field_formula_functions_round: Arrondi
  form_field_formula_functions_sqrt: RacineCarré
  form_field_formula_functions_sum: Somme
  form_field_formula_functions_tags: Tags
  form_field_formula_functions_ifEmpty: SiVide
  form_field_formula_identity: Identité
  form_field_formula_invalid_label: Formule invalide
  form_field_formula_valid_label: Formule valide
  form_field_has_min_max_values: A des valeurs min max
  form_field_image: Image
  form_field_items_allow_creation: Autoriser la création d'éléments
  form_field_items_allow_deletion: Autoriser la suppression d'éléments
  form_field_items_columns: Colonnes
  form_field_items_subtables: Sous-tableaux
  form_field_items_new_col: Nouvelle colonne
  form_field_label_label: Nom du champ
  form_field_min_value: Valeur minimale
  form_field_model_attribute: Attribut
  form_field_multiple_choice: Choix multiples
  form_field_name_label: Clé de l'attribut
  form_field_number: Numéro
  form_field_option: Option
  form_field_option_required: >-
    Un champ d'option est requis par défaut, veuillez sélectionner le champ
    "Choix multiples" si une valeur vide peut être retournée par le formulaire.
  form_field_options_label: Liste des choix
  form_field_options_placeholder: Ajouter un choix
  form_field_printable_form: Impression
  form_field_read_query: Requête de lecture
  form_field_relation_allow_view: Autoriser la visualisation d'éléments
  form_field_relation_displayed_field: Champ du libellé
  form_field_required_label: Obligatoire
  form_field_template: Template
  form_field_text: Champ texte
  form_field_text_description_placeholder: Ajoutez une description ou une aide ici (facultatif)
  form_field_text_label_placeholder: Ajouter un nom de champ
  form_field_text_name_placeholder: Ajouter la clef de l'attribut
  form_field_text_value_placeholder: Ajouter une valeur par défaut (facultatif)
  form_field_textarea_label: Texte long
  form_field_write_query: Requête d'écriture
  form_fields: Champs du formulaire
  form_id: ID Formulaire
  form_readquery_placeholder: Ajouter une requête de lecture (facultatif)
  form_select_attribute: Choisir un attribut
  form_select_object: Sélectionner un objet
  form_share: Formulaire partagé publiquement
  form_title: Vous voyez maintenant à quoi ressemblerait ce formulaire sur l'objet
  form_title_placeholder: Nom du formulaire
  form_writequery_placeholder: Ajouter une requête d'écriture
  formreadquery_placeholder: Ajouter une requête de lecture (facultatif)
  full_screen: Plein écran (toute la largeur)
  hide_zero: Cacher les zéros
  initial_value: Valeur initiale
  label_template: Modèle d'étiquette
  labels: Étiquettes
  labels_mapping: Mapping
  labels_max_chars_per_row: Nb max caract. par ligne
  labels_name: Nom
  labels_per_page: Étiquettes par page
  labels_type: Type
  legacy: Héritage
  loading_error_message: Erreur survenue lors du chargement du formulaire
  modal_labels_description: Vous pouvez gérer l'impression d'étiquettes ici
  modal_new_form_description: >-
    Le créateur de formulaire vous permet de générer des formulaires à remplir
    par vos équipes et permet donc d’automatiser la remontée de données.
  modal_share_form_description: >-
    Vous pouvez partager ce formulaire par email avec un lien unique et
    accessible publiquement.
  no_related_model_found: Aucun modèle trouvé
  please_select_model_attribute: Veuillez sélectionner un attribut du modèle
  postflight_summary: Afficher un récapitulatif après envoi
  prefill: Pré-remplissage
  preflight_summary: Demander confirmation avant envoi
  printer: Imprimante
  printer_not_supported: Votre imprimante n'est pas détectée
  qcmct_title: Contrôles qualité
  quantity: Quantité
  read_only: Lecture seule
  rendering: Génération du document...
  selected_test_object: Objet de test sélectionné
  serial:
    connect: Connecter
    measure: Mesurer
    reconnect: Reconnecter
    uid: UID
    unit: Unité
    value: Valeur
    serial_not connected: >-
      La connection à l'appareil n'est pas active. Pourriez-vous l'activer une
      première fois en cliquant sur "Connecter" ci-dessus ?
    serial_not_supported: >-
      Votre navigateur ne supporte pas la connexion à ce type d'équipement (via un
      port série). Merci de rouvrir cette page sur Google Chrome
  share: Partager
  shared: Partagé
  sharing_url: URL de partage
  state_recovered: Les données du formulaire ont été récupérées d'une précédente session
  toast_deletion_success: Ce formulaire a été supprimé avec succès.
  toast_duplication_success: |-
    Le formulaire a été dupliqué avec succès.
    Vous êtes redirigé vers ce nouveau formulaire.
  update_success: Ce formulaire a été modifié avec succès
forms.choose_attribute: Nouvel attribut
forms.creation_import_fields: Importer les attributs du modèle
forms.creation_related_model: Modèle associé
forms.creation_related_process: Processus connexe
forms.creation_related_process_step: Étape du processus liée
forms.form_field_no_related_model_placeholder: Aucun modèle associé
forms.form_field_no_relationship_placeholder: Pas de relation
forms.form_field_relation: Relation
forms.form_field_relationship_allow_existing_object_label: Autoriser la liaison d'un objet existant
forms.form_field_relationship_allow_new_object_label: Autoriser la création d'un nouvel élément lié
forms.form_field_relationship_related_model_label: Sélectionnez un modèle associé
forms.form_field_relationship_value_label: Sélectionnez une relation
forms.form_share: Formulaire partagé publiquement
forms.new_form_advanced: Avancé
forms.new_form_classic: Classique
forms.no_model: Ce formulaire n'est pas lié à un modèle existant
forms.no_related_step: Non lié à une étape de processus
global:
  actions: Actions
  add: Ajouter
  agree: Oui
  automations: Automatisations
  cancel: Annuler
  click_button_reload: Veuillez cliquer sur le bouton ci-dessous pour recharger !
  client: Client lié
  client_name: Nom du client
  clients: Clients
  close: Fermer
  collaboration: collaboration
  comment: Commentaire
  compare: Comparer
  condition: Condition
  confirm: Envoyer
  create: Créer
  create_variable: Créer une variable
  currency: Monnaie
  dashboard: Tableau de bord
  data: Données
  data_connections: Connexions de données
  data_saved: Vos données ont été enregistrées
  not_permitted: Vous n'avez pas les droits nécessaires pour effectuer cette action
  days: Jours
  delete: Supprimer
  description: Description
  details: Détails
  disabled: désactivé
  disabled_users: Utilisateurs désactivés
  display: Affichage
  display_style: Mode d'affichage
  download_document: Télécharger le document
  duplicate: Dupliquer
  duration: Durée
  edit: Modifier
  email: E-mail
  end_of: Fin de
  entity: Entité
  error: Erreur
  error_json: JSON est incorrect
  error_loading: Une erreur s'est produite au chargement
  error_login_invalid_password: Le mot de passe est incorrect
  error_login_invalid_user: Utilisateur non trouvé
  error_login_unknown: >-
    Erreur de login inconnue, pouvez-vous contacter les équipes Bonx via le
    bouton de chat en bas à droite sur cette page ?
  error_password_validation: >-
    Votre mot de passe doit comporter au moins 8 caractères et doit contenir au
    moins une minuscule, une majuscule et un chiffre.
  error_space_in_name: Le champ ne doit pas avoir d'espace
  error_trailing_underscore: Le nom ne doit pas finir par un tiret bas (_)
  error_do_not_start_with_number: Le nom ne doit pas commencer par un chiffre
  failed: Echoué
  "false": Faux
  field: champ
  fields: champs
  file_input:
    existing_file: "Fichier existant :"
    no_file: Vous n'avez pas encore soumis de fichier
    replace: (le fichier sera remplacé)
  filter: Filtrer
  first_name: Prénom
  follow_up: Suivi
  forgot_password: Mot de passe oublié
  form: Formulaire
  forms: Formulaires
  from: Depuis
  group: Groupe
  group_order: N°(ordre)
  groups: Groupes
  home: Accueil
  icon: Icône
  id: Id
  informations: Informations
  is_hidden: Masquer cet attribut
  is_required: Champ requis
  are_required: Aucun n'est requis | {fields} est requis | {fields} sont requis
  items: élément(s)
  key: Clé
  label: Label
  labels: |-
    label |
    label |
    labels
  large: Grand
  last_name: Nom de famille
  launch: Lancer
  launch_date: Date de lancement
  list: Liste
  loading: Chargement en cours...
  logic: Logique
  login: Connexion
  logout: Se déconnecter
  medium: Moyen
  missing_field: Aucun champ manquant | Champ manquant | {n} champs manquants
  missing_write_request: Vous n'avez pas fourni de requête d'écriture
  models: |-
    modèle |
    modèle |
    modèles
  modified: a modifié
  month:
    april_short: avr
    august_short: août
    december_short: déc
    february_short: fév
    january_short: jan
    july_short: juil
    june_short: juin
    march_short: mar
    may_short: mai
    november_short: nov
    october_short: oct
    september_short: sep
  name: Nom
  new: Nouveau
  new_version: Une nouvelle version est disponible !
  "no": Non
  no_entries: Aucune entrée
  no_results: Pas de résultats
  none: Aucun
  not-found: Pas trouvé
  objects: |-
    Objet |
    Objet |
    Objets
  ok: Ok
  order: Commande
  overview: Aperçu
  parameters: Paramètres
  password: Mot de passe
  pdf_template: Modèles de PDF
  permissions: Permissions
  phone: Téléphoner
  photo: Photo
  photo_url: Url photo
  process: Processus
  processes: Processus
  prod_price: Coût du produit
  project: Projet
  projects: Projets
  reload: Recharger
  remember_me: Se souvenir de moi
  replay: Replay
  reset_email_sent: >-
    Un e-mail pour réinitialiser votre mot de passe a été envoyé. S'il vous
    plaît cliquez sur le lien lorsque vous l'obtenez
  reset_password: Réinitialiser le mot de passe
  role: Rôle
  rows: lignes
  save: Enregistrer
  saving: Sauvegarde en cours...
  search: Rechercher
  select: Sélectionner un élément
  send: Envoyer
  send_reset_email: Envoyer un e-mail de réinitialisation
  settings: Paramètres
  show: Montrer
  small: Petit
  status: Statut
  success: Succès
  table:
    nav: Pagination du tableau
  task: Tâche | Tâche | Tâches
  title: Titre
  total_report: Rapport total
  training_and_help: Formation & Aide
  "true": Vrai
  type: Type
  unknown_user: Utilisateur inconnu
  unsinchronized_store_header_content_pt2: >-
    Par conséquent, vous ne serez pas autorisé à effectuer une modification
    avant d'avoir actualisé la page
  update: Mise à jour
  user: Utilisateur
  users: Utilisateurs
  users_and_permissions: Utilisateurs & Permissions
  validate: Valider
  value: Valeur
  variables: Variables
  views: |-
    Vue |
    Vue |
    Vues
  week: Semaine
  "yes": Oui
  you_dont_have_access_to_this_page: Vous n'avez pas accès à cette page
global.attribute: Attribut
global.close: Fermer
global.instance: Instance
global.model: Modèle
global.toast_error_copy_to_clipboard: Erreur survenue lors de copie de données
global.toast_export_data_error: Erreur survenue lors de l'export des données
global.toast_key_copy_success_content: La clé a été copiée avec succès dans votre presse-papiers.
global.toast_succes_copy_to_clipboard: Le contenu du tableau a été copié avec succès dans votre presse-papiers
global.toast_succes_copy_to_clipboard_link: Le lien a été copié avec succés dans votre presse-papiers.
global.unlink: Délier
global.unsinchronized_store_header_content: >-
  L'élément a été mis à jour.

  Par conséquent, vous ne serez pas autorisé à enregistrer des modifications
  avant d'avoir actualisé la page.
global.unsinchronized_store_header_content_pt1: Les éléments suivants ont été mis à jour
global.unsinchronized_store_header_content_pt2: >-
  Par conséquent, vous ne serez pas autorisé à effectuer une édition avant
  d'avoir actualisé la page
global.unsinchronized_store_header_title: La configuration de Bonx a été modifiée par un autre utilisateur
global.update: Mise à jour de
groups:
  disabled_explanation: Cette vue appartient à l'environnement standard et n'est pas personnalisable
  group_delete: Supprimer le groupe
  group_delete_description: "Voulez-vous vraiment supprimer le groupe %{group_name}\_?"
  group_name: Nom du groupe
  group_order: Ordre du groupe
  groups_list: Liste des groupes
  toast_edit_success: Les modifications sauvegardées au groupe ont été sauvegardées avec succès
home:
  greetings: Bienvenue
integrations:
  login_flow_not_implemented: >-
    Les paramètres de cette intégration ne peuvent être édités que par un
    Administreur Bonx.
  oauth_login: OAuth login
  referenced_resources: Ressources référencées
  title: Intégrations
  type: Type d'intégration
list: Liste
messaging:
  add_to: Ajoutez un destinataire (email ou numéro de téléphone)
  body: Corps du message
  email: Email
  external: Ce message sera envoyé à des personnes externes à votre organisation
  messaging: Discussion
  placeholder: Posez une question ou envoyez une mise à jour
  send: Envoyer
  sent_message: a envoyé un message
  sms: SMS
  somebody: Quelqu'un
  subject: Objet du message
  template: |-
    Modèle de message |
    Modèle de message |
    Modèles de message
  user_firstname: Prénom de l'utilisateur
  user_lastname: Nom de famille de l'utilisateur
  variables: Variables
  whatsapp: WhatsApp
modal_confirmation_popup_cancel: Non
modal_confirmation_popup_confirm: Oui
month: Mois
object:
  also_delete_related_object: Supprimer également et définitivement l'objet associé
  attribute:
    attributes: |-
      attribut |
      attribut |
      attributs
  attributes: Attributs de l'objet
  attributes_list: Liste des attributs
  cancel_changes: Modifications non sauvegardées
  cancel_changes_description: "Des modifications de données n'ont pas été sauvegardées. Etes-vous sûr de vouloir continuer sans les enregistrer \_?"
  create_object: Créer un objet
  create_related_object: Créer un nouvel objet lié
  delete_object: Supprimer l'objet
  delete_object_attribute: Supprimer un attribut
  delete_object_attribute_description: "L'attribut suivant %{attribute_name} va être supprimé.  \nCette supression est définitive. \nConfirmer\_?"
  delete_object_description: "L'objet suivant va être supprimé.  \nCette supression est définitive. \nConfirmer\_?"
  delete_related_object: Dé-lier l'objet associé
  deletion_confirmation_body: Êtes-vous sûr de vouloir dé-lier cet objet ?
  error_attribute_with_same_name_exists: >-
    Un attribut existant ou supprimé a déjà le même nom, veuillez contacter
    votre service informatique ou un administrateur Bonx pour plus
    d'information.
  error_loading: Une erreur s'est produite lors du chargement du contenu de cette page
  error_no_primary_attribute: Aucun attribut principal n'a été défini pour ce modèle
  link_related_object: Relier un objet existant
  related_objects: |-
    Objet associé  |
    Objet associé |
    Objets associés
  relation:
    attributes_list: Liste des attributs de la relation
  relation_dir_attribute: Direction
  relation_label_attribute: Libellé
  relation_linked_to: Restreindre le lien à
  relation_not_found: Relation non trouvée
  relation_sort_asc: Croissant
  relation_sort_attribute: Tri
  relation_sort_desc: Décroissant
  relation_sort_unset: Non défini
  relationship:
    many_to_many: N:N
    many_to_one: N:1
    object: Objet lié
    one_to_many: 1:N
    one_to_one: "1:1"
    to_object: A l'objet
  relationship_list: Liste des relations
  relationships: |-
    relation |
    relation |
    relations
  search_related_objects: Rechercher un objet à relier
  selected_setting_object: Type d'objet
  show_details: Voir les détails
  timeline: Historique
objects:
  add_attribute: Ajouter un attribut
  add_label: Créer un label
  add_object: Créer un objet
  attributes_subtype_float: Décimal
  attributes_subtype_integer: Entier
  attributes_subtype_link: Lien
  attributes_subtype_plain_text: Texte brut
  attributes_type_boolean: Booléen
  attributes_type_date: Date
  attributes_type_datetime: Date et heure
  attributes_type_file: Fichier
  attributes_type_multitag: Multitags
  attributes_type_number: Nombre
  attributes_type_progress: Progression
  attributes_type_relation: Relation
  attributes_type_tag: Tag
  attributes_type_text: Texte
  attributes_type_textarea: Zone de texte
  data_model: Modèle de données
  data_models: Modèles de données
  delete_data_model: Supprimer un modèle
  delete_data_model_description: "Le modèle suivant '%{attribute_name}' va être supprimé.  \nCette supression est définitive. \nConfirmer\_?"
  delete_objects: Supprimer des objects
  delete_objects_description: "Les %{count} suivants vont être supprimés. \nCette supression est définitive. \nConfirmer\_?"
  delete_relation: Supprimer le lien
  displayed_name: Nom affiché
  edit_attribute: Modifier un attribut
  edit_label: Modifier un label
  edit_object: Modifier un objet
  label:
    placeholder: Veuillez choisir un libéllé
  label_objects: Objets étiquettés avec ce label
  label_relations: Relations étiquettées avec ce label
  labels: Labels
  labels_list: Liste des labels
  models: Modèles
  models_list: Listes de modèles
  objects_list: Liste des objets
  overview: Aperçu
  relation:
    add_relation: Ajouter une relation
    edit_relation: Modifier une relation
  relations: Relations
otable:
  error:
    table_has_no_column: >-
      Ce tableau sera visible dès qu'un attribut sera ajouté en tant que
      colonne, merci de contacter un administrateur de votre entreprise ou de
      Bonx.
pages:
  automation: Automatisation
  data-connection: Connexion de données
  object: Objets
  role: Paramètres
pdf_template:
  create_template: Créer un template
  id_error: Vous devez renseignez un id pour le template pdf
  model: Modèle
  no_template: Aucun template de PDF pour ce type d'objet
  pdf_export: Export PDF
process.finished: Fini
process.in_progress: En cours
process.waiting: En attente
processes:
  next_step: Etape suivante
  save_first: Merci de sauvegarder le formulaire afin de pouvoir rajouter des formulaires
  step_name: Nom de l'étape
profile:
  gantt_size_label: Affichage du diagramme de Gantt
  home_page: Page d'accueil
  my_profile: Mon profil
  settings: Réglages
settings:
  actions:
    create: Créer (global)
    create_own: Créer
    delete: Supprimer (global)
    delete_own: Supprimer
    read: Lire (global)
    read_own: Lire
    update: Mettre à jour (global)
    update_own: Mettre à jour
  parent: Hérite de
  permissions:
    action: Action
    allow: Autorisé
    calculated: Droit calculé
    conflict: Conflit
    deny: Refusé
    info_1: >-
      Les modifications des droits ne s'appliquent qu'à ce groupe et aux groupes
      enfants.
    info_2: Hérité signifie que les droits du groupe parent seront utilisés.
    info_3: >-
      Refusé signifie que quels que soient les droits du groupe parent, le
      groupe concerné ne pourra pas effectuer cette action.
    info_4: >-
      Autorisé signifie que le groupe concerné pourra effectuer cette action ;
      s'il y a conflit avec le groupe parent, la modification ne sera pas
      appliquée, le label Non autorisé (verrouillé) sera affiché dans la colonne
      'Droits appliqués'
    info_5: >-
      Non défini n'est utilisé que pour le groupe 'Invité'. Le groupe 'Invité'
      est le parent de tous les groupes. Si un droit n'est pas défini, il sera
      traité comme 'Refusé' mais peut être changé pour les groupes enfants,
      composants, catégories et éléments.
    inherit: Hérité
    not_set: Non défini
    permission: Permission
    resource: Ressource
  resources:
    clients: Clients
    forms: Formulaires
    messagetemplates: Modèles de message
    settingdataconnections: Automatisations
    settings: Paramètres
    settingsmodels: Modèles
    users: Utilisateurs
    viewgroups: Groupes de vue
    views: Vue
  roles:
    admin: Super Administrateur
    client_admin: Administrateur
    guest: Invité
    user: Opérateur
timeline:
  a_customer: Un client
  addComment: Ajouter un commentaire
  commented: a commenté
  comments: Commentaires
  edit_value: a edité la valeur
  external_user: Utilisateur externe
  form_submitted: a soumis un formulaire
  messages: Messages
  new_value: Nouvelle valeur
  no_events: Aucun événement pour cet objet
  old_value: Ancienne valeur
toast:
  delete_failure: L'objet ou les objets n'ont pas réussis à être supprimés
  delete_objects: L'objet ou les objets ont été supprimés avec succès
  form_submit_content: Le formulaire a été soumis avec succès
  print_success: Une page va bientôt s'ouvrir
  share_form_and_copy_url_success: L'url unique du formulaire a été copiée dans le presse papier
  view_error_no_model_title: Bonx vous permettra bientôt d'éditer des vues complexes
  view_error_no_model_title_content: >-
    Cette vue a été créée manuellement, veuillez contacter votre service
    informatique ou un administrateur Bonx.
toast.form_field_file_submit_content_error: Ce formulaire nécessite l'envoi d'un fichier.
today: Aujourd'hui
user:
  add_label: Créer un label
  data_model:
    labels: Labels
    models: Modèles
    relations: Relations
  data_models: Modèles de données
  edit_label: Modifier un label
  label:
    placeholder: Chercher un label...
  models_list: Liste des labels
view:
  action_move_up: Déplacer vers le haut
  config_error_message: >-
    Votre view ne contient aucun attribut. Veuillez la configurer en cliquant
    sur le bouton dédié.
  config_view: Configurer la view
  edit_view: Modifier
  no_title: Pas de titre
  toast_automation_saved: Automatisation sauvegardée avec succès
  toast_edit_success: Le détail de la vue a été modifié avec succès
  toast_error_save_automation: Impossible de sauvegarder l'automatisation
views:
  action_move_down: Déplacer vers le bas
  add_attribute: Nouvel attribut
  add_block: Nouveau bloc
  block_name: Nom du bloc
  block_type: Type du bloc
  blocks: Blocs
  create_new_view: Créer une nouvelle vue
  create_new_view_description: >-
    Les vues vous permettent de créer les écrans pour visualiser et modifier la
    donnée qui vous intéresse, sous différentes formes et de la partager à vos
    équipes.
  create_view: Créer une nouvelle vue
  creation_automation_name_label: Nom de l'automatisation
  creation_form_name_label: "Nom du formulaire\t"
  creation_view_model: Donnée de base
  creation_view_name: Nom de la vue
  default_setup_element_name: Données liées au modèle
  default_view_group_name: Groupe par défaut
  deletion_confirmation_body: "Voulez-vous vraiment supprimer cette vue\_? \nToutes ses données seront sauvegardées au cas où vous commettriez une erreur et souhaiteriez l'annuler."
  deletion_confirmation_body_soft: Voulez-vous vraiment supprimer cette vue ?
  deletion_confirmation_title: Suppression d'une Vue
  duration:
    d: "{value} jours"
    h: "{value} heures"
    m: "{value} minutes"
    s: "{value} secondes"
  duration_format: Format
  duration_formats:
    d: jours
    dhms: jours, heures, minutes, secondes
    h: heures
    hms: heures, minutes, secondes
    m: minutes
    ms: minutes, secondes
    s: secondes
  edit_attributes_options: Options d'attributs
  edit_attributes_options_add_colors: Couleurs
  edit_attributes_options_add_icon: Icônes
  edit_attributes_options_add_status: Statut
  edit_attributes_options_background_color: Couleur de l'arrière plan
  edit_attributes_options_border_color: Couleur de la bordure
  edit_attributes_options_description: >-
    Séléctionner les options d'un attribut vous permet de changer ses paramètres
    profond.
  edit_attributes_options_hide_json: Fermer les détails
  edit_attributes_options_icon: Icône
  edit_attributes_options_no_attributes: Ce bloc ne possède pas encore d'attribut.
  edit_attributes_options_show_json: Afficher les détails
  edit_attributes_options_status_error: "Statut : erreur"
  edit_attributes_options_status_success: "Statut : succès"
  edit_attributes_options_status_warning: "Statut : avertissement"
  edit_import_model: Importer un model
  edit_import_model_description: >-
    Importer un model vous permet d'ajouter en un clic tous ses attributs en
    tant qu'axes du bloc.
  edition_deletion: Supprimer la vue
  edition_no_columns: >-
    Les cases de séléction seront disponibles dès qu'un attribut sera ajouté au
    model sélectionné, merci de contacter un administrateur de votre entreprise
    ou de Bonx.
  edition_no_related_objects: Ce modèle n'est lié à aucun autre modèle.
  edition_primary_table: Table de la donnée primaire
  edition_secondary_related_objects: Objets associés
  edition_selected_columns: Colonnes
  error:
    loading_image: Une erreur s'est produite lors chargement des images
    printer: Une erreur s'est produite avec l'imprimante
    query_invalid_or_missing: >-
      La requête fournie est vide ou comporte une erreur, veuillez contacter un
      administrateur de votre entreprise ou de Bonx.
  filter_label_placeholder: Label à filtrer
  filter_value_placeholder: Valeur
  filter_view: Filtrer cette vue
  filters_add_filter: Ajouter une condition
  gantt:
    bar:
      no_label: Cette barre ne possède aucun label
    precision:
      day: jours
      display_by: Afficher par
      month: mois
  id: Id
  label: Label
  modal_new_view_description: >-
    Les vues vous permettent de visualiser la donnée qui vous intéresse, sous
    différentes formes et de la partager à vos équipes.
  query: Requête GraphQL
  title: Titre
  toast_deletion_success: Cette vue a été supprimée avec succès.
  toast_edit_success: Les modifications apportées à la vue ont été sauvegardées avec succès
  type: Type
  view_block_name_placeholder: Nom du bloc de vue (optionnel)
  view_group: Groupe de vue
  view_name_placeholder: Nom de la vue
  view_parameters_title: ⚙️ Paramètres de la vue
  views_list: Liste des vues
week: Semaine
