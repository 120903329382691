import { Acl } from "@nicolabello/js-acl"
import { cloneDeep, sortBy } from "lodash-es"
import { userStore } from "../stores/user"
import { updateUserClientAcl } from "~/controllers/clients"

export type Role = string
export type Verb = "allow" | "deny"

export enum Modifiers {
  Allow = 1,
  Deny = 0,
  Inherit = -1,
}

export interface CustomRole {
  role: Role
  label: string
  parent?: Role
  client_id?: string
  level?: number
}

type ParentCustomRole = CustomRole & {
  children?: Array<CustomRole>
}

export enum Roles {
  Guest = "GUEST",
  User = "USER",
  ClientAdmin = "CLIENT_ADMIN",
  Admin = "ADMIN",
}

export enum Resources {
  Views = "VIEWS",
  ViewGroups = "VIEW_GROUPS",
  Forms = "FORMS",
  Settings = "SETTINGS",
  SettingModels = "SETTING_MODELS",
  SettingDataConnections = "SETTING_DATA_CONNECTIONS",
  Users = "USERS",
  Clients = "CLIENTS",
  MessageTemplates = "MESSAGE_TEMPLATES",
  PdfTemplates = "PDF_TEMPLATES",
}

export enum Actions {
  Create = "CREATE",
  CreateMyOwn = "CREATE_OWN",
  Read = "READ",
  ReadMyOwn = "READ_OWN",
  Update = "UPDATE",
  UpdateMyOwn = "UPDATE_OWN",
  Delete = "DELETE",
  DeleteMyOwn = "DELETE_OWN",
}

export interface Privilege {
  role: Role
  resource: Resources | string
  verb: Verb
  action: Actions | string
}

export interface ClientAcl {
  flattenRoles: Array<Role>
  roles: Array<CustomRole>
  privileges: Array<Privilege>
}

export function useAcl() {
  let _acl: Acl | null = null

  const getClientAcl = (): ClientAcl => {
    // Load user and client data
    const user = userStore().user
    return cloneDeep(user.client?.acl ?? { roles: [], privileges: [] })
  }

  const getRoles = () => {
    // Main common roles
    const roles: Array<CustomRole> = [
      { role: Roles.Guest, /* parent: undefined, */ label: "settings.roles.guest" }, // Guest is root
      { role: Roles.User, parent: Roles.Guest, label: "settings.roles.user" }, // User inherit from Guest
      { role: Roles.ClientAdmin, parent: Roles.User, label: "settings.roles.client_admin" }, // ClientAdmin inherit from User
      { role: Roles.Admin, parent: Roles.Guest, label: "settings.roles.admin" }, // Admin inherit from Guest
    ]

    // Custom client roles
    const clientAcl = getClientAcl()
    if (clientAcl.roles.length > 0)
      roles.push(...clientAcl.roles)

    // Sort roles by parent
    for (const role of roles as Array<CustomRole & { _sortKey: string }>) {
      if (!role.parent) {
        role.level = 1
        role._sortKey = role.role
      }
      else {
        const parent = roles.find(r => r.role === role.parent) as (CustomRole & { _sortKey: string }) | undefined
        if (parent) {
          role.level = (parent.level as number) + 1
          role._sortKey = `${parent._sortKey}.${role.role}`
        }
      }
    }

    return sortBy(roles, ["_sortKey"])
  }

  const getAcl = () => {
    if (!_acl) {
      // Get client specific ACL
      const clientAcl = getClientAcl()

      // Instantiate ACL
      _acl = new Acl()

      // Roles
      const roles = getRoles()
      for (const { role, parent } of roles)
        _acl.addRole(role, parent)

      // Main common resources
      _acl.addResource(Resources.ViewGroups)
      _acl.addResource(Resources.Views)
      _acl.addResource(Resources.Forms)
      _acl.addResource(Resources.Settings)
      _acl.addResource(Resources.SettingModels)
      _acl.addResource(Resources.SettingDataConnections)
      _acl.addResource(Resources.Users)
      _acl.addResource(Resources.Clients)
      _acl.addResource(Resources.MessageTemplates)
      _acl.addResource(Resources.PdfTemplates)

      // Custom client resources
      if (clientAcl.privileges.length > 0) {
        for (const privilege of clientAcl.privileges) {
          // If we a separator, we have a resource and a specific ID
          if (privilege.resource.includes("."))
            _acl.addResource(privilege.resource, privilege.resource.split(".")[0]) // Ex : ViewGroup.1234567890 => ViewGroup.1234567890 inherit from ViewGroup
        }
      }

      // Main common privileges
      // By default, all privileges are denied if not explicitly allowed.

      // Guest can do nothing
      _acl.deny(Roles.Guest, null)

      // Admins are gods !
      _acl.allow(Roles.Admin, null)

      // Client admins can do anything on their own client
      _acl.allow(Roles.ClientAdmin, null, [
        Actions.CreateMyOwn,
        Actions.ReadMyOwn,
        Actions.UpdateMyOwn,
        Actions.DeleteMyOwn,
      ])

      // Custom client privileges
      if (clientAcl.privileges.length > 0) {
        for (const { role, resource, verb, action } of clientAcl.privileges) {
          const method = verb === "allow" ? "allow" : "deny"
          _acl[method](role, resource, action)
        }
      }
    }
    return _acl
  }

  const _getResourceNode = (resource: Resources | string): object | undefined => {
    const acl = getAcl()
    // @ts-expect-error I know this is private but I need it
    return acl.resources.find(resource)
  }

  const _getRoleNode = (role: Role | string): { parent: any } | undefined => {
    const acl = getAcl()
    // @ts-expect-error I know this is private but I need it
    return acl.roles.find(role)
  }

  const _getResourceModifier = (roleNode: any, resourceNode: any, action: string): Modifiers => {
    const acl = getAcl()
    // @ts-expect-error I know this is private but I need it
    return acl.getResourceBranchModifier(roleNode, resourceNode, action)
  }

  const _getRoleModifier = (roleNode: any, resourceNode: any, action: string): Modifiers => {
    const acl = getAcl()
    // @ts-expect-error I know this is private but I need it
    return acl.getRoleBranchModifier(roleNode, resourceNode, action)
  }

  const isAllowed = (role: Role, resource: Resources | string, action: Actions | string, withInheritance = true) => {
    const roleNode = _getRoleNode(role)
    if (!roleNode)
      return false

    const _isAllowed = (resourceNode: object) => {
      const modifier = _getResourceModifier(roleNode, resourceNode, action)
      if (modifier === Modifiers.Allow || modifier === Modifiers.Deny)
        return modifier

      if (withInheritance && roleNode.parent)
        return _getRoleModifier(roleNode.parent, resourceNode, action)

      return Modifiers.Inherit
    }

    let resourceNode = _getResourceNode(resource)

    // If we a separator, we have a resource and a specific ID
    if (resource && resource.includes(".")) {
      // Check first if the specific resource is registered in ACL
      if (!resourceNode) {
        // The resource is not registered in ACL, so we check parent resource if it is for inheritance check
        if (withInheritance) {
          const resourceParent = resource.split(".")[0]
          resourceNode = _getResourceNode(resourceParent)
          if (resourceNode)
            return _isAllowed(resourceNode)
        }
        return Modifiers.Inherit
      }
    }

    if (resourceNode)
      return _isAllowed(resourceNode)

    return false
  }

  const canI = (
    action?: Actions | string | Array<Actions | string>,
    resource?: Resources | string,
    object?: object & { client_id: string },
  ) => {
    const acl = getAcl()
    const user = userStore().user
    const actions = Array.isArray(action) ? action : [action]

    // At least one action must be allowed
    for (const action of actions) {
      // Check if customer user is allowed to do something on My own client data
      if (action === Actions.ReadMyOwn || action === Actions.UpdateMyOwn || action === Actions.DeleteMyOwn) {
        if (object && object.client_id !== user.client_id)
          return false
      }

      // If we a separator, we have a resource and a specific ID
      if (resource && resource.includes(".")) {
        // Check first if the specific resource is registered in ACL
        const resourceNode = _getResourceNode(resource)
        if (!resourceNode) {
          // The resource is not registered in ACL, so we check parent resource
          const resourceParent = resource.split(".")[0]
          if (acl?.isAllowed(user.role, resourceParent, action ?? null))
            return true
        }
      }

      if (acl?.isAllowed(user.role, resource ?? null, action ?? null))
        return true
    }

    return false
  }

  const amI = (roles: Role | Array<Role>) => {
    roles = Array.isArray(roles) ? roles : [roles]
    const user = userStore().user
    return roles.includes(user.role)
  }

  const getRolesTree = () => {
    const roles = getRoles() as Array<ParentCustomRole>

    // Build roles tree
    const rolesTree: Array<ParentCustomRole> = []
    for (const role of roles) {
      if (!role.parent) {
        role.level = 1
        rolesTree.push(role)
      }
      else {
        const parent = roles.find(r => r.role === role.parent)
        if (parent) {
          role.level = (parent.level as number) + 1
          if (!parent.children)
            parent.children = []

          parent.children.push(role)
        }
      }
    }

    return rolesTree
  }

  const saveRoles = async (roles: Array<CustomRole>) => {
    const user = userStore().user
    const acl = getClientAcl()
    acl.roles = roles
    acl.flattenRoles = roles.map(r => r.role)
    await updateUserClientAcl(acl, user.client_id)
    user.client.acl = acl
    _acl = null
  }

  const getResources = (commonOnly = false) => {
    const resources: Array<{ resource: Resources, parent?: Resources, label: string }> = [
      // Main common resources
      { resource: Resources.ViewGroups, label: "settings.resources.viewgroups" },
      { resource: Resources.Views, label: "settings.resources.views" },
      { resource: Resources.Forms, label: "settings.resources.forms" },
      { resource: Resources.Settings, label: "settings.resources.settings" },
      { resource: Resources.SettingModels, label: "settings.resources.settingsmodels" },
      { resource: Resources.SettingDataConnections, label: "settings.resources.settingdataconnections" },
      { resource: Resources.Users, label: "settings.resources.users" },
      { resource: Resources.Clients, label: "settings.resources.clients" },
      { resource: Resources.MessageTemplates, label: "settings.resources.messagetemplates" },
    ]

    if (commonOnly)
      return resources

    // Custom client resources
    const clientAcl = getClientAcl()
    if (clientAcl.privileges.length > 0) {
      for (const privilege of clientAcl.privileges) {
        // If we a separator, we have a resource and a specific ID
        const resource = privilege.resource.split(".")
        if (resource.length > 1) {
          resources.push({
            resource: privilege.resource as Resources,
            parent: resource[0] as Resources,
            label: "Custom",
          }) // Ex : ViewGroup.1234567890 => ViewGroup.1234567890 inherit from ViewGroup
        }
      }
    }

    return resources
  }

  const getResource = (resource: Resources | string) => {
    const resources = getResources()
    return resources.find(r => r.resource === resource)
  }

  const savePrivileges = async (privileges: Array<Privilege>) => {
    const user = userStore().user
    const acl = getClientAcl()
    acl.privileges = privileges
    await updateUserClientAcl(acl, user.client_id)
    user.client.acl = acl
    _acl = null
  }

  return {
    getAcl,
    getClientAcl,
    getRoles,
    getRolesTree,
    saveRoles,
    savePrivileges,
    getResources,
    getResource,
    isAllowed,
    canI,
    amI,
  }
}
