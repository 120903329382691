import { userStore } from "~/stores/user"
import { orgStore } from "~/stores/org"
import { viewsStore } from "~/stores/views"
import { viewGroupsStore } from "~/stores/viewGroups"
import { usersStore } from "~/stores/users"
import { formsStore } from "~/stores/forms"
import { globalStore } from "~/stores/global"
import DBHelper from "~/helpers/dbHelper/index"
import { modelsStore } from "~/stores/models"
import { automationsStore } from "~/stores/automations"

const dbHelper = new DBHelper()

let uid = ""

export async function loadStores(userId: string) {
  uid = userId
  await userStore().loadUser(uid)
  await globalStore().loadGlobalStore()
  await loadAppStores()
  setOnSnapshotListener()
}

async function loadAppStores() {
  await orgStore().loadOrg()
  await Promise.all([
    usersStore().loadUsers(),
    viewsStore().loadViews(),
    viewGroupsStore().loadViewGroups(),
    modelsStore().loadModels(),
    formsStore().loadForms(),
    automationsStore().loadAutomations(),
  ])
}

function callBack() {
  void loadAppStores()
}

// listen to refresh_views_cache_id
function setOnSnapshotListener() {
  const cacheId = globalStore().refresh_cache_id
  if (cacheId)
    dbHelper.getDocFromCollectionOnSnapshot("cache", cacheId, callBack)
}
