/**
 * Change flat array to array of objects with id key
 * @param arr The array to modify
 * @param arrayKey The key of the base document to modify
 * @returns The modified array
 */
export function addIdKeyInArray(doc, arrayKey) {
    return { ...doc, [arrayKey]: doc[arrayKey].map((item) => {
            if (typeof item === "object" && item !== null && "id" in item)
                return item;
            return { id: item };
        }) };
}
/**
 * Flatten array of objects with id key back to flat array
 * @param arr The array to flatten
 * @param arrayKey The key of the base document to modify
 * @returns The flattened array
 */
export function flattenIdKeyInArray(doc, arrayKey) {
    return { ...doc, [arrayKey]: doc[arrayKey].map((item) => {
            if (typeof item === "object" && item !== null && "id" in item)
                return item.id;
            return item;
        }) };
}
