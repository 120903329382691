<script setup lang="ts">
import { useHead } from "@vueuse/head"
import { useI18n } from "vue-i18n"
import { reactive } from "vue"
import { VERSION } from "@bonx/common"
import { loadUser } from "./controllers/authentication"
import AnalyticsHelper from "./helpers/AnalyticsHelper"
import { hotReload, versionToNumber } from "./helpers/UtilsHelper"
import { apiStore } from "./stores/api"
import { qrStore } from "./stores/qr"

const analyticsHelper = new AnalyticsHelper()
const route = useRoute()
const router = useRouter()

const { locale } = useI18n()
const api = apiStore().getApiClient

useHead({
  title: `Bonx - ${VERSION}`,
  meta: [{ name: "description", content: `Bonx - ${VERSION}` }],
})

const appData = reactive({
  loading: false,
  interval_ctx: null as any,
  show_update_bar: false,
  input_buffer: "",
  last_input_timestamp: null,
  last_detected_newline_timestamp: null,
})

function getNavigatorLanguage() {
  const storedLanguage = localStorage.getItem("userLanguage")
  if (storedLanguage)
    return storedLanguage

  let language = "en"
  if (typeof window !== "undefined")
    language = navigator.language.substring(0, 2)

  return language
}
// load user local
const localeIso: string = getNavigatorLanguage() // navigator.language.substring(0, 2)
locale.value = localeIso
// load User Info

/* checkVersion */
async function checkVersion() {
  // BNX-1154: Ne pas afficher le bandeau de nouvelle version sur les shared forms anonymyes
  if (typeof route.query.auth_token !== "undefined")
    return

  const status = await api.getStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  if (frontVersion < serverVersion)
    appData.show_update_bar = true
}

async function init() {
  analyticsHelper.logAction("INIT_APP")
  appData.loading = true
  await loadUser()
  if (typeof window !== "undefined") {
    checkVersion()
    appData.interval_ctx = setInterval(checkVersion, 1000 * 60 * 1) // ask for new version very 1 min
  }
  const query: any = route?.query
  if (query?.go)
    router.push(`/${query?.go}`)

  appData.loading = false

  window.addEventListener("keydown", (ev) => {
    // Scanner might return an Enter at the end of QR code if configured
    if (ev.key === "Enter" && ev.timeStamp - appData.last_input_timestamp < 100) {
      appData.last_detected_newline_timestamp = ev.timeStamp
      qrStore().setCapturedQR(appData.input_buffer)
    }

    if (appData.last_input_timestamp === null || ev.timeStamp > appData.last_input_timestamp + 300)
      appData.input_buffer = ""

    // Regex matches unicode characters
    // Only push valid characters into buffer
    if (/^.$/u.test(ev.key))
      appData.input_buffer += ev.key

    appData.last_input_timestamp = ev.timeStamp
  })
}

const channel = new BroadcastChannel("authChannel")
channel.addEventListener("message", (event) => {
  if (event.data === "logout")
    location.replace("/auth/login")
  else if (event.data === "login")
    location.replace("/")
})

init()
</script>

<template>
  <!-- TODO MODAL TO MOVE SOMEWHERE -->
  <transition enter-active-class="duration-300" leave-active-class="duration-300">
    <div
      v-show="appData.show_update_bar"
      class="h-7 z-[100] w-full fixed flex justify-center items-center bg-green-600 shadow-md shadow-gray-400 transition-transform"
      :class="{ 'translate-y-0': appData.show_update_bar, '-translate-y-full': !appData.show_update_bar, 'shadow-md shadow-gray-400': appData.show_update_bar }"
    >
      <div class="flex items-center mx-auto">
        <div class="text-xs flex text-white gap-2 mr-4">
          <div
            i="carbon-data-enrichment"
          />
          <h1>{{ $t("global.new_version") }}</h1>
        </div>
        <button
          class="inline-flex justify-center border border-gray-100 rounded py-0.5 px-1 items-center text-xs text-gray-100 hover:"
          @click="() => {
            appData.show_update_bar = false
            hotReload()
          }"
        >
          {{ $t("global.reload") }}
        </button>
      </div>
      <button
        class="text-white"
        @click="appData.show_update_bar = false"
      >
        <span class="sr-only">{{ $t("global.close") }}</span>
        <div
          i="carbon-close"
          class="h-5 w-5"
        />
      </button>
    </div>
  </transition>
  <router-view v-if="!appData.loading" />
  <div
    v-else
    class="flex w-full h-full justify-center items-center bbx-bg-primary-gradient"
  >
    <img
      src="/assets/logo-green.svg"
      alt="Bonx"
      title="Bonx"
      class="w-1/10 animate-ping"
    >
    <!-- TODO MODAL TO MOVE BELOW -->
    <div
      class="absolute bottom-20 flex flex-col items-center cursor-pointer text-white"
      @click="hotReload"
    >
      <div
        class="text-3xl"
        i="carbon-reset"
      />
      <span>{{ VERSION }}</span>
    </div>
  </div>
</template>
