/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
import { getQR } from './base.js';
import { getOptions } from './options.js';
import { getPath } from './path.js';
export function getSVG(text, options) {
    const opt = getOptions(options);
    const matrix = getQR(text, opt);
    const X = matrix.length + 2 * opt.margin;
    let svg = '<?xml version="1.0" encoding="UTF-8"?>';
    svg += '<svg xmlns="http://www.w3.org/2000/svg" ';
    if (opt.size > 0) {
        const XY = X * opt.size;
        svg += `width="${XY}" height="${XY}" `;
    }
    svg += `viewBox="0 0 ${X} ${X}">`;
    svg += `<path d="${getSVGPath(matrix, opt.margin)}"/></svg>`;
    return svg;
}
export function getSVGPath(matrix, margin) {
    let path = '';
    getPath(matrix).forEach((subpath) => {
        for (let k = 0; k < subpath.length; k++) {
            const item = subpath[k];
            switch (item[0]) {
                case 'M':
                    path += `M${item[1] + margin} ${(item[2] || 0) + margin}`;
                    break;
                default:
                    path += item.join('');
                    break;
            }
        }
        path += 'z';
    });
    return path;
}
