import { cloneDeep, isString, isUndefined } from "lodash-es";
import { templatesData } from "../templates/data.js";
/**
 * Returns all automations available in the library
 * @returns
 */
export function getAutomationStepLibrary() {
    const rawLib = templatesData.core.library.automation_steps;
    const modelBasedAutomationStepLibrary = Object.values(rawLib)
        .map((model) => {
        return Object.values(model);
    })
        .flat()
        .map((s) => {
        return {
            ...s,
            template_path: `core.library.automation_steps.${s?.base_model}.${s.name}`,
        };
    });
    const globalAutomationStepLibrary = Object.values(templatesData.core.library.automation_steps_globals).map((s) => {
        return {
            ...s,
            template_path: `core.library.automation_steps_globals.${s.name}`,
        };
    });
    return globalAutomationStepLibrary.concat(modelBasedAutomationStepLibrary);
}
/**
 * From a fully qualified path, returns the configuration of the automation step in the library
 * @param path
 * @returns
 */
export function getAutomationStepLibraryByPath(path) {
    return getAutomationStepLibrary().find(s => s.template_path === path);
}
/**
 * Retrieve the document for a template document id
 * @param collection The collection name
 * @param docId The document id
 * @returns The document data
 */
export function getCoreTemplateDoc(collection, docId) {
    const DEFAULT_TEMPLATE_COLLECTION = "core";
    const collectionData = templatesData[DEFAULT_TEMPLATE_COLLECTION].collections[collection];
    if (isUndefined(collectionData))
        return null;
    if (isUndefined(collectionData[docId]))
        return null;
    return cloneDeep(collectionData[docId]);
}
/**
 * Retrieve the collection of documents for a collection name
 * @param collection The name of the collection
 * @param filters Optional filters to apply to the collection
 * @returns The array of documents
 */
export function getCollection(collection, filters) {
    const collectionData = templatesData.core.collections[collection];
    if (isUndefined(collectionData))
        return null;
    // Filter the collection
    if (!isUndefined(filters)) {
        return Object.fromEntries(Object.entries(collectionData).filter(([_, doc]) => {
            return Object.entries(filters).every(([key, value]) => key === "client_id" || doc[key] === value);
        }));
    }
    return cloneDeep(collectionData);
}
/**
 * Check if a document id is a template document id
 * @param docId The document id to check
 * @returns True if the document id is a template document id
 */
export function isTemplateDocumentId(docId) {
    return docId.endsWith("_@@");
}
/**
 * Check if a document is a custom document (not an override of a template) created by the user
 */
export function isCustomDocument(doc) {
    return isUndefined(doc.template) && !isTemplateDocumentId(doc.id);
}
/**
 * Check if a document is an override document of a base template doc
 */
export function isOverrideDocument(doc) {
    return isString(doc.template) && isTemplateDocumentId(doc.template);
}
/**
 * Check if a property is a custom property created by the user in a template document or in a custom document
 * @param doc The base document
 * @param property The property to check
 */
export function isCustomProperty(doc, property) {
    return isCustomDocument(doc) || (isOverrideDocument(doc) && property.is_custom === true);
}
