import { createPinia } from "pinia"
import type { Router } from "vue-router/auto"
import { markRaw } from "vue"
import type { UserModule } from "../types"

declare module "pinia" {
  export interface PiniaCustomProperties {
    $router: Router
  }
}

// Setup Pinia
// https://pinia.esm.dev/
export const install: UserModule = ({ isClient, initialState, app, router }) => {
  const pinia = createPinia()

  // Router is available in store actions
  pinia.use(({ store }) => {
    store.$router = markRaw(router)
  })

  app.use(pinia)
  // Refer to
  // https://github.com/antfu/vite-ssg/blob/main/README.md#state-serialization
  // for other serialization strategies.
  if (isClient)
    pinia.state.value = (initialState.pinia) || {}

  else
    initialState.pinia = pinia.state.value
}
