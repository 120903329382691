import { cloneDeep, isNull, isUndefined } from "lodash-es";
import { isFirestoreDocError } from "../helpers/dbHelper.js";
import { getCollection, getCoreTemplateDoc, isCustomDocument, isTemplateDocumentId } from "../helpers/templatesHelper.js";
import { buildListFromCoreAndCustom, createCustomTrackerLinkedList } from "../helpers/coreCustomization.js";
import { addIdKeyInArray, flattenIdKeyInArray } from "../helpers/arrayHelpers.js";
/**
 * Collection Service
 * Manages CRUD operations for a collection of documents
 *
 * Handle core documents in modules and custom documents in Firestore
 */
export class CollectionService {
    /**
     * The Firestore database helper
     */
    dbHelper;
    /**
     * The collection name
     */
    collection;
    /**
     * The client id (optional)
     */
    clientId;
    /**
     * List of modules that are enabled/disabled for the client
     */
    modules;
    /**
     * Constructor
     * @param dbHelper The Firestore database helper
     * @param collection The collection name
     * @param clientId The client id (optional)
     */
    constructor(dbHelper, collection, clientId) {
        this.dbHelper = dbHelper;
        this.collection = collection;
        this.clientId = clientId;
    }
    /**
     * List all documents in the collection
     * @returns List of documents
     */
    async list(options = {}) {
        // Force the client_id filter
        if (this.clientId)
            options.filters = { ...options?.filters, client_id: this.clientId };
        // Load the available modules
        await this.loadAvailableModules();
        // Get the CORE collection data if the CORE module is enabled for the client
        let coreCollection = {};
        if (this.modules.core === true) {
            // Load the collection data for the module
            coreCollection = getCollection(this.collection, options.filters) ?? {};
        }
        // Get the custom collection data
        let customCollection = [];
        if (options?.filters)
            customCollection = await this.dbHelper.getAllDataFromCollectionWithWhereArray(this.collection, options.filters);
        else
            customCollection = await this.dbHelper.getAllDataFromCollection(this.collection);
        // Migrate legacy documents if needed
        customCollection = customCollection.map((doc) => this.legacyMigration(doc));
        // Split between the custom documents that are generated from the modules collection and the ones that are not
        const overridenModuleDocs = [];
        const addedCustomDocs = [];
        let overridesCustomDocs = [];
        for (const customDoc of customCollection) {
            if (isCustomDocument(customDoc)) {
                addedCustomDocs.push(this.legacyMigration(customDoc));
            }
            else {
                overridesCustomDocs.push(this.legacyMigration(customDoc));
                overridenModuleDocs.push(customDoc.template);
            }
        }
        // Merge the core and custom collections
        overridesCustomDocs = overridesCustomDocs.map((customDoc) => {
            // Migration of the module document if needed
            const moduleDoc = this.legacyMigration(coreCollection[customDoc.template]);
            return this.merge(moduleDoc, customDoc);
        });
        // Keep the module documents that are not overriden
        const moduleDocs = Object.values(coreCollection).filter((moduleDoc) => {
            return !overridenModuleDocs.includes(moduleDoc.id);
        });
        const items = addedCustomDocs.concat(overridesCustomDocs.map(customDoc => ({
            ...customDoc,
            client_id: this.clientId,
            template: customDoc.id,
        })), 
        // Add the client_id to the core documents
        moduleDocs.map(moduleDoc => ({
            ...this.legacyMigration(moduleDoc),
            client_id: this.clientId,
        })));
        return cloneDeep(items);
    }
    /**
     * Get a document by templateId
     * @param templateId The template id
     * @returns The document or null if not found
     */
    async getByTemplateId(templateId) {
        if (isTemplateDocumentId(templateId)) {
            const result = await this.dbHelper.getAllDataFromCollectionWithWhereArray(this.collection, {
                template: templateId,
                client_id: this.clientId,
            });
            if (result.length === 0)
                return null;
            return this.legacyMigration(result[0]);
        }
        return null;
    }
    /**
     * Get a document by Id
     * @param docId A firestore id and/or a template id (string)
     * @returns Document or null if not found
     */
    async get(docId) {
        const isTemplate = isTemplateDocumentId(docId);
        if (!isTemplate) {
            const result = await this.dbHelper.getDocFromCollection(this.collection, docId);
            if (isFirestoreDocError(result)) {
                console.error(`Document ${docId} does not exist in collection ${this.collection}`);
                return null;
            }
            return result;
        }
        // we have a template document id
        let templateDoc = null;
        // Check if the docId exists in the CORE
        templateDoc = getCoreTemplateDoc(this.collection, docId);
        if (!templateDoc) {
            console.error(`Template document ${docId} does not exist in core collection ${this.collection}`);
            return null;
        }
        // Add the client_id to the core document
        templateDoc = { ...templateDoc, client_id: this.clientId };
        const overridenTemplateDoc = await this.dbHelper.getDocFromCollectionWithWhere(this.collection, {
            template: docId,
            client_id: this.clientId,
        });
        if (isFirestoreDocError(overridenTemplateDoc) || !overridenTemplateDoc)
            return this.legacyMigration(templateDoc);
        return this.legacyMigration(this.merge(templateDoc, overridenTemplateDoc));
    }
    /**
     * Create a new document
     * @param input Input data to create the document
     * @returns Created document
     */
    async create(input) {
        const clientId = input.client_id ?? this.clientId;
        if (isUndefined(clientId))
            throw new Error("client_id is required");
        const now = new Date().toISOString();
        const doc = {
            ...input,
            client_id: clientId,
            created_at: now,
            updated_at: now,
        };
        const result = await this.dbHelper.addDataToCollection(this.collection, doc);
        if (isFirestoreDocError(result))
            throw new Error(`Could not create doc ${JSON.stringify(doc)}`);
        return {
            ...doc,
            id: result.id,
        };
    }
    /**
     * Update a document
     * If the docId is a module id, it will create a new override document and return it
     * @param docId A firestore id or a module id (string)
     * @param input Document to update
     * @returns The data that was updated or the new override document
     */
    async update(docId, input) {
        if (isUndefined(docId))
            throw new Error("Doc Id is required");
        if (!isTemplateDocumentId(docId)) {
            // Update the document
            const newDoc = {
                ...input,
                updated_at: new Date().toISOString(),
            };
            const result = await this.dbHelper.updateDataToCollection(this.collection, docId, newDoc);
            if (isFirestoreDocError(result))
                throw new Error(`Could not update doc ${docId} : ${String(result)}`);
            return result;
        }
        // this is a template
        const templateDoc = getCoreTemplateDoc(this.collection, docId);
        if (!templateDoc)
            throw new Error(`Document ${docId} does not exist in core modules`);
        if (isUndefined(input.template)) {
            // template field is set from the firebase costom override
            // there is no template field defined so we create a new custom override document
            const diffInput = this.diff(templateDoc, input);
            // We add the template id to the custom override document to check next time if we have an
            // update of the core override
            diffInput.template = docId;
            // We create a new custom document
            return this.create(diffInput);
        }
        // Set the calculated diff between the module document and the input document as the new input
        const newDoc = { ...this.diff(templateDoc, input), updated_at: new Date().toISOString(), template: docId };
        // get the custom override document
        const oldDoc = await this.dbHelper.getAllDataFromCollectionWithWhereArray(this.collection, {
            template: docId,
            client_id: this.clientId,
        });
        if (!oldDoc.length)
            throw new Error(`Custom document ${docId} does not exist in collection ${this.collection}`);
        // Update the document
        const result = await this.dbHelper.updateDataToCollection(this.collection, oldDoc[0].id, newDoc);
        if (isFirestoreDocError(result))
            throw new Error(`Could not update doc ${docId} : ${String(result)}`);
        return newDoc;
    }
    /**
     * Delete a document
     * @param docId A firestore id or a module id (string)
     */
    async delete(docId) {
        if (isUndefined(docId))
            throw new Error("Doc Id is required");
        if (isTemplateDocumentId(docId))
            throw new Error("Cannot delete a module document");
        const result = await this.dbHelper.deleteData(this.collection, docId);
        if (isFirestoreDocError(result))
            throw new Error(`Could not delete doc ${docId}`);
        return true;
    }
    /**
     * Process the diff between a module document and a custom document
     * @param moduleDoc The module document
     * @param input The custom document
     * @returns The diff between the two documents
     *
     * @abstract
     */
    diff(_moduleDoc, _input) {
        throw new Error("Diff method not implemented.");
    }
    /**
     * Merge a module document with a custom document
     * @param moduleDoc The module document
     * @param customDoc The custom document
     * @returns The merged document
     *
     * @abstract
     */
    merge(_moduleDoc, _customDoc) {
        throw new Error("Method not implemented.");
    }
    /**
     * Migrate a legacy document if needed
     * @param doc The document to migrate
     * @returns The migrated document
     */
    legacyMigration(doc) {
        return doc;
    }
    /**
     * Allow to add orederd elements in an array of the core document
     * @param coreDoc The module document
     * @param input The custom document
     * @param arrayKey The key in the core document that contains the array to be modified
     * @param id_key The key in the array objects that is used to link the objects
     * @returns The modified core document
     */
    allowAddElementInArray(baseCoreDoc, baseInput, arrayKey, baseIdKey = null) {
        const idKey = baseIdKey ?? "id";
        let coreDoc = cloneDeep(baseCoreDoc);
        let input = cloneDeep(baseInput);
        if (isNull(baseIdKey)) {
            coreDoc = addIdKeyInArray(cloneDeep(coreDoc), arrayKey);
            input = addIdKeyInArray(cloneDeep(input), arrayKey);
        }
        if (!coreDoc || !coreDoc[arrayKey] || !input || !input[arrayKey])
            return input;
        const moduleFields = cloneDeep(coreDoc[arrayKey]) || [];
        const inputFields = cloneDeep(input[arrayKey]) || [];
        const validInput = {};
        const tracker = createCustomTrackerLinkedList(moduleFields, inputFields, idKey);
        if (tracker.length > 0) {
            validInput[arrayKey] = inputFields.reduce((acc, field) => {
                const node = tracker.find(node => node[idKey] === field[idKey]);
                if (node)
                    acc.push({ ...field, prev: node.prev, next: node.next, is_custom: true });
                return acc;
            }, []);
        }
        else {
            validInput[arrayKey] = [];
        }
        return validInput;
    }
    mergeElementInArray(coreDoc, customDoc, arrayKey, baseIdKey = null) {
        if (!coreDoc || !coreDoc[arrayKey])
            return customDoc;
        const idKey = baseIdKey ?? "id";
        if (isNull(baseIdKey)) {
            coreDoc = {
                ...coreDoc,
                [arrayKey]: coreDoc[arrayKey].map((item) => ({ id: item })),
            };
        }
        let mergedData = cloneDeep(coreDoc);
        // merge added fields from customDoc into moduleDoc
        const moduleFields = coreDoc[arrayKey] || [];
        const customFields = customDoc[arrayKey] || [];
        mergedData[arrayKey] = buildListFromCoreAndCustom(moduleFields, customFields, idKey);
        mergedData.template = coreDoc.id;
        if (isNull(baseIdKey))
            mergedData = flattenIdKeyInArray(mergedData, arrayKey);
        mergedData = {
            ...mergedData,
            // store the original module array to compare it later with the custom array
            [`module_${String(arrayKey)}`]: coreDoc[arrayKey],
        };
        return mergedData;
    }
    /**
     * Get the available modules for the client
     * @returns List of modules
     */
    async loadAvailableModules() {
        if (!isUndefined(this.modules))
            return;
        if (!isUndefined(this.clientId)) {
            const client = await this.dbHelper.getDocFromCollection("clients", this.clientId);
            if (!isUndefined(client) && !isFirestoreDocError(client)) {
                this.modules = client.feature_flags?.modules ?? {};
                return;
            }
        }
        this.modules = {};
    }
}
