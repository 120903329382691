import { cloneDeep, has, isEqual, isUndefined } from "lodash-es";
import { isTemplateDocumentId } from "../helpers/templatesHelper.js";
import { CollectionService } from "./CollectionService.js";
/**
 * View Service
 */
export class ViewService extends CollectionService {
    constructor(dbHelper, clientId) {
        super(dbHelper, "views", clientId);
    }
    /**
     *
     * @param viewId Id of the view to update
     * @param fieldId Id of the field to update
     * @param input Object containing the field data
     * @returns Updated field
     */
    async updateField(viewId, fieldName, fieldValue) {
        const input = {
            [fieldName]: fieldValue,
        };
        if (isTemplateDocumentId(viewId)) {
            // get the custom override document
            const customDoc = await this.dbHelper.getAllDataFromCollectionWithWhereArray(this.collection, {
                template: viewId,
                client_id: this.clientId,
            });
            if (customDoc.length) {
                // this is an update of a custom override document
                // merge partial input with custom doc to avoid losing custom changes
                input.template = viewId;
                const mergedDoc = { ...customDoc[0], ...input };
                return super.update(viewId, mergedDoc);
            }
        }
        return super.update(viewId, input);
    }
    /**
     * Detect if there is a new filter or if a filter has been modified
     * @param inputFilters Filters from the input document
     * @param moduleFilters Filters from the module document
     * @returns True if there is a new filter or if a filter has been modified, false otherwise
     */
    static detectFilterAddOrEdit(inputFilters, moduleFilters) {
        // check filters added, or removed
        if (inputFilters && moduleFilters && inputFilters.length !== moduleFilters?.length)
            return true;
        // check filter modified
        for (const inputFilter of inputFilters || []) {
            const moduleFilter = moduleFilters?.find(filter => filter.id === inputFilter.id);
            if (!moduleFilter)
                // filter creation
                return true;
            if (!isEqual(inputFilter, moduleFilter))
                // core filter modification
                return true;
        }
        return false;
    }
    /**
     * Process the diff between a module document and a custom document
     * @param moduleDoc The module document
     * @param input The custom document
     * @returns The diff between the two documents
     */
    diff(baseModuleDoc, baseInput) {
        const moduleDoc = cloneDeep(baseModuleDoc);
        const input = cloneDeep(baseInput);
        const validInput = { root: { blocks: [] } };
        const moduleRoot = moduleDoc.root;
        const inputRoot = input.root;
        if (!inputRoot || !moduleRoot || !inputRoot.blocks || !moduleRoot.blocks || !has(input, "root") || !has(moduleDoc, "root"))
            return input;
        // allow adding new blocks
        const validInputRoot = this.allowAddElementInArray(moduleRoot, inputRoot, "blocks", "id");
        validInput.root = validInputRoot;
        // allow modify filters
        const inputBlocks = (cloneDeep(inputRoot.blocks) || []);
        const moduleBlocks = (moduleRoot.blocks || []);
        const validInputBlocks = (validInput.root.blocks || []);
        for (const inputBlock of inputBlocks) {
            // check if filters has been modified
            const inputFilters = inputBlock.betterFilters || [];
            const moduleFilters = moduleBlocks.find(block => block.id === inputBlock.id)?.betterFilters || [];
            const newFilters = ViewService.detectFilterAddOrEdit(inputFilters, moduleFilters);
            if (newFilters && !validInputBlocks.find(block => block.id === inputBlock.id))
                validInputBlocks.push({ id: inputBlock.id, betterFilters: [] });
            if (newFilters) {
                const inputIdx = validInputBlocks.findIndex(block => block.id === inputBlock.id);
                validInputBlocks[inputIdx].betterFilters = inputFilters;
            }
        }
        for (const inputBlock of inputBlocks) {
            // allow modify label
            const inputLabel = inputBlock.label;
            const moduleLabel = moduleBlocks.find(block => block.id === inputBlock.id)?.label;
            if (inputLabel && inputLabel !== moduleLabel) {
                const inputIdx = validInputBlocks.findIndex(block => block.id === inputBlock.id);
                if (inputIdx === -1)
                    validInputBlocks.push({ id: inputBlock.id, label: inputLabel });
                else
                    validInputBlocks[inputIdx].label = inputLabel;
            }
            // allow modify hidden
            const inputHidden = inputBlock.hidden;
            const moduleHidden = moduleBlocks.find(block => block.id === inputBlock.id)?.hidden;
            if (inputHidden !== moduleHidden) {
                const inputIdx = validInputBlocks.findIndex(block => block.id === inputBlock.id);
                if (inputIdx === -1)
                    validInputBlocks.push({ id: inputBlock.id, hidden: inputHidden });
                else
                    validInputBlocks[inputIdx].hidden = inputHidden;
            }
        }
        validInput.root.blocks = validInputBlocks;
        return validInput;
    }
    /**
     * Merge a module document with a custom document
     * @param moduleDoc The module document
     * @param customDoc The custom document
     * @returns The merged document
     */
    merge(baseModuleDoc, baseCustomDoc) {
        const moduleDoc = cloneDeep(baseModuleDoc);
        const customDoc = cloneDeep(baseCustomDoc);
        const mergedDoc = cloneDeep(moduleDoc);
        const moduleRoot = moduleDoc.root;
        const customRoot = customDoc.root;
        // allow adding new blocks
        const mergedRoot = this.mergeElementInArray(moduleRoot, customRoot, "blocks", "id");
        // allow modify filters
        mergedDoc.root = mergedRoot;
        if (!has(moduleDoc, "root") || !has(customDoc, "root"))
            return moduleDoc;
        const customBlocks = (customRoot.blocks || []);
        const moduleBlocks = (moduleRoot.blocks || []);
        const mergedBlocks = (mergedRoot.blocks || []);
        if (!customRoot || !moduleRoot)
            return moduleDoc;
        for (const customBlock of customBlocks) {
            if (customBlock.is_custom)
                // skip this block because it is a new block added by user
                continue;
            const moduleBlock = moduleBlocks.find(block => block.id === customBlock.id);
            if (!moduleBlock)
                continue;
            const customFilters = customBlock.betterFilters;
            if (customFilters) {
                const moduleIdx = mergedBlocks.findIndex(block => block.id === moduleBlock?.id);
                mergedBlocks[moduleIdx].betterFilters = customFilters;
            }
        }
        for (const customBlock of customBlocks) {
            // allow modify label
            const inputLabel = customBlock.label;
            const moduleLabel = moduleBlocks.find(block => block.id === customBlock.id)?.label;
            if (!isUndefined(inputLabel) && inputLabel !== moduleLabel) {
                const inputIdx = mergedBlocks.findIndex(block => block.id === customBlock.id);
                mergedBlocks[inputIdx].label = inputLabel;
            }
            // allow modify hidden
            const inputHidden = customBlock.hidden;
            const moduleHidden = moduleBlocks.find(block => block.id === customBlock.id)?.hidden;
            if (!isUndefined(inputHidden) && inputHidden !== moduleHidden) {
                const inputIdx = mergedBlocks.findIndex(block => block.id === customBlock.id);
                mergedBlocks[inputIdx].hidden = inputHidden;
            }
        }
        mergedDoc.template = moduleDoc.id;
        return mergedDoc;
    }
}
export default ViewService;
