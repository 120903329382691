import { CollectionService } from "./CollectionService.js";
const DEFAULT_LIST_OPTIONS = {
    includeDeletedForms: false,
};
/**
 * Service class for managing forms.
 */
export class FormService extends CollectionService {
    FORM_ID_FIELD = "name";
    constructor(dbHelper, clientId) {
        super(dbHelper, "forms", clientId);
    }
    /**
     * List all forms
     * @param options Options to filter the list (optional)
     * @returns List of forms
     */
    async list(options = DEFAULT_LIST_OPTIONS) {
        let forms = await super.list(options);
        // Filter deleted forms
        if (!options.includeDeletedForms)
            forms = forms.filter(f => !f.deleted);
        return forms;
    }
    /**
     * Process the diff between a module document and a custom document
     * @param moduleDoc The module form
     * @param input The custom form
     * @returns The diff between the two documents
     */
    diff(moduleDoc, input) {
        // allow adding fields in the form
        const validInput = this.allowAddElementInArray(moduleDoc, input, "fields", this.FORM_ID_FIELD);
        return validInput;
    }
    /**
     * Merge a module document with a custom document
     * @param moduleDoc The module document
     * @param customDoc The custom document
     * @returns The merged document
     */
    merge(moduleDoc, customDoc) {
        if (!moduleDoc)
            return customDoc;
        // merge added fields
        const mergedData = this.mergeElementInArray(moduleDoc, customDoc, "fields", this.FORM_ID_FIELD);
        mergedData.template = moduleDoc.id;
        return mergedData;
    }
}
export default FormService;
