import qs from "qs"
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios"
import axios, { AxiosHeaders } from "axios"
import { has, pick } from "lodash-es"
import { parseISO } from "date-fns"
import { type DatasetBlock, type DocumentRenderingRequestPayload, type Event, type Form, type Node, type PDFTemplate, VERSION } from "@bonx/common"
import { Annotation, BatchRecorder, ExplicitContext, TraceId, Tracer, jsonEncoder } from "zipkin"
import { HttpLogger } from "zipkin-transport-http"
import { getBackEndURL, getEnv, getZipkinURL } from "../config/index"
import { fb_auth } from "../services/firebase"
import { userStore } from "../stores/user"
import { appendZipkinHeaders } from "./instrumentation"

/**
 * The result of an API call
 *
 * @category ApiHelper
 */
interface ApiResult<T> {
  data: T
  status: "ok" | "ko"
}

interface HeadersParams {
  anonymous?: boolean
  content_type?: string
  shareToken?: string
}

interface CustomQueryRunParams {
  query: string
  params?: Record<string, unknown>
}

interface CustomQueryOpts {
  shareToken?: string | null
}

export interface GetFormOpts {
  shareToken?: string
  primaryItemId?: number
}

interface InstrumentedAxiosRequestOptions {
  /**
   * This hook is called before axios performs the request
   * This is useful to add additional tracing information
   * ex: () => { tracer.recordBinary("some metadata", "some value") }
   */
  preFlightTracingSetupFn?: Function
  /**
   * The RPC method to report to the tracer
   * @example "post /v1/data_connections/oauth/:id"
   */
  rpc?: string
  /**
   * If true, a new rootId will be created instead of a childId
   * This is useful when the request is the first in a chain or when the request is not related to any other request
   * @example The status endpoint
   */
  createRootId?: boolean
}

/**
 * Appends common metadata to a Zipkin tracer
 * @param tracer The Zipkin tracer
 */
export function appendCommonZipkinBinaries(tracer: Tracer) {
  tracer.recordBinary("clientId", userStore().user.client_id)
  tracer.recordBinary("clientName", userStore().user.client_name)
  tracer.recordBinary("userName", `${userStore().user.first_name ?? ""} ${userStore().user.last_name ?? "Unknown"}`)
  tracer.recordBinary("version", VERSION)
  tracer.recordBinary("env", getEnv())
}

interface GetDatasetOptions {
  explain?: boolean
  context?: Record<string, unknown>
  shareToken?: string
}

interface UpsertTokenParams {
  form_id: string
  shared: boolean
}

export const apiConfig = {
  returnRejectedPromiseOnError: true,
  timeout: 60000,
  baseURL: getBackEndURL(),
  paramsSerializer: {
    encode: (params: any) => qs.stringify(params, { indices: false }),
  },
}

export default class ApiHelper {
  apiClient: AxiosInstance
  apiClientPython: any
  apiClientNylas: any
  userTokenId: string
  fb_auth: any
  tracer: Tracer

  constructor() {
    this.apiClient = axios.create(apiConfig)
    this.fb_auth = fb_auth
    this.userTokenId = ""
    this.initZipkin()
  }

  initZipkin() {
    if (getEnv() !== "production") {
      this.tracer = {
        scoped: <V>(callback: () => V) => callback(),
        id: new TraceId({ traceId: "", spanId: "" }),
        local: <V>(_name: string, callback: () => V) => callback(),
        createRootId: () => new TraceId({ traceId: "", spanId: "" }),
        createChildId: () => new TraceId({ traceId: "", spanId: "" }),
        letId: <V>(_traceId: TraceId, callback: () => V) => callback(),
        setId: () => {},
        recordAnnotation: () => {},
        recordMessage: () => {},
        recordServiceName: () => {},
        recordRpc: () => {},
        recordClientAddr: () => {},
        recordServerAddr: () => {},
        recordLocalAddr: () => {},
        recordBinary: () => {},
        writeIdToConsole: () => {},
      }
      return
    }
    const tracer = new Tracer({
      ctxImpl: new ExplicitContext(), // the in-process context
      recorder: new BatchRecorder({
        logger: new HttpLogger({
          endpoint: `${getZipkinURL()}/api/v2/spans`,
          jsonEncoder: jsonEncoder.JSON_V2,
        }),
      }), // batched http recorder
      localServiceName: "app",
    })

    this.tracer = tracer
    this.wrapAxios()
  }

  /**
   * An instrumented version of axios's request() function call
   * @param axiosConfig
   * @param preFlightTracingSetupFn
   * @param opts to override default values reported to the tracer
   * @returns the response of the request
   */
  async iAxiosRequest(
    axiosConfig: AxiosRequestConfig,
    opts: InstrumentedAxiosRequestOptions = {},
  ): Promise<AxiosResponse> {
    const { preFlightTracingSetupFn } = opts
    const parentTraceId = opts.createRootId ? this.tracer.createRootId() : this.tracer.createChildId()

    return this.tracer.scoped(async () => {
      this.tracer.setId(parentTraceId)
      this.tracer.recordServiceName("app")
      this.tracer.recordRpc(opts.rpc ?? `${axiosConfig.method} ${axiosConfig.url}`)
      appendCommonZipkinBinaries(this.tracer)
      this.tracer.recordAnnotation(new Annotation.ConsumerStart())

      // Hook to allow for additional tracing setup
      // Don't let tracing crash the endpoint, if the hook crashes we catch the exception
      if (preFlightTracingSetupFn) {
        try {
          preFlightTracingSetupFn()
        }
        catch (e) {
          console.warn("Error in preFlightTracingSetupFn", e)
        }
      }

      try {
        return await this.apiClient.request({
          ...axiosConfig,
          _zipkinParentTraceId: parentTraceId,
          _zipkinRpcMethod: opts.rpc,
        })
      }
      catch (error) {
        this.tracer.setId(parentTraceId)
        this.tracer.recordBinary("error", String(error))
        throw error
      }
      finally {
        this.tracer.setId(parentTraceId)
        this.tracer.recordAnnotation(new Annotation.ConsumerStop())
      }
    })
  }

  /**
   * Wrap the axios instance to add Zipkin tracing
   * @see https://github.com/openzipkin/zipkin-js/tree/master/packages/zipkin-instrumentation-axiosjs
   */
  wrapAxios() {
    const zipkinRecordRequest = (config: InternalAxiosRequestConfig) => this.tracer.scoped(() => {
      // @ts-expect-error we create a new childId
      const childId = this.tracer.createChildId(config._zipkinParentTraceId ?? this.tracer.createRootId())
      this.tracer.setId(childId)
      this.tracer.recordServiceName("app")
      this.tracer.recordRpc(`${config.method} ${config.url}`)
      this.tracer.recordAnnotation(new Annotation.ClientSend())
      appendCommonZipkinBinaries(this.tracer)
      return appendZipkinHeaders(config, childId)
    })
    const zipkinRecordResponse = (res: AxiosResponse) => this.tracer.scoped(() => {
      // @ts-expect-error we retrieve the traceId from the config
      this.tracer.setId(res.config._zipkinTraceId)
      this.tracer.recordBinary("http.status_code", res.status.toString())
      if (res.status < 200 || res.status > 399)
        this.tracer.recordBinary("error", res.status.toString())

      this.tracer.recordAnnotation(new Annotation.ClientRecv())
      return res
    })
    const zipkinRecordError = (error: any) => this.tracer.scoped(() => {
      if (error.config) {
        const { _zipkinTraceId } = error.config
        this.tracer.setId(_zipkinTraceId)
        if (error.response)
          this.tracer.recordBinary("error", error.response.status.toString())
        else
          this.tracer.recordBinary("error", error)
      } // otherwise the error preceded the request interceptor
      return Promise.reject(error)
    })
    this.apiClient.interceptors.request.use(zipkinRecordRequest, zipkinRecordError)
    this.apiClient.interceptors.response.use(zipkinRecordResponse, zipkinRecordError)
  }

  getZipkinTracer() {
    return this.tracer
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId
  }

  async getToken() {
    const user: any = await this.fb_auth?.currentUser
    const token: string = await user?.getIdToken()
    this.setUserTokenId(token)
  }

  /**
   * Construct the headers to perform the query
   *
   * The shareToken is a single-use token that can be used to perform queries
   * as an anonymous user (for instance : to push a form shared with a final customer over email)
   *
   * anonymous mode can also be enabled to perform un-authenticated queries - that's rare but a couple of endpoints are OK with this
   * (and that's ok from a security endpoint, such as the ping endpoint)
   * @param opts
   *
   * @category ApiHelper
   */
  async getHeaders(opts: HeadersParams = {}): Promise<AxiosRequestConfig> {
    const defaults = {
      anonymous: false,
      content_type: "application/json",
      shareToken: "",
    }

    opts = { ...defaults, ...opts }

    const headers = new AxiosHeaders({
      "Cache-Control": "no-cache, no-store, must-revalidate",
      "Pragma": "no-cache",
      "Content-Type": opts.content_type,
      "Accept": "application/json",
    })

    if (opts.shareToken) {
      headers["x-share-token"] = opts.shareToken
      opts.anonymous = true
    }

    if (!opts.anonymous) {
      await this.getToken()
      if (!this.userTokenId)
        throw new Error("Missing user token")

      headers.set("Authorization", `Bearer ${this.userTokenId}`)
    }

    return { headers }
  }

  async getStatus() {
    const cfg = await this.getHeaders({ anonymous: true })
    const resultAxios = await this.iAxiosRequest({
      url: "/v1/status",
      method: "get",
      ...cfg,
    }, {
      createRootId: true,
      rpc: "getStatus",
    })
    return resultAxios?.data
  }

  async getUsers() {
    const resultAxios: any = await this.apiClient.get("/v1/users", await this.getHeaders())
    return resultAxios?.data
  }

  async getUser(id: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/user/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async postUser(id: string, data: any) {
    const resultAxios: any = await this.apiClient.post(`/v1/user/${id}`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async deleteFile(id: string) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("fileId", id)
    }
    const resultAxios = await this.iAxiosRequest({
      url: `/v1/files/${id}`,
      method: "delete",
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "deleteFile",
    })
    return resultAxios?.data
  }

  async getSignedUrl(id: string, shareToken = "") {
    const headers = await this.getHeaders({ shareToken })
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("fileId", id)
    }
    const resultAxios = await this.iAxiosRequest({
      url: `/v1/files/${id}?signed_url=1`,
      method: "post",
      ...headers,
    }, {
      preFlightTracingSetupFn,
      rpc: "getSignedUrl",
    })
    return resultAxios?.data?.signed_url
  }

  /**
   * Obtains a file download URL from the backend using a file_attachmentId
   * @param id
   * @param opts
   * @returns
   */
  async getFileDownloadUrl(id: string, opts: GetFileParams = {}) {
    const shareToken = opts.shareToken ?? ""
    const cfg = await this.getHeaders({ shareToken })
    const resultAxios = await this.iAxiosRequest({
      method: "get",
      url: `/v1/files/details/${id}`,
      ...cfg,
    })
    return resultAxios?.data?.data
  }

  /**
   * Obtains a file download URL from the backend and triggers its download
   * @param id
   * @param opts
   * @returns
   */
  async getFile(id: string, opts: GetFileParams = {}) {
    const shareToken = opts.shareToken ?? ""
    const cfg = await this.getHeaders({ shareToken })
    const resultAxios = await this.iAxiosRequest({
      method: "get",
      url: `/v1/files/${id}`,
      ...cfg,
    }, {
      rpc: "getFile",
    })
    const linkSource = resultAxios?.data?.data
    const fileName = resultAxios?.data?.file_name
    const base64Response = await fetch(linkSource)
    const blob = await base64Response.blob()
    const downloadLink = document.createElement("a")
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = fileName
    document.body.append(downloadLink)
    downloadLink.click()
    downloadLink.remove()
    return resultAxios?.data
  }

  async uploadFile(fileName: string, fileData: any, shareToken = "") {
    const headers = await this.getHeaders({
      anonymous: shareToken !== "",
      content_type: "multipart/form-data",
      shareToken,
    })

    const data = new FormData()
    data.append("file", fileData, fileName)

    const resultAxios = await this.iAxiosRequest({
      url: "/v1/files",
      method: "post",
      data,
      ...headers,
    }, {
      rpc: "uploadFile",
    })

    if (resultAxios.status !== 200)
      throw new Error(resultAxios.statusText)

    return resultAxios.data
  }

  async postGraph(data: any) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("data", JSON.stringify(data ?? {}))
    }
    const resultAxios = await this.iAxiosRequest({
      url: "/v1/graph",
      method: "post",
      data,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "postGraph",
    })
    return resultAxios?.data
  }

  async getGraphData(data: Record<string, any>) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("data", JSON.stringify(data ?? {}))
    }
    const resultAxios = await this.iAxiosRequest({
      url: "/v1/graph/get/",
      method: "post",
      data,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "getGraphData",
    })
    return resultAxios?.data
  }

  async getGraphNodeData(nodeId: number | string, data?: any) {
    const headers = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("nodeId", nodeId)
      if (data)
        this.tracer.recordBinary("data", JSON.stringify(data))
    }
    const resultAxios = await this.iAxiosRequest({
      url: `/v1/graph/get/${nodeId}`,
      method: "post",
      data,
      ...headers,
    }, {
      preFlightTracingSetupFn,
      rpc: "getGraphNodeData",
    })

    // Perform type conversion if there is a schema
    const resultData = resultAxios?.data

    if (resultData?.primary_model?.schema && resultData?.object) {
      for (const field of resultData.primary_model.schema) {
        if (field?.$formkit === "datetime-local" || field?.$formkit === "datetime" || field?.$formkit === "date") {
          if (has(resultData.object.properties, field.name))
            resultData.object.properties[field.name] = parseISO(resultData.object.properties[field.name])
        }
      }
    }

    return resultData
  }

  /**
   * Runs a custom query on the graph
   * TODO : THIS IS A SECURITY FLAW AS QUERIES FROM THE FRONTEND SHOULD NEVER BE EXECUTED
   * WE SHOULD ONLY EXECUTE QUERIES FROM A TRUSTED SOURCE (Our backend, someone from our team, etc.)
   * @param data
   * @param opts
   * @returns
   */
  async testGraphData(data: CustomQueryRunParams, opts: CustomQueryOpts = {}) {
    const cfg = await this.getHeaders({ shareToken: opts.shareToken ?? "" })
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("query", data.query)
      if (data.params)
        this.tracer.recordBinary("params", JSON.stringify(data.params))
    }
    const resultAxios = await this.iAxiosRequest({
      url: "/v1/graph/test/",
      method: "post",
      data,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "testGraphData",
    })
    return resultAxios?.data
  }

  async getGraphDataFromObject(data: any) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("nodeId", data.objectId)
    }
    const resultAxios = await this.iAxiosRequest({
      url: "/v1/graph/object/",
      method: "post",
      data,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "getGraphDataFromObject",
    })
    return resultAxios?.data
  }

  async convertToCSV(data: any) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/convert/csv/",
      data,
      ...cfg,
    })
    return resultAxios?.data
  }

  async saveGraphData(data: any) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/graph/save/",
      data,
      ...cfg,
    }, {
      rpc: "saveGraphData",
    })
    return resultAxios?.data
  }

  async deleteGraphData(data: any) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("nodeId", data.node_id)
    }
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/graph/delete/",
      data,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "deleteGraphData",
    })
    return resultAxios?.data
  }

  // TODO
  async deleteRelation(data: any) {
    const resultAxios: any = await this.apiClient.post("/v1/graph/relation/delete/", data, await this.getHeaders())
    return resultAxios?.data
  }

  async updateGraphData(data: any) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("nodeId", data.data.identity)
    }
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/graph/update/",
      data,
      ...cfg,
    }, { preFlightTracingSetupFn, rpc: "updateGraphData" })
    return resultAxios?.data
  }

  async pushForm(data: any, shareToken = "") {
    const cfg = await this.getHeaders({
      shareToken,
    })
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("formId", data.form_id)
    }
    const resultAxios = await this.iAxiosRequest({
      url: "/v1/graph/form/",
      method: "post",
      data,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "pushForm",
    })
    return resultAxios?.data
  }

  async pullForm(data: any, shareToken = "") {
    const cfg = await this.getHeaders({
      anonymous: shareToken !== "",
      shareToken,
    })
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/graph/form/get/",
      data,
      ...cfg,
    }, {
      rpc: "pullForm",
    })
    return resultAxios?.data
  }

  async launchAutomation(id: string, payload: Record<string, unknown>) {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("automationId", id)
    }
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: `/v1/data_connections/${id}`,
      data: payload,
      ...cfg,
    }, { preFlightTracingSetupFn, rpc: "launchAutomation" })
    return resultAxios?.data
  }

  /**
   * Upserts a form's sharing token
   * Sharing tokens are short-lived (30 days by default) tokens
   * That enable non-Bonx-users to submit Bonx forms in a relatively secure fashion
   */
  async upsertToken(data: UpsertTokenParams) {
    const cfg = await this.getHeaders({ })
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/forms/token/",
      data,
      ...cfg,
    }, { rpc: "upsertToken" })
    return resultAxios?.data
  }

  async upsertFormLabel(data: any) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/forms/labels/",
      data,
      ...cfg,
    }, { rpc: "upsertFormLabel" })
    return resultAxios?.data
  }

  /**
   * Get definition of a given process
   * @param data
   */
  async getProcess(data: any) {
    const resultAxios: any = await this.apiClient.post("/v1/processes/get/", data, await this.getHeaders())
    return resultAxios?.data
  }

  /**
   * Get definition of a given process
   * @param data
   */

  async createProcess(data: any) {
    const resultAxios: any = await this.apiClient.post("/v1/processes/create/", data, await this.getHeaders())
    return resultAxios?.data
  }

  /**
   * Get definition of a given process
   * @param data
   */
  async updateProcess(data: any) {
    const resultAxios: any = await this.apiClient.post("/v1/processes/update/", data, await this.getHeaders())
    return resultAxios?.data
  }

  /**
   * Get all information about a process on a node
   * Past steps, current steps, blockers or available forms & potential future steps
   * @param id Neo4J id of the node
   */
  async getNodeProcessDetails(id: string) {
    const resultAxios: any = await this.apiClient.post(
      `/v1/processes/get/live/object/${id}`,
      {},
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async renderPdf(data: any) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/pdf",
      data,
      ...cfg,
      responseType: "blob",
    }, { rpc: "renderPdf" })
    return resultAxios?.data
  }

  /**
   * Creates a PDF template
   * @param data The payload The document_id
   *
   * @category ApiHelper
   */
  async createPDFTemplate(data: PDFTemplate): Promise<{ document_id: string }> {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/pdf/template",
      data,
      ...cfg,
    }, { rpc: "createPDFTemplate" })
    return resultAxios.data
  }

  /**
   * Exports a PDF template for a given list of objects
   * @param pdf_template_id The template id
   * @param object_ids The list of object ids to export
   * @returns The PDF file as a Blob
   *
   * @category ApiHelper
   */
  async exportPDFTemplate(pdf_template_id: PDFTemplate["id"], object_ids: Array<string>): Promise<Blob> {
    const cfg = await this.getHeaders()
    const data = {
      pdf_template_id,
      object_ids,
      client_id: userStore().user.client_id,
    }
    // TODO Check me
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/pdf/export",
      data,
      ...cfg,
      responseType: "blob",
    }, { rpc: "exportPDFTemplate" })
    return resultAxios?.data
  }

  /**
   * Renders a template with data and returns a PDF
   * @param data
   *
   * @category ApiHelper
   */
  async renderPdfV2(data: DocumentRenderingRequestPayload, shareToken = "") {
    const headers = await this.getHeaders({
      anonymous: shareToken !== "",
      shareToken,
    })
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/pdf/persistent",
      data,
      ...headers,
    }, { rpc: "renderPdfV2" })
    return resultAxios?.data
  }

  /**
   * Renders a template with data and returns a HTML
   * @param data
   *
   * @category ApiHelper
   */
  async renderHtml(data: DocumentRenderingRequestPayload, shareToken = "") {
    const cfg = await this.getHeaders({ shareToken })
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/pdf/html",
      data,
      ...cfg,
    }, { rpc: "renderHtml" })
    return resultAxios?.data
  }

  async oAuthToken(id: string, data: Record<string, string>) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: `/v1/data_connections/oauth/${id}`,
      data,
      ...cfg,
    }, {
      rpc: "oAuthToken",
    })
    return resultAxios?.data
  }

  async integrationOAuthToken(id: string, data: Record<string, string>) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: `/v1/integrations/oauth/${id}`,
      data,
      ...cfg,
    }, { rpc: "integrationOAuthToken" })
    return resultAxios?.data
  }

  /**
   * Performs a request to a connected integration
   * Using credentials know by Bonx
   * @param integrationId
   * @param data
   *
   * @category ApiHelper
   */
  async integrationProxyRequest(integrationId: string, data: any) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: `/v1/integrations/proxy/${integrationId}`,
      data,
      ...cfg,
    }, {
      rpc: "integrationProxyRequest",
    })
    return resultAxios?.data
  }

  async formShareUrl(form_id: string, node_id: string) {
    const data = { form_id, node_id }
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("formId", form_id)
      this.tracer.recordBinary("nodeId", node_id)
    }
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/forms/share/",
      data,
      ...cfg,
    }, { preFlightTracingSetupFn, rpc: "formShareUrl" })
    return resultAxios?.data
  }

  async getEvents(related_object_id: string): Promise<ApiResult<Array<Node<Event>>>> {
    const cfg = await this.getHeaders()
    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("nodeId", related_object_id)
    }
    const resultAxios = await this.iAxiosRequest({
      method: "get",
      url: `/v1/events/${related_object_id}`,
      ...cfg,
    }, {
      preFlightTracingSetupFn,
      rpc: "getEvents",
    })
    return resultAxios?.data
  }

  async messagingSend(data: any) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/messaging/send",
      data,
      ...cfg,
    }, { rpc: "messagingSend" })
    return resultAxios?.data
  }

  async getBlockData(block: DatasetBlock, opts: GetDatasetOptions = {}) {
    const context = opts.context ?? {}
    const explain = opts.explain ?? false
    const shareToken = opts.shareToken ?? ""

    const preFlightTracingSetupFn = () => {
      this.tracer.recordBinary("block", JSON.stringify(block ?? {}))
      this.tracer.recordBinary("explain", explain.toString())
      this.tracer.recordBinary("context", JSON.stringify(context ?? {}))
    }

    const data = {
      ...pick(block, ["dataset", "axis", "filters", "betterFilters", "ordering", "limit", "skip", "aggregation"]),
      context,
    }
    const cfg = await this.getHeaders({
      shareToken,
    })
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: `/v1/dataset${explain ? "?explain=1" : ""}`,
      data,
      ...cfg,
    }, { preFlightTracingSetupFn, rpc: "getBlockData" })
    return resultAxios?.data
  }

  /**
   * Call the backend to manually trigger an automation
   *
   * @param id Automation firestore id
   * @param node_id Neo4j object id
   * @param model_id Firestore id of related model id
   * @returns an object with status ok if automation was successfully triggered
   */
  async manualTriggerAutomation(id: string, node_id: string, model_id: string) {
    const payload = {
      async: true,
      trigger: {
        type: "manual",
        primary_item_id: node_id,
        user_id: userStore().user.id,
        origin: `manual/${model_id}`,
      },
    }
    const resultAxios: any = await this.apiClient.post(`/v1/data_connections/${id}`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  /**
   * Configuration API
   * This API can be called to obtain the configuration of forms, views, etc.
   */
  async getConfigForm(formId: string, opts: GetFormOpts): Promise<{ data: Form }> {
    const shareToken = opts.shareToken ?? ""
    const cfg = await this.getHeaders({ shareToken })
    const resultAxios = await this.iAxiosRequest({
      method: "get",
      url: `/v1/config/form/${formId}`,
      ...cfg,
    })
    return resultAxios?.data
  }

  async createInternalComment(related_object_id: string, comment: string) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: "/v1/events/comment",
      data: {
        related_object_id,
        comment,
      },
      ...cfg,
    }, { rpc: "createInternalComment" })
    return resultAxios?.data
  }

  /**
   * Create an intercom hash for a user
   * @param userId The user id
   * @returns The intercom hash
   */
  async createIntercomHash(userId: string) {
    const cfg = await this.getHeaders()
    const resultAxios = await this.iAxiosRequest({
      method: "post",
      url: `/v1/user/${userId}/intercom`,
      ...cfg,
    }, { rpc: "createIntercomHash" })
    return resultAxios.data.data.hash
  }
}
