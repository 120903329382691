import type { Client, FeatureFlags, UserWithClient } from "@bonx/common"
import { defineStore } from "pinia"
import Intercom from "@intercom/messenger-js-sdk"
import DBHelper from "../helpers/dbHelper/index"
import { getEnv } from "../config"
import { apiStore } from "./api"

export const userStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "user",
  // a function that returns a fresh state
  state: () => ({
    user: {
      first_name: "",
      last_name: "",
      email: "",
      role: "",
      language: "",
      client_name: "",
      entity_name: "",
      id: "",
      picture_url: "",
      client_id: "",
      client: {} as Client,
      client_feature_flags: [] as FeatureFlags,
      created_on: 0,
    } as UserWithClient,
  }),

  // optional getters
  getters: {
    getUser(): UserWithClient {
      return this.user
    },
    getFullName(): string {
      return `${this.user.last_name} ${this.user.first_name}`
    },
  },
  // optional actions
  actions: {
    async loadUser(uid: string) {
      const dbHelper = new DBHelper()
      if (uid) {
        const user: UserWithClient = await dbHelper.getDocFromCollection("users", uid)
        const client: Client = await dbHelper.getDocFromCollection("clients", user?.client_id)
        localStorage.setItem("currentUser", JSON.stringify(user))
        if (client) {
          user.client = client
          user.client_name = client.name ?? ""
          user.client_feature_flags = client.feature_flags ?? [] as FeatureFlags
        }

        if (!user?.picture_url)
          user.picture_url = "/assets/anonymous-avatar-icon-25.webp"

        user.language = navigator.language.substring(0, 2)
        this.user = user

        // Following integrations are only deployed on production
        if (getEnv() !== "production")
          return

        // -------------------------------
        // BNX-1774 : Intercom integration
        // -------------------------------

        // Check to see if we have the user hash, if not, we need to create it
        if (!user.intercom_user_hash) {
          try {
            user.intercom_user_hash = await apiStore().getApiClient.createIntercomHash(user.id)
          }
          catch (error) {
            console.error("Error creating Intercom hash", error)
          }
        }

        // Initialize Intercom
        Intercom({
          api_base: "https://api-iam.intercom.io",
          app_id: "soo5uisb",
          user_id: user.id,
          user_hash: user.intercom_user_hash,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          created_at: user.created_on,
          avatar: user.picture_url,
          hide_default_launcher: window.innerWidth <= 1280,
          custom_launcher_selector: "#intercom-launcher-button",
          company: {
            id: user.client_id,
            name: user.client_name,
          },
        })

        // -------------------------------
        // BNX-1781 : Fullview integration
        // -------------------------------

        window.$fvIdentity = {
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          disableReplaysForUser: false,
          env: user.client_id,
          roles: [user.role],
          client_id: user.client_id,
          client_name: user.client_name,
          created_at: user.created_on,
        }
      }
    },
  },
})
