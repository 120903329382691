import { cloneDeep, isUndefined } from "lodash-es";
import { CollectionService } from "./CollectionService.js";
/**
 * Model Service
 * Manages CRUD operations for models
 */
export class ModelService extends CollectionService {
    MODEL_ID_FIELD = "name";
    constructor(dbHelper, clientId) {
        super(dbHelper, "settings_models", clientId);
    }
    /**
     * List all models
     * @param options Options to filter the list (optional)
     * @returns List of models
     */
    async list(options = {}) {
        let models = await super.list(options);
        // Filter deleted models
        if (!options?.includeDeletedModels)
            models = models.filter(m => !m.deletedAt);
        // Filter hidden and deleted attributes
        if (options?.excludeHiddenAttributes || options?.excludeDeletedAttributes) {
            for (let i = 0; i < models.length; i++) // skipcq: JS-0361
                models[i].schema = ModelService.filterSchema(models[i].schema, options);
        }
        // See BNX-2023
        // The QueryHelper does stateful manipulation of model.schema
        // Which leads to increasingly large generated queries over time and an overall performance degradation
        // To fix it temporarily, we return here a deep clone of the models
        return cloneDeep(models);
    }
    /**
     * Get a model by Id
     * @param modelId A firestore id a the module id (string)
     * @param options Options to filter the model (optional)
     * @returns Model
     */
    async get(modelId, options) {
        const model = await super.get(modelId);
        if (!model)
            return null;
        // Filter hidden and deleted attributes from the schema if needed
        model.schema = ModelService.filterSchema(model.schema, options);
        // See BNX-2023
        // See comment above in list() method
        return cloneDeep(model);
    }
    /**
     * Process the diff between a template document and a custom document
     * @param templateDoc The template document
     * @param input The custom document
     * @returns The diff between the two documents
     */
    diff(templateDoc, input) {
        // allow add schema fields in the model
        const validInput = this.allowAddElementInArray(templateDoc, input, "schema", this.MODEL_ID_FIELD);
        // BNX-2067 : We need to keep the name of the template document (used in getGraphNodeData)
        validInput.name = templateDoc.name;
        // DEP-2018: keep misc field
        if (!isUndefined(input.misc))
            validInput.misc = input.misc;
        return validInput;
    }
    /**
     * Merge a template document with a custom document
     * @param templateDoc The module document
     * @param customDoc The custom document
     * @returns The merged document
     */
    merge(templateDoc, customDoc) {
        if (!templateDoc)
            return customDoc;
        const mergedData = this.mergeElementInArray(templateDoc, customDoc, "schema", this.MODEL_ID_FIELD);
        mergedData.template = templateDoc.id;
        // DEP-2018: keep misc field
        if (!isUndefined(customDoc.misc))
            mergedData.misc = customDoc.misc;
        return mergedData;
    }
    /**
     * Filter the attributes of a model schema
     * @param schema The model schema to filter
     * @param options Options to filter the schema
     * @returns Filtered model schema
     */
    static filterSchema(schema, options) {
        if (!options || (!options?.excludeDeletedAttributes && !options?.excludeHiddenAttributes))
            return schema;
        return schema.filter(s => (!options.excludeHiddenAttributes || !s.hidden) && (!options.excludeDeletedAttributes || !s.deleted));
    }
}
