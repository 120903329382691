import { Field, Model, ModelInput, ModelService, Relation, RelationField, RelationInput, RelationService, isOverrideDocument } from "@bonx/common"
import { defineStore } from "pinia"
import { cloneDeep, omit } from "lodash-es"
import DBHelper from "../helpers/dbHelper/index"
import { userStore } from "./user"
import { globalStore } from "./global"

const dbHelper = new DBHelper()
export interface KVOption {
  label: string
  value: string
}

export const modelsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "models",
  // a function that returns a fresh state
  state: () => ({
    models: {} as Record<string, Model>,
    relations: {} as Record<string, Relation>,
    _modelService: null as ModelService | null,
    _relationService: null as RelationService | null,
  }),
  // optional getters
  getters: {
    model: state => (id: string, clone = false) => clone ? cloneDeep(state.models[id]) : state.models[id],
    relation: state => (id: string, clone = false) => clone ? cloneDeep(state.relations[id]) : state.relations[id],
    modelsList: state => (): Array<Model> => Object.values(state.models),
    relationsList: state => (): Array<Relation> => Object.values(state.relations),
    getModelService: state => () => {
      if (!state._modelService)
        state._modelService = new ModelService(dbHelper, userStore().user.client_id)
      return state._modelService
    },
    getRelationService: state => () => {
      if (!state._relationService)
        state._relationService = new RelationService(dbHelper, userStore().user.client_id)
      return state._relationService
    },
  },
  // optional actions
  actions: {
    async loadModels() {
      const user = userStore().user
      if (!user?.client_id)
        throw new Error(`Could not find user: ${JSON.stringify(user)}`)

      const [models, relations] = await Promise.all([
        this.getModelService().list({
          excludeDeletedAttributes: true,
        }),
        this.getRelationService().list(),
      ])
      this.models = {}
      this.relations = {}
      for (const model of models)
        this.models[model.id] = model

      for (const relation of relations)
        this.relations[relation.id] = relation
    },

    /**
     * Fetches model from raw DB instead of store cache
     * @param modelId
     */
    async getById(modelId: string) {
      return this.getModelService().get(modelId, { excludeHiddenAttributes: false, excludeDeletedAttributes: true })
    },

    async loadSelectedClientModels(selectedClientId: string) {
      if (!selectedClientId)
        return

      const [models, relations] = await Promise.all([
        this.getModelService().list(),
        this.getRelationService().list(),
      ])

      for (const model of models) {
        if (!this.models[model.id])
          this.models[model.id] = model
      }
      for (const relation of relations) {
        if (!this.relations[relation.id])
          this.relations[relation.id] = relation
      }
    },

    async clearStoreAndLoadSelectedClientModels(selectedClientId: string) {
      if (selectedClientId) {
        const models = await this.getModelService().list()
        for (const model of models)
          this.models[model.id] = model
      }
    },

    async createModel(model: ModelInput) {
      const user = userStore().user
      if (!user?.client_id)
        throw new Error(`User has no client_id ${JSON.stringify(user)}`)

      const created = await this.getModelService().create(model)
      this.models[created.id] = created
      await globalStore().updateStoreCache()
      return created.id
    },

    async updateModel(model_id: string, model: Partial<ModelInput>) {
      const user = userStore().user
      if (!user)
        throw new Error("Could not find user")

      // Make sure we don't send any fields that are not allowed
      const input = omit(model, ["id", "created_at", "updated_at"]) as ModelInput
      const doc = await this.getModelService().update(model_id, input)
      await this.loadModels()
      await globalStore().updateStoreCache()
      return {
        id: doc.id,
        isNewOverride: !isOverrideDocument({ ...model, id: model_id }) && isOverrideDocument(doc),
      }
    },

    async deleteModel(model_id: string, soft = true) {
      if (!model_id)
        throw new Error("Model id is required")

      const user = userStore().user

      if (!user || !user?.client_id)
        throw new Error(`Could not find user: ${String(user)}`)

      const model = this.model(model_id)

      if (!model)
        throw new Error(`Model ${model_id} not found`)

      // Solf delete
      if (soft)
        return this.updateModel(model_id, { deletedAt: new Date().toISOString() })

      // Hard delete
      await this.getModelService().delete(model_id)
      await globalStore().updateStoreCache()
    },

    async getDeletedFields(model_id: string) {
      const user = userStore().user
      if (!user?.client_id)
        throw new Error("Could not find user")

      const model = await this.getModelService().get(model_id)
      return model.schema.filter(f => f.deleted)
    },

    async createModelField(model_id: string, field: Field) {
      if (!model_id)
        throw new Error("Model id is required")

      const model = this.model(model_id, true)
      if (!model)
        throw new Error(`Model ${model_id} not found in ${JSON.stringify(this.modelsList())}`)

      if (!model.schema)
        console.warn(`Model ${model_id} has no schema, ${JSON.stringify(model)}`)

      // Set creation date
      field.creation_date = new Date().toISOString()

      // Keep deleted fields in the schema (they are filtered out)
      const deletedFields = await this.getDeletedFields(model_id)
      model.schema = [
        ...model.schema,
        ...deletedFields,
        field,
      ]

      return this.updateModel(model.id, model)
    },

    async updateModelField(model_id: string, field: Field) {
      const model = this.model(model_id, true)
      if (!model)
        throw new Error(`Model ${model_id} not found in ${JSON.stringify(this.modelsList())}`)
      // Keep deleted fields in the schema (they are filtered out)
      const deletedFields = await this.getDeletedFields(model_id)
      model.schema = model.schema.concat(deletedFields)
      const index = model.schema.findIndex(f => f.name === field.name)
      model.schema[index] = field
      return this.updateModel(model.id, model)
    },

    async deleteModelField(model_id: string, field_name: string) {
      const model = this.model(model_id, true)
      if (!model)
        throw new Error(`Model ${model_id} not found`)
      const deletedFields = await this.getDeletedFields(model_id)
      model.schema = model.schema.concat(deletedFields)
      const index = model.schema.findIndex((f: Field) => f.name === field_name)
      model.schema.splice(index, 1)
      return this.updateModel(model.id, model)
    },

    getModelRelatedModelsMaxCardinality(modelId: Model["id"]): any {
      const relatedModelIds = new Set<Model["id"]>()
      const checkedModelIds = new Set<Model["id"]>()

      relatedModelIds.add(modelId)

      const getRelatedModelIds = (modelId: string) => {
        const relations = this.getModelRelations(modelId)
        relations.forEach((r) => {
          if (r.object_id === modelId)
            relatedModelIds.add(r.to_object_id)
          else
            relatedModelIds.add(r.object_id)
        })
      }

      for (const relatedModelId of relatedModelIds) {
        if (!checkedModelIds.has(relatedModelId)) {
          getRelatedModelIds(relatedModelId)
          checkedModelIds.add(relatedModelId)
        }
      }

      return relatedModelIds
    },

    getModelRelations(modelId: string): Array<Relation> {
      return this.relationsList().filter((r: any) => r.object_id === modelId || r.to_object_id === modelId)
    },

    getRelationOtherModel(relationId: string, modelId: string): Model | undefined {
      const relation = this.relation(relationId)

      if (relation) {
        const otherModelId = relation.object_id === modelId ? relation.to_object_id : relation.object_id
        return this.model(otherModelId)
      }
    },

    async createRelation(input: RelationInput) {
      const user = userStore().user
      if (!user)
        throw new Error("Could not find user")

      const created = await this.getRelationService().create(input)
      this.relations[created.id] = created
      await globalStore().updateStoreCache()
      return created.id
    },

    async updateRelation(relation_id: string, relation: Relation) {
      const user = userStore().user
      if (!user)
        throw new Error("Could not find user")

      await this.getRelationService().update(relation_id, relation)
      this.relations[relation_id] = relation
      await globalStore().updateStoreCache()
    },

    async getRelationFromMirrorColumn(modelId: string, columnName: string) {
      const baseModel = this.model(modelId, true)

      // Search mirror column in the schema
      const relationMirrorColumn = baseModel.schema.find(c => c.name === columnName && c.deleted !== true) as RelationField
      if (!relationMirrorColumn)
        throw new Error(`Mirror column ${columnName} not found in ${modelId}`)

      // Get relation
      const relation = this.relation(relationMirrorColumn.misc.relation_id)

      if (!relation)
        throw new Error(`No relation found for ${modelId} > ${columnName}`)

      const direction = relation.object_id === baseModel.id ? "right" : "left"
      return [relation, direction]
    },
  },
})
