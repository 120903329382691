import { generateClasses } from "@formkit/tailwindcss"
import { de, fr } from "@formkit/i18n"
import * as carbonIcons from "@iconify-json/carbon"
import { applicationIcons } from "@formkit/icons"
import type { UserModule } from "../types"
import { getEnv } from "../config"
import { formkitTheme } from "./formkit-theme"

const formatedCarbonIcons = Object.keys(carbonIcons.icons.icons).reduce((acc: any, key: any) => {
  acc[`carbon-${key}`] = `<svg viewBox='0 0 32 32'>${carbonIcons.icons.icons[key].body}</svg>`
  return acc
}, {})

function getNavigatorLanguage() {
  let language = "en"
  if (typeof window !== "undefined") {
    language = navigator.language.substring(0, 2)
    if (language !== "de" && language !== "fr")
      language = "en"
  }
  return language
}

export const install: UserModule = async ({ app }) => {
  // FormKit imports should be done asynchronously to ensure they are loaded after their dependencies
  const { defaultConfig, plugin } = await import("@formkit/vue")
  const { createProPlugin, inputs } = await import("@formkit/pro")

  const pro
      = getEnv() === "production" ? createProPlugin("fk-6100abc1a1", inputs) : createProPlugin("fk-a423e3511e", inputs)

  // Configure FormKit:
  const config = defaultConfig({
    config: {
      classes: generateClasses(formkitTheme),
    },
    icons: {
      ...applicationIcons,
      ...formatedCarbonIcons,
    },
    locales: { de, fr },
    locale: getNavigatorLanguage(),
    plugins: [pro],
  })

  app.use(plugin, config)
}
