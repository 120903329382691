import { CollectionService } from "./CollectionService.js";
import { ModelService } from "./ModelService.js";
/**
 * Relation Service Class
 */
export class RelationService extends CollectionService {
    modelService;
    constructor(dbHelper, clientId) {
        super(dbHelper, "settings_models_relations", clientId);
        this.modelService = new ModelService(dbHelper, clientId);
    }
    /**
     * List all relations
     * @param modelId Filter by modelId, return all relations to/from the model
     * @returns List of relations
     */
    async list(options = {}) {
        let relations = await super.list(options);
        // Filter by modelId, return all relations to/from the model
        if (options.modelId)
            relations = relations.filter((r) => r.object_id === options.modelId || r.to_object_id === options.modelId);
        // Return all relations with object_name and to_object_name
        if (relations.length > 0) {
            const models = await this.modelService.list();
            relations = relations.filter(r => models.find(m => m.id === r.to_object_id) && models.find((m) => m.id === r.object_id));
            for (let i = 0; i < relations.length; i++) {
                const fromObject = models.find(m => m.id === relations[i].object_id);
                const toObject = models.find(m => m.id === relations[i].to_object_id);
                relations[i].object_name = fromObject?.name;
                relations[i].to_object_name = toObject?.name;
                relations[i].object_displayed_name = fromObject?.displayed_name ?? fromObject?.name;
                relations[i].to_object_displayed_name = toObject?.displayed_name ?? toObject?.name;
            }
        }
        if (options.include_related_model_settings) {
            if (!options.modelId)
                throw new Error("Missing a required argument to include related model : Model Id");
            for (const relation of relations) {
                const relatedModelId = relation.object_id === options.modelId ? relation.to_object_id : relation.object_id;
                const relatedModel = await this.modelService.get(relatedModelId);
                if (!relatedModel)
                    throw new Error(`Could not find related model ${relatedModelId}`);
                relation.related_model_settings = relatedModel;
            }
        }
        return relations;
    }
    /**
     * For a given relation and start model, return the opposite model's config
     */
    async getOppositeModelConfig(relationId, modelId) {
        if (!relationId)
            throw new Error("relationId is required");
        const relation = await this.get(relationId);
        if (!relation)
            throw new Error(`Could not find relation ${relationId}`);
        let relatedModel;
        if (modelId !== relation.object_id && modelId !== relation.to_object_id)
            throw new Error(`Model ${modelId} is not part of relation ${relationId}`);
        if (modelId === relation.object_id)
            relatedModel = await this.modelService.get(relation.to_object_id);
        else if (modelId === relation.to_object_id)
            relatedModel = await this.modelService.get(relation.object_id);
        if (!relatedModel)
            throw new Error(`Could not find related model ${relation.to_object_id}`);
        return relatedModel;
    }
}
export default RelationService;
