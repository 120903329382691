import Handlebars from "handlebars";
import { add, isValid, parseISO, sub } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { getSVG } from "../lib/qr-image/svg.js";
Handlebars.registerHelper("strAppend", (str, suffix) => {
    if (typeof str === "string" && typeof suffix !== "object")
        return `${str}${suffix}`;
    return str;
});
Handlebars.registerHelper("split", (str, separator, returnIndex = -1) => {
    if (!str)
        return "";
    const arr = str.split(separator);
    return typeof returnIndex === "number" && returnIndex !== -1
        ? arr[returnIndex]
        : arr;
});
Handlebars.registerHelper("dateAdd", (date, options) => {
    try {
        const parsedDate = isValid(date) ? date : parseISO(date);
        if (isValid(parsedDate))
            return add(parsedDate, options.hash);
    }
    catch (e) {
        console.error(e);
    }
    return date;
});
Handlebars.registerHelper("dateSub", (date, options) => {
    try {
        const parsedDate = isValid(date) ? date : parseISO(date);
        if (isValid(parsedDate))
            return sub(parsedDate, options.hash);
    }
    catch (e) {
        console.error(e);
    }
    return date;
});
Handlebars.registerHelper("dateFormat", (date, outputFormat) => {
    try {
        if (date === "now")
            date = new Date();
        let parsedDate = date;
        if (!isValid(date))
            parsedDate = parseISO(date);
        if (isValid(parsedDate))
            return formatInTimeZone(parsedDate, "Europe/Paris", outputFormat);
    }
    catch (e) {
        console.error(e);
    }
    return date;
});
Handlebars.registerHelper("qrcode", (str) => {
    const svgStr = getSVG(str);
    const encoded = encodeURIComponent(svgStr)
        .replace(/'/g, "%27")
        .replace(/"/g, "%22");
    return new Handlebars.SafeString(`<img class="qrcode" src="data:image/svg+xml,${encoded}" />`);
});
Handlebars.registerHelper("if", function (v1, operator, v2, options) {
    switch (operator) {
        case "==":
            // eslint-disable-next-line eqeqeq
            return v1 == v2 ? options.fn(this) : options.inverse(this);
        case "===":
            return v1 === v2 ? options.fn(this) : options.inverse(this);
        case "!=":
            // eslint-disable-next-line eqeqeq
            return v1 != v2 ? options.fn(this) : options.inverse(this);
        case "!==":
            return v1 !== v2 ? options.fn(this) : options.inverse(this);
        case "<":
            return v1 < v2 ? options.fn(this) : options.inverse(this);
        case "<=":
            return v1 <= v2 ? options.fn(this) : options.inverse(this);
        case ">":
            return v1 > v2 ? options.fn(this) : options.inverse(this);
        case ">=":
            return v1 >= v2 ? options.fn(this) : options.inverse(this);
        case "&&":
            return v1 && v2 ? options.fn(this) : options.inverse(this);
        case "||":
            return v1 || v2 ? options.fn(this) : options.inverse(this);
        case "IN":
            return Array.isArray(v2) && v2.includes(v1) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
});
Handlebars.registerHelper("nl2br", (text) => {
    text = Handlebars.Utils.escapeExpression(text);
    text = text.replace(/(\r\n|\n|\r)/gm, "<br />");
    return new Handlebars.SafeString(text);
});
Handlebars.registerHelper("numberFormat", (value, options) => {
    // Helper parameters
    const dl = options.hash.decimalLength || 2;
    const ts = options.hash.thousandsSep || " ";
    const ds = options.hash.decimalSep || ",";
    const mb = options.hash.moneySymbolBefore || "";
    const ma = options.hash.moneySymbolAfter || "";
    // Parse to float
    value = Number.parseFloat(value);
    if (Number.isNaN(value))
        return "";
    // The regex
    const re = new RegExp(`\\d(?=(\\d{3})+${dl > 0 ? "\\D" : "$"})`, "g");
    // Formats the number with the decimals
    const num = value.toFixed(Math.max(0, ~~dl));
    // Returns the formatted number
    return ((mb !== "" ? `${mb} ` : "")
        + (ds ? num.replace(".", ds) : num).replace(re, `$&${ts}`)
        + (ma !== "" ? ` ${ma}` : ""));
});
export function renderHtml(template, data = {}) {
    // Render template
    const hbTemplate = Handlebars.compile(template);
    return hbTemplate(data);
}
