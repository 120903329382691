import { defineStore } from "pinia"
import localForage from "localforage"
import type { Actor, Block, View } from "@bonx/common"
import { QueryHelper, ViewService } from "@bonx/common"
import DBHelper from "../helpers/dbHelper/index"
import { Actions, Resources, useAcl } from "../composables/acl"
import { viewGroupsStore } from "./viewGroups"
import { userStore } from "./user"
import { modelsStore } from "./models"

const dbHelper = new DBHelper()
const { canI } = useAcl()

export const viewsStore = defineStore({
  id: "views",
  state: () => ({
    views: {
      list: [] as Array<View>,
    },
    refresh_views_cache_id: "",
    viewsFilters: {} as any,
    editionDrawer: { isOpened: false } as any,
    objectDrawer: {
      isOpened: false,
      modelId: null as string | null,
      objectId: null as "new" | number | null,
      actorId: null as Actor["id"] | null,
      objectProps: {} as Record<string, any>,
    },
  }),
  getters: {
    view: state => (id: string) => state.views.list.find(view => view.id === id) as View,
    getActiveView: () => {
      return useRouter().currentRoute.value.params.id as string
    },
    getViews(): Array<any> {
      return this.views.list
    },
    /**
     * Returns the actions associated with a view.
     * This can include
     *  - Triggering the opening of a form if the row of a block is clicked
     *  - Triggering the opening of a form if a QR code is scanned
     *  - Triggering the opening of a form if an icon on each row of a block is clicked
     *  - Triggering the opening of a form if a top level button is clicked
     * See https://www.notion.so/ossventures/42da4b9fc8074b0ead537c0a86fbf52b?v=6e46801dd65d46e2b4cffce652e9c7a1&p=9b83d59d29a1430c9d652448e273f697&pm=s
     */
    getViewActions: (state) => {
      return (viewId: string) => {
        const view = state.views.list.find(view => view.id === viewId)
        return view?.misc || { open_form_on_scanned_item: "" }
      }
    },
    getViewBlockActions: (state) => {
      return (viewId: string, blockIndex: any) => {
        const view = state.views.list.find(view => view.id === viewId)
        return view?.setup?.[blockIndex]?.misc || {}
      }
    },
    getViewBlockType: (state) => {
      return (viewId: string, blockIndex: any) => {
        const view = state.views.list.find(view => view.id === viewId)

        return (view?.setup?.[blockIndex] && view?.setup[blockIndex].type) ?? ""
      }
    },
    /**
     * Returns a flat list of all blocks title and id in a view
     * @param state
     *
     */
    getViewBlocks: (state) => {
      return (viewId: string) => {
        const view = state.views.list.find(view => view.id === viewId)
        return view.root.blocks.map((block: Block) => {
          return { label: block.label, value: block.id }
        })
      }
    },
    isEditionDrawerOpened(): boolean {
      return this.editionDrawer.isOpened
    },
    getEditionDrawerParams(): any {
      return this.editionDrawer
    },
  },
  actions: {
    async loadViews(): Promise<any> {
      const user: any = userStore().user
      if (user) {
        // Get refresh_views cache id
        const arrayWhere: any = {
          client_id: user.client_id,
          action: "refresh_views",
        }
        const cacheDocList = await dbHelper?.getAllDataFromCollectionWithWhereArray("cache", arrayWhere)
        if (cacheDocList?.length === 1) {
          const cacheDoc = cacheDocList[0]
          this.refresh_views_cache_id = cacheDoc.id
        }

        let views: Array<View> = []

        const array_where: any = {
          client_id: user.client_id,
        }
        if (user?.client_id) {
          const service = new ViewService(dbHelper, user.client_id)
          views = await service.list(array_where)
        }

        this.views.list = views.filter(view => !view.deleted_at && canI([Actions.Read, Actions.ReadMyOwn], `${Resources.Views}.${view.id}`))
      }
    },
    async loadView(viewId: string): Promise<any> {
      if (!viewId)
        throw new Error(`Can't use ${viewId} as an ID`)

      if (!canI([Actions.Read, Actions.ReadMyOwn], `${Resources.Views}.${viewId}`))
        return

      const viewService = new ViewService(dbHelper, userStore().user.client_id)
      const view = await viewService.get(viewId)

      if (!view) {
        console.warn(`View ${viewId} not found`)
        return
      }
      const index = this.views.list.findIndex((item: any) => item.id === viewId)
      if (index === -1) {
        console.warn(`View ${viewId} not found in list`)
        this.views.list.push(view)
      }
      else {
        this.views.list.splice(index, 1, view)
      }
    },

    async loadSelectedClientViews(selectedClientId: string): Promise<any> {
      if (selectedClientId) {
        let views: Array<any> = []
        const array_where: any = {
          client_id: selectedClientId,
        }
        const viewService = new ViewService(dbHelper, selectedClientId)
        views = await viewService.list(array_where)

        views
          .filter((view: any) => canI([Actions.Read, Actions.ReadMyOwn], `${Resources.Views}.${view.id}`))
          .forEach((view: any) => {
            if (!this.views.list.find((item: any) => item.id === view.id))
              this.views.list.push(view)
          })
      }
    },
    async createView(view: any): Promise<any> {
      const user: any = userStore().user
      if (user) {
        view.client_id = user.client_id
        view.created_at = new Date().toISOString()
        view.updated_at = new Date().toISOString()

        const viewService = new ViewService(dbHelper, user.client_id)
        const createdView = await viewService.create(view)

        view.id = createdView.id
        this.views.list.push(view)
        return view
      }
      return null
    },
    /**
     * Performs a partial update of a view, by id
     * @param viewId
     * @param view
     */
    async updateViewById(viewId: string, view: any): Promise<any> {
      if (!canI([Actions.Update, Actions.UpdateMyOwn], `${Resources.Views}.${viewId}`))
        throw new Error(`Insufficient permissions to update view ${viewId}`)

      view.updated_at = new Date().toISOString()

      const viewService = new ViewService(dbHelper, userStore().user.client_id)
      await viewService.update(viewId, view)

      await this.loadView(viewId)
      return view
    },
    async updateView(view: any): Promise<any> {
      if (!canI([Actions.Update, Actions.UpdateMyOwn], `${Resources.Views}.${view.id}`, view))
        throw new Error(`Insufficient permissions to update view ${view.id}`)

      if (this.views.list.find((item: any) => item.id === view.id))
        await this.updateViewById(view.id, view)
      else
        throw new Error(`View ${view.id} not found (payload: ${JSON.stringify(view)}))`)
    },
    async updateBlock(viewId: string, blockIndex: number, block: any): Promise<any> {
      if (!canI([Actions.Update, Actions.UpdateMyOwn], `${Resources.Views}.${viewId}`))
        throw new Error(`Insufficient permissions to update view ${viewId}`)

      const view = this.views.list.find(view => view.id === viewId)
      if (view) {
        view.setup[blockIndex] = {
          ...view.setup[blockIndex],
          ...block,
        }

        await this.updateView(view)
        return view
      }
      else {
        throw new Error(`View ${view.id} not found in list`)
      }
    },
    async deleteView(viewId: string): Promise<any> {
      if (!canI([Actions.Delete, Actions.DeleteMyOwn], `${Resources.Views}.${viewId}`))
        throw new Error(`Insufficient permissions to delete view ${viewId}`)

      const viewIndex = this.views.list.findIndex((view: any) => view.id === viewId)
      if (viewIndex > -1) {
        const view = this.views.list[viewIndex]
        view.deleted_at = new Date().toISOString()
        this.views.list.splice(viewIndex, 1)

        const viewService = new ViewService(dbHelper, userStore().user.client_id)
        await viewService.update(viewId, view)
      }
      await viewGroupsStore().loadViewGroups()
    },

    /**
     *  Control when the block edition drawer opens and for which view and block
     */
    toggleBlockEditionDrawer(isOpened: boolean, params: Record<string, unknown>): void {
      this.editionDrawer = {
        isOpened,
        ...params,
      }
    },

    // Views Filters
    async loadViewsFilters() {
      const lf: any = await localForage.getItem("bonx-views-filters")
      if (lf?.data)
        this.viewsFilters = lf?.data
    },
    setViewFilter() {
      void localForage.setItem("bonx-views-filters", { data: JSON.parse(JSON.stringify(this.viewsFilters)) })
    },
    setViewBlockFilter(viewId: string, blockIndex: number, filters: any): any {
      this.viewsFilters[viewId] = {
        [blockIndex]: this.viewsFilters[viewId]
          ? { ...this.viewsFilters[viewId][blockIndex], filters }
          : { filters },
      }
      this.setViewFilter()
    },
    setViewBlockSorter(viewId: string, blockIndex: number, sorter: any): any {
      this.viewsFilters[viewId] = {
        [blockIndex]: this.viewsFilters[viewId]
          ? { ...this.viewsFilters[viewId][blockIndex], sorter }
          : { sorter },
      }
      this.setViewFilter()
    },
    async generateCypherQuery(related_model_id: string, axis?: Array<string>) {
      const setting_object = modelsStore().model(related_model_id)
      if (!setting_object)
        return false

      const user = userStore().user
      const helper = new QueryHelper(dbHelper, user.client_id)
      return await helper.matchQuery(setting_object, {
        axis,
      })
    },
    /**
     * Performs a partial update of a view, by id
     * @param viewId
     * @param view
     * @param isSave
     */
    async updateRootBlock(viewId: string, root: Block, isSave = false): Promise<View> {
      if (!canI([Actions.Update, Actions.UpdateMyOwn], `${Resources.Views}.${viewId}`))
        throw new Error(`Insufficient permissions to update view ${viewId}`)

      if (isSave) {
        if (root.label) {
          const viewService = new ViewService(dbHelper, userStore().user.client_id)
          await viewService.updateField(viewId, "name", root.label)
        }

        const viewService = new ViewService(dbHelper, userStore().user.client_id)
        await viewService.updateField(viewId, "root", root)

        await this.loadView(viewId)
      }
      return this.view(viewId)
    },

    // V1 Record<string, unknown> Drawer
    openObjectDrawer(action: "new" | "edit", data: { id: string | number, objectProps?: Record<string, string> }, actorId: Actor["id"]) {
      if (action === "new") {
        this.objectDrawer.objectId = "new"
        this.objectDrawer.modelId = data.id as string
        if (data.objectProps)
          this.objectDrawer.objectProps = data.objectProps
      }
      else {
        this.objectDrawer.objectId = data.id as number
      }
      this.objectDrawer.actorId = actorId
      this.objectDrawer.isOpened = true
    },
    closeObjectDrawer() {
      this.objectDrawer.isOpened = false
      this.objectDrawer.objectId = null
      this.objectDrawer.modelId = null
      this.objectDrawer.objectProps = {}
    },
  },
})
