import { defineStore } from "pinia"
import { userStore } from "~/stores/user"
import DBHelper from "~/helpers/dbHelper/index"

const dbHelper = new DBHelper()

export const globalStore = defineStore({
  id: "global",
  state: () => ({
    refresh_cache_id: "",
  }),
  getters: {
    getGlobalStore(): any {
      return this
    },
  },
  actions: {
    async loadGlobalStore(): Promise<any> {
      const user: any = userStore().user
      if (user) {
        // Get refresh_views cache id
        const arrayWhere: any = {
          client_id: user.client_id,
          action: "refresh_store",
        }
        const cacheDocList = await dbHelper?.getAllDataFromCollectionWithWhereArray("cache", arrayWhere)
        if (cacheDocList?.length >= 1) {
          const cacheDoc = cacheDocList[0]
          this.refresh_cache_id = cacheDoc.id
        }
        else if (cacheDocList?.length === 0) {
          const cacheDoc = await dbHelper.addDataToCollection("cache", {
            client_id: user.client_id,
            action: "refresh_store",
            date: new Date().toISOString(),
          })
          this.refresh_cache_id = cacheDoc.id
        }
      }
    },
    async updateStoreCache(): Promise<any> {
      await dbHelper.updateDataToCollection("cache", this.refresh_cache_id, { date: new Date().toISOString() })
    },

  },
})
