import { defineStore } from "pinia"
import type { UserWithClient, ViewGroup } from "@bonx/common"
import { ViewGroupService } from "@bonx/common"
import DBHelper from "../helpers/dbHelper/index"
import { Actions, Resources, useAcl } from "../composables/acl"
import { userStore } from "./user"
import { globalStore } from "./global"

const dbHelper = new DBHelper()
const { canI } = useAcl()

// Todo : Merge this store into views store
export const viewGroupsStore = defineStore({
  id: "viewGroups",
  state: () => ({
    viewGroups: {
      list: [] as Array<ViewGroup>,
    },
    _viewGroupService: null as ViewGroupService | null,
  }),
  getters: {
    viewGroup: state => (id: string) => state.viewGroups.list.find(viewGroup => viewGroup.id === id) as ViewGroup,
    getViewGroups(): Array<ViewGroup> {
      return this.viewGroups.list
    },
    getViewGroupService: state => () => {
      if (!state._viewGroupService)
        state._viewGroupService = new ViewGroupService(dbHelper, userStore().user.client_id)
      return state._viewGroupService
    },
  },
  actions: {
    async loadViewGroups(): Promise<void> {
      const user: UserWithClient = userStore().user
      if (user) {
        let viewGroups: Array<ViewGroup> = []

        if (!user?.client_id)
          throw new Error(`Could not found user : ${JSON.stringify(user)}`)

        viewGroups = await this.getViewGroupService().list()
        this.viewGroups.list = viewGroups.filter((viewGroup: ViewGroup) =>
          canI([Actions.Read, Actions.ReadMyOwn], `${Resources.ViewGroups}.${viewGroup.id}`),
        )
      }
    },
    async deleteViewGroup(viewGroupId: string) {
      const result = await this.getViewGroupService().delete(viewGroupId)
      this.viewGroups.list = this.viewGroups.list.filter((viewGroup: ViewGroup) => viewGroup.id !== viewGroupId)
      return result
    },

    /**
     * Save view Group updates and refresh view groups list
     * @param viewGroupId view group id
     * @param viewGroup view group information
     */
    async updateViewGroup(viewGroupId: string, viewGroup: ViewGroup) {
      await this.getViewGroupService().update(viewGroupId, viewGroup)
      await this.loadViewGroups()
    },
    async addViewToGroup(viewGroupId: string, viewId: string) {
      const viewGroup = this.viewGroups.list.find((vg: ViewGroup) => vg.id === viewGroupId)
      if (viewGroup && !viewGroup.view_list.find((v: string) => v === viewId)) {
        viewGroup.view_list.push(viewId)
        if (viewGroupId !== "0")
          await this.getViewGroupService().update(viewGroupId, viewGroup)
        await globalStore().updateStoreCache()
        return viewGroup
      }
    },

    async createViewGroup(viewGroup: ViewGroup) {
      const user = userStore().user
      if (!user?.client_id)
        throw new Error(`User has no client_id ${JSON.stringify(user)}`)

      const created = await this.getViewGroupService().create(viewGroup)
      return created
    },
  },
})
