import { defineStore } from "pinia"
import { userStore } from "./user"
import DBHelper from "~/helpers/dbHelper/index"

export const usersStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "users",
  // a function that returns a fresh state
  state: () => ({
    users: [] as Array<any>,
  }),

  // optional getters
  getters: {
    getUser(): any {
      return this.users
    },
  },
  // optional actions
  actions: {
    async loadUsers(): Promise<any> {
      const dbHelper = new DBHelper()
      const user = userStore().user
      let users = null
      if (user?.role === "ADMIN")
        users = await dbHelper?.getAllDataFromCollection("users")
      else
        users = await dbHelper?.getAllDataFromCollectionWithWhere("users", "client_id", user?.client_id)

      this.users = users
    },
    filterUsers(ids: any) {
      const users: any = this.users.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return users
    },
  },
})
